import { Fragment, useContext, useState } from 'react';
import toast from 'react-hot-toast';
import copy from 'copy-to-clipboard';
import { CopyIcon, PencilIcon, GlobeIcon } from '@primer/octicons-react';

import {
  Box,
  Divider,
  Grid,
  IconButton,
  Popover,
  Tooltip,
  Typography,
} from '@mui/material';
import { useParams } from 'react-router-dom';
import { MaterialIcons } from '../../../../../../../assets/MaterialIcons';
import { OrganisationAccessCode } from '../../../../../../../shared/types/organisation/OrganisationDetailItem';
import StyledButton from '../../../../../../widgets/styledButton/StyledButton';
import AddAccessCodeDialog from './AddAccessCodeDialog';
import { MarketingServiceV2 } from '../../../../../../../shared/service/services_v2';
import FeatureBox from '../../../../../../widgets/featureBox';
import { OrganisationoDetailContext } from '../../OrganisationDetailView';
import SectionHeader from '../../../../../../components/SectionHeader/SectionHeader';
import { TooltipTypography } from '../../../../../../components/TooltipTypography/TooltipTypography';
import SectionCard from '../../../../../../components/SectionCard/SectionCard';
import {
  AclActions,
  AclModules,
} from '../../../../../../../shared/constants/Ability';
import { AbilityContext } from '../../../../../../components/Acl/Can';

const AccessCodesView = () => {
  const { accessCodes, getAccessCodes, refetchAuditLogs, setRefetchAuditLogs } =
    useContext(OrganisationoDetailContext);
  const ability = useContext(AbilityContext);

  const [showAddAccessCodeDialog, setShowAddAccessCodeDialog] = useState<{
    open: boolean;
    edit: boolean;
    accessCode: OrganisationAccessCode | null;
  }>({ open: false, edit: false, accessCode: null });
  const [showDomainRestrictions, setShowDomainRestrictions] = useState<{
    element: null | HTMLElement;
    domainRestrictions: string;
  }>({ element: null, domainRestrictions: '' });

  const { orgId }: { orgId: string } = useParams();

  const handleSuccess = () => {
    getAccessCodes();
    setShowAddAccessCodeDialog({
      open: false,
      edit: false,
      accessCode: null,
    });
    setRefetchAuditLogs(!refetchAuditLogs);
  };

  return (
    <>
      <Box>
        <SectionHeader
          title={<Typography variant="h6">Access codes</Typography>}
          rightElement={
            <StyledButton
              size="small"
              variant="ghost"
              startIcon={MaterialIcons.Add}
              onClick={() =>
                setShowAddAccessCodeDialog({
                  open: true,
                  edit: false,
                  accessCode: null,
                })
              }
              margin={0}
              permission={{
                action: AclActions.Manage,
                subject: AclModules.ORGANISATION,
              }}
            >
              Add access code
            </StyledButton>
          }
        />

        <Grid
          container
          spacing={2}
          mt={1}
          paddingX={1}
          sx={{
            maxHeight: '250px',
            overflowY: 'auto',
          }}
        >
          {accessCodes
            ?.sort(
              (a, b) =>
                a.currentUsage / a.maxUsage - b.currentUsage / b.maxUsage,
            )
            .map((accessCode: OrganisationAccessCode) => (
              <Fragment key={accessCode.code}>
                <Grid item xs={4}>
                  <SectionCard
                    sx={{
                      width: '100%',
                      margin: 0,
                    }}
                  >
                    <Box
                      style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                    >
                      <SectionHeader
                        sx={{ width: '100%', alignItems: 'start', gap: '0' }}
                        title={
                          <Box
                            sx={{
                              display: 'flex',
                              justifyContent: 'start',
                              padding: 0,
                              margin: 0,
                              maxWidth: '7vw',
                            }}
                          >
                            <TooltipTypography
                              title={accessCode.code}
                              sx={{
                                fontSize: '18px',
                                fontWeight: 600,
                              }}
                            />
                          </Box>
                        }
                        rightElement={
                          <Box>
                            <Tooltip title={'Domain Restrictions'}>
                              <IconButton
                                onClick={(e) => {
                                  setShowDomainRestrictions({
                                    element: e.currentTarget,
                                    domainRestrictions:
                                      accessCode?.domainRestriction || '',
                                  });
                                }}
                                sx={{ margin: 0 }}
                              >
                                <GlobeIcon size={14} />
                              </IconButton>
                            </Tooltip>
                            <IconButton
                              onClick={() => {
                                copy(accessCode.code);
                                toast.success(`${accessCode.code} Copied!`);
                              }}
                              sx={{ margin: 0 }}
                            >
                              <CopyIcon size={14} />
                            </IconButton>

                            <IconButton
                              onClick={() => {
                                setShowAddAccessCodeDialog({
                                  open: true,
                                  edit: true,
                                  accessCode: accessCode,
                                });
                              }}
                              sx={{ margin: 0 }}
                              disabled={
                                !ability.can(
                                  AclActions.Manage,
                                  AclModules.ORGANISATION,
                                )
                              }
                            >
                              <PencilIcon size={14} />
                            </IconButton>
                          </Box>
                        }
                        subTitle={
                          <Box
                            sx={{
                              display: 'flex',
                              justifyContent: 'start',
                              padding: 0,
                              margin: 0,
                              maxWidth: '8vw',
                            }}
                          >
                            <TooltipTypography
                              fontSize={'14px'}
                              color={
                                accessCode.currentUsage < accessCode.maxUsage
                                  ? 'var(--chip-green-color)'
                                  : 'var(--chip-red-color)'
                              }
                              title={`(${accessCode.currentUsage} / ${accessCode.maxUsage})`}
                            />
                          </Box>
                        }
                      />
                    </Box>
                  </SectionCard>
                </Grid>
              </Fragment>
            ))}
        </Grid>
      </Box>

      <AddAccessCodeDialog
        orgId={orgId}
        open={showAddAccessCodeDialog.open}
        handleClose={() =>
          setShowAddAccessCodeDialog({
            open: false,
            edit: false,
            accessCode: null,
          })
        }
        handleSuccess={handleSuccess}
        edit={showAddAccessCodeDialog.edit}
        accessCode={showAddAccessCodeDialog.accessCode}
        key={`${showAddAccessCodeDialog.open}-${showAddAccessCodeDialog.edit}`}
      />

      <Popover
        anchorEl={showDomainRestrictions.element}
        open={!!showDomainRestrictions.element}
        onClose={() =>
          setShowDomainRestrictions({ element: null, domainRestrictions: '' })
        }
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        sx={{ borderRadius: '1rem', zIndex: '1201 !important' }}
      >
        <Box p={2}>
          Domain Restrictions
          <Divider sx={{ my: 1 }} />
          <Box
            display={'flex'}
            flexDirection={'column'}
            maxHeight={'50vh'}
            sx={{ overflowY: 'auto' }}
          >
            {showDomainRestrictions?.domainRestrictions
              ?.split(',')
              ?.map((item, idx) => (
                <Typography color={'GrayText'} key={idx}>
                  {item}
                </Typography>
              ))}
          </Box>
        </Box>
      </Popover>
    </>
  );
};

export default AccessCodesView;
