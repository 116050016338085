import { IPermission } from '../../../view/screens/access-control/utils/types/access-control.interface';
import { ApiConfigV2 } from '../../apiConfigV2';
import { NetworkInstanceV2 } from './NetworkInstance';

export class AccessControl {
  client: NetworkInstanceV2;

  constructor(_client: NetworkInstanceV2) {
    this.client = _client;
  }

  getPermissions = () =>
    this.client.get(ApiConfigV2.api.accessControl.getPermissions);

  createRole = (payload: { name: string }) =>
    this.client.post(ApiConfigV2.api.accessControl.createRole, payload);

  updateRole = (roleId: number, payload: { name: string }) =>
    this.client.put(
      ApiConfigV2.api.accessControl.updateRole.replace(
        '{{roleId}}',
        roleId.toString(),
      ),
      payload,
    );

  createPermission = (payload: Partial<IPermission>) =>
    this.client.post(ApiConfigV2.api.accessControl.createPermission, payload);

  updatePermission = (permissionId: number, payload: Partial<IPermission>) =>
    this.client.put(
      ApiConfigV2.api.accessControl.updatePermission.replace(
        '{{permissionId}}',
        permissionId.toString(),
      ),
      payload,
    );

  getRoleDetails = (roleId: number) =>
    this.client.get(
      ApiConfigV2.api.accessControl.getRoleDetails.replace(
        '{{roleId}}',
        roleId.toString(),
      ),
    );

  assignPermissionsToRole = (
    roleId: number,
    payload: { permissionIds: number[] },
  ) =>
    this.client.post(
      ApiConfigV2.api.accessControl.assignPermissionsToRole.replace(
        '{{roleId}}',
        roleId.toString(),
      ),
      payload,
    );

  getRolePermissions = (roleIds: number[]) =>
    this.client.get(ApiConfigV2.api.accessControl.rolePermissions, {
      params: { roleIds },
    });

  getUserOverriddenPermissions = (userId: string) =>
    this.client.get(
      ApiConfigV2.api.accessControl.userOverriddenPermissions.replace(
        '{{userId}}',
        userId.toString(),
      ),
    );
}
