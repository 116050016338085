import { Tab, Tabs } from '@mui/material';
import {
  Dispatch,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import toast from 'react-hot-toast';
import { match, useLocation } from 'react-router-dom';
import Config from '../../../../../config';

import { OrganisationServiceV2 } from '../../../../../shared/service/services_v2';
import {
  OperationalCountryItem,
  OrgMappedProviders,
  OrganisationAccessCode,
  OrganisationDetailItem,
  SsoDomain,
} from '../../../../../shared/types/organisation/OrganisationDetailItem';
import { OrganisationTeamItem } from '../../../../../shared/types/organisation/OrganisationTeamItem';
import { OrganisationTeamMemberItem } from '../../../../../shared/types/organisation/OrganisationTeamMemberItem';
import { LoadingView } from '../../../../components/loadingView';
import { TabPanel } from '../../../../components/tab/tabPanel';
import { RootViewContext } from '../../../RootView';
import OrganisationDetailHeader from './OrganisationDetailHeader';
import OrganisationManagement from './OrganisationManagement';
import OrganisationOverview from './OrganisationOverview';
import ConfigEditor from './configEditor/ConfigEditor';
import { useUpdateOrganisation } from './hooks/useUpdateOrganisation';
import { useGetOrgMembersCount } from './hooks/useGetOrgMembersCount';
import { useGetOrgTeams } from './hooks/useGetOrgTeams';
import { AddOrganisationToRecentlyVisited } from '../../../../../utilities/Organisation';

const tabHashOrder = ['', '#management', '#config'];

export interface OrganisationEditingModules {
  ORG_INFORMATION: boolean;
  ORG_CONFIGURATION: boolean;
  ORG_CONTRACT_DETAILS: boolean;
}

const OrganisationEditingModulesInitialValues: OrganisationEditingModules = {
  ORG_INFORMATION: false,
  ORG_CONFIGURATION: false,
  ORG_CONTRACT_DETAILS: false,
};

interface OrganisationoDetailContextTypes {
  isEditing: OrganisationEditingModules;
  setIsEditing: Dispatch<SetStateAction<OrganisationEditingModules>>;
  loading: boolean;
  handleOrganisationUpdate: () => void;
  orgData: OrganisationDetailItem | null;
  orgCredits: any | null;
  updatedData: Partial<OrganisationDetailItem>;
  setUpdatedData: Dispatch<
    SetStateAction<Partial<OrganisationDetailItem> | undefined>
  >;
  accessCodes: OrganisationAccessCode[] | null;
  getAccessCodes: () => Promise<void>;
  getOrgData: () => Promise<void>;
  getOrgCredits: () => Promise<void>;
  membersInMultipleTeams: Partial<OrganisationTeamMemberItem>[];
  csRep: boolean;
  orgProvidersList: OrgMappedProviders[] | null;
  getOrgMappedProviders: () => Promise<void>;
  domainConfigs: SsoDomain[];
  getOrgDomains: () => Promise<void>;
  refetchAuditLogs: boolean;
  setRefetchAuditLogs: Dispatch<SetStateAction<boolean>>;
  totalMembers?: number;
  refetchMembersCount: () => void;
  orgTeams: OrganisationTeamItem[];
  refetchOrgTeams: () => void;
}

export const OrganisationoDetailContext =
  createContext<OrganisationoDetailContextTypes>({
    isEditing: OrganisationEditingModulesInitialValues,
    setIsEditing: () => {
      return false;
    },
    loading: false,
    handleOrganisationUpdate: () => {
      return;
    },
    orgData: null,
    orgCredits: null,
    updatedData: {},
    setUpdatedData: () => {
      return;
    },
    accessCodes: null,
    getAccessCodes: async () => {
      return;
    },
    getOrgData: async () => {
      return;
    },
    getOrgCredits: async () => {
      return;
    },
    membersInMultipleTeams: [],
    orgProvidersList: [],
    domainConfigs: [],
    getOrgDomains: async () => {
      return;
    },
    getOrgMappedProviders: async () => {
      return;
    },
    refetchAuditLogs: false,
    setRefetchAuditLogs: () => false,
    refetchMembersCount: () => {
      return;
    },
    orgTeams: [],
    refetchOrgTeams: () => {
      return;
    },
    csRep: false,
  });

const OrganisationDetailView = ({
  match,
}: {
  match: match<{ orgId: string }>;
}) => {
  const location = useLocation();
  const { setAppBreadCrumbs } = useContext(RootViewContext);
  const [orgData, setOrgData] = useState<OrganisationDetailItem | null>(null);
  const [updatedData, setUpdatedData] = useState<
    Partial<OrganisationDetailItem> | undefined
  >();

  const [membersInMultipleTeams, setMembersInMultipleTeams] = useState<
    Partial<OrganisationTeamMemberItem>[]
  >([]);

  const [orgCredits, setOrgCredits] = useState<Partial<any>[]>([]);

  const [accessCodes, setAccessCodes] = useState<OrganisationAccessCode[]>([]);
  const [isEditing, setIsEditing] = useState<OrganisationEditingModules>(
    OrganisationEditingModulesInitialValues,
  );
  const [selectedTabIndex, setSelectedTabIndex] = useState<number>(
    tabHashOrder.findIndex((h) => h === location.hash) || 0,
  );
  const [refetchAuditLogs, setRefetchAuditLogs] = useState(false);
  const [orgProvidersList, setOrgMappedProviders] = useState<
    OrgMappedProviders[] | null
  >(null);

  const [domainConfigs, setDomainConfigs] = useState<SsoDomain[]>([]);

  const { data: membersCount, refetch: refetchMembersCount } =
    useGetOrgMembersCount(orgData?.id);
  const { data: teams, refetch: refetchOrgTeams } = useGetOrgTeams(orgData?.id);

  const handleMembersCountRefetch = useCallback(() => {
    refetchMembersCount();
  }, [refetchMembersCount]);

  const getOrgData = useCallback(async () => {
    let response;

    try {
      response = await OrganisationServiceV2.getDetail(
        Number(match.params.orgId),
      );

      if (response && response.data.success) {
        const {
          id,
          createdAt,
          updatedAt,
          defaultTeamId,
          operationalCountries,
          status,
          ...rest
        } = response.data.data;

        const operationalCountryNames = operationalCountries.map(
          (country: OperationalCountryItem) => country.countryName,
        );

        setOrgData({
          ...response.data.data,
          operationalCountries: operationalCountryNames,
          status: String(status),
        });
        setUpdatedData({
          ...(rest ?? {}),
          id,
          operationalCountries: operationalCountryNames,
          status: String(status),
        });
        AddOrganisationToRecentlyVisited(id);
      }
    } catch (error) {
      toast.error(
        'An error occurred while trying to get the details of the organisation.',
      );
    }
  }, [match.params.orgId]);

  const getMembersInMultipleTeams = useCallback(async () => {
    let membersResponse;

    try {
      membersResponse =
        await OrganisationServiceV2.getMembersInMultipleTeamsByOrg(
          Number(match.params.orgId) ?? 0,
        );

      if (membersResponse?.data?.success) {
        setMembersInMultipleTeams(membersResponse?.data?.data?.members ?? []);
      }
    } catch (error) {
      toast.error(
        "An error occurred while trying to get the organisation's members.",
      );
    }
  }, [match.params.orgId]);

  const getOrgCredits = useCallback(async () => {
    let creditsReponse;

    try {
      creditsReponse = await OrganisationServiceV2.getOrgCredits(
        Number(match.params.orgId) ?? 0,
      );
      if (creditsReponse?.data?.success) {
        setOrgCredits(creditsReponse?.data?.data?.credits ?? []);
      }
    } catch (error) {
      toast.error(
        "An error occurred while trying to get the organisation's credits.",
      );
    }
  }, [match.params.orgId]);

  const getAccessCodes = useCallback(async () => {
    let response = null;

    try {
      response = await OrganisationServiceV2.getAccessCode(
        Number(match.params.orgId) ?? 0,
      );
      if (response && response.data.success) {
        setAccessCodes(response.data.data.accessCodes);
      }
    } catch (error) {
      toast.error(
        "An error occurred while trying to get the organisation's access codes.",
      );
    }
  }, [match.params.orgId]);

  useEffect(() => {
    getOrgData();
    getAccessCodes();
    getMembersInMultipleTeams();
    getOrgCredits();
  }, []);

  const onOrgUpdateSuccess = () => {
    getOrgData();
    getOrgCredits();
    setIsEditing(OrganisationEditingModulesInitialValues);
    toast.success('Organisation data updated successfully!');
  };

  const { mutate: updateOrgData, isLoading } =
    useUpdateOrganisation(onOrgUpdateSuccess);

  const handleOrganisationUpdate = () => {
    if (!orgData?.id || !updatedData) return;

    updateOrgData({
      orgId: orgData?.id,
      updatedData,
      isStatusUpdated: updatedData.status !== orgData.status,
    });
  };

  const anyActiveEditMode = useMemo(() => {
    return Object.values(isEditing ?? {})?.some((item) => item);
  }, [isEditing]);

  const discardChanges = () => {
    setUpdatedData({ ...orgData });
    setIsEditing(OrganisationEditingModulesInitialValues);
  };

  const activateMembers = async () => {
    let response = null;

    if (!orgData?.id) return;

    try {
      response = await OrganisationServiceV2.activateMembers(orgData?.id);

      if (response && response.data.success) {
        getOrgData();
        toast.success(
          response?.data?.data ??
            'Member Activation Job has started. Changes will be reflected shortly. Please check back later.',
        );
      }
    } catch (error) {
      toast.error(
        "An error occurred while attempting to activate the organisation's members.",
      );
    }
  };
  const syncWithMailChimp = async () => {
    let response = null;
    if (!orgData?.id) return;

    try {
      response = await OrganisationServiceV2.syncWithMailChimp(orgData?.id);

      if (response && response.data.success) {
        toast.success('Members synced successfully!');
      }
    } catch (error) {
      toast.error(
        'An error occurred while attempting to sync the members with MailChimp.',
      );
    }
  };
  useEffect(() => {
    if (orgData) {
      setAppBreadCrumbs([
        {
          label: 'Commercial',
          path: Config.paths.marketing.home,
          type: 'link',
        },
        {
          label: 'Clients',
          path: Config.paths.organisationV3,
          type: 'link',
        },
        {
          label: `[${orgData?.id}] ${orgData?.name}`,
          type: 'text',
        },
      ]);
    }
  }, [orgData]);

  const getOrgMappedProviders = useCallback(async () => {
    if (!orgData?.id) return;

    let response = null;
    try {
      response = await OrganisationServiceV2.getOrgMappedProviders(orgData?.id);
      if (response && response.data.success) {
        setOrgMappedProviders(response.data.data);
      }
    } catch (error) {
      toast.error(
        'An error occurred while attempting to get the organisation mapped providers',
      );
    }
  }, [orgData?.id]);

  const getOrgDomains = useCallback(async () => {
    if (!orgData?.id) return;

    let response = null;
    try {
      response = await OrganisationServiceV2.getDomainConfigs(orgData?.id);
      if (response && response.data.success) {
        setDomainConfigs(response.data.data);
      }
    } catch (error) {
      toast.error(
        'An error occurred while attempting to get the organisation mapped providers',
      );
    }
  }, [orgData?.id]);

  useEffect(() => {
    getOrgMappedProviders();
  }, [orgData?.id, getOrgMappedProviders]);

  useEffect(() => {
    getOrgDomains();
  }, [orgData?.id, getOrgDomains]);

  if (!orgData || !updatedData || isLoading) {
    return <LoadingView />;
  }

  return (
    <>
      <OrganisationoDetailContext.Provider
        value={{
          isEditing,
          setIsEditing,
          loading: isLoading,
          handleOrganisationUpdate,
          orgData,
          orgCredits,
          updatedData,
          setUpdatedData,
          accessCodes,
          getAccessCodes,
          getOrgData,
          getOrgCredits,
          membersInMultipleTeams,
          orgProvidersList,
          getOrgMappedProviders,
          refetchAuditLogs,
          setRefetchAuditLogs,
          domainConfigs,
          getOrgDomains,
          totalMembers: membersCount?.totalMembers ?? 0,
          refetchMembersCount: handleMembersCountRefetch,
          orgTeams: teams ?? [],
          refetchOrgTeams,
          csRep: (orgData as any)?.csRep ?? true, // TODO set it using org detail api
        }}
      >
        <OrganisationDetailHeader
          accessCodes={accessCodes}
          activateMembers={activateMembers}
        />
        <Tabs
          value={selectedTabIndex}
          onChange={(_event, newValue) => {
            setSelectedTabIndex(newValue);
            window.location.hash = tabHashOrder[newValue];
          }}
          aria-label="Course detail tabs"
          sx={{ marginTop: 2, mb: 3 }}
        >
          <Tab label="Overview" value={0} disabled={anyActiveEditMode} />
          <Tab label="Management" value={1} disabled={anyActiveEditMode} />
          <Tab label="Configuration" value={2} disabled={anyActiveEditMode} />
        </Tabs>

        {/* Have not used TabPanel here bcz it adds lot of padding */}
        {selectedTabIndex === 0 && <OrganisationOverview />}
        {selectedTabIndex === 1 && <OrganisationManagement />}

        <TabPanel value={selectedTabIndex} index={2}>
          <ConfigEditor orgId={orgData.id} />
        </TabPanel>
      </OrganisationoDetailContext.Provider>
    </>
  );
};

export default OrganisationDetailView;
