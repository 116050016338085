import { Close } from '@mui/icons-material';
import { Chip, Tooltip } from '@mui/material';

interface IFilterChipProps {
  title: string;
  onDelete: () => void;
}
const FilterChip = ({ title, onDelete }: IFilterChipProps) => {
  return (
    <Tooltip title={title}>
      <Chip
        sx={{
          height: '28px',
          background: '#F7F7F7',
          padding: '10px 4px',
          maxWidth: '250px',
          // display: 'flex',
          '& .MuiChip-label': {
            display: 'block',
            fontSize: '12px',
            fontWeight: '500',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          },
        }}
        deleteIcon={<Close />}
        label={title}
        size="small"
        onDelete={onDelete}
      />
    </Tooltip>
  );
};

export default FilterChip;
