import {
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  InputLabel,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';
import { useState } from 'react';
import { CreditPoolTypes } from '../../../../../../../shared/constants/Organisation';
import { CreditPlanRequestPayload } from '../../../../../../../shared/types/organisation/OrganisationDetailItem';
import LabelWithHelp from '../../../../../../components/LabelWithHelp/LabelWithHelp';
import { GenericDialog } from '../../../../../../components/genericDialog';
import FeatureBox from '../../../../../../widgets/featureBox';
import StyledButton from '../../../../../../widgets/styledButton/StyledButton';

const CreditEditDialogRevised = ({
  handleSubmit,
  open,
  handleClose,
  currentPlans,
  loading,
}: {
  handleSubmit: (pool: CreditPlanRequestPayload[]) => void;
  open: boolean;
  handleClose: () => void;
  currentPlans: any[];
  loading?: boolean;
}) => {
  const [accordionStates, setAccordionStates] = useState<any[]>([]);
  const [planUse, setPlanUse] = useState<string>('emp');

  const currentEnabledPlans = currentPlans
    .filter((cp) => cp.planUse === planUse)
    .reduce((res, cp) => {
      res[cp.type] = true;
      return res;
    }, {});

  const [type, setType] = useState<string | undefined>(
    CreditPoolTypes.find((cpt) => !currentEnabledPlans[cpt.type])?.type,
  );
  const [expiryDate, setExpiryDate] = useState<string>(moment().toString());

  const [showCreditUpdateDialog, setshowCreditUpdateDialog] =
    useState<boolean>(false);

  const updateAccordionState = (index: number, newState: any) => {
    setAccordionStates((prevStates) => {
      const newStates = [...prevStates];
      newStates[index] = newState;
      return newStates;
    });
  };

  const handleEditClick = () => {
    const selectedPlansArray = accordionStates
      .filter((state) => state && state.included)
      .map((state) => ({
        id: state.id,
        credits: state.credits,
        creditPerUser: state.creditPerUser,
        type: state.type,
        expiresAt: state.expiresAt || new Date(),
        planUse: state.planUse,
        notes: state?.notes || '',
      }));
    handleSubmit(selectedPlansArray);
  };

  return (
    <GenericDialog
      open={open}
      handleClose={handleClose}
      noFullScreen
      title="Edit Credit Plan"
      extraElements={
        <StyledButton
          disabled={!type || !expiryDate || loading}
          sx={{ width: '8rem' }}
          size="small"
          margin={0}
          onClick={handleEditClick}
        >
          Edit
        </StyledButton>
      }
    >
      <Grid container spacing={2}>
        {currentPlans.map((plan: any, index: number) => {
          const type = CreditPoolTypes.find((item) => item.type === plan.type)
            ?.label;

          return (
            <Grid item xs={6} width={6} key={plan.id}>
              <FeatureBox sx={{ marginBottom: 0 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12} width={12}>
                    <Box display={'flex'} justifyContent={'space-between'}>
                      <Typography variant="h6">{type}</Typography>
                      <Tooltip title={`Edit ${type} Plan`}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={
                                accordionStates[index]?.included || false
                              }
                              onChange={(event, checked) =>
                                setAccordionStates((prevStates) => {
                                  const updatedStates = [...prevStates];
                                  updatedStates[index] = {
                                    ...updatedStates[index],
                                    included: checked,
                                    id: plan.id,
                                    planUse: 'emp',
                                    type: plan.type,
                                    expiresAt: plan.expiresAt,
                                    creditPerUser: plan.creditPerUser,
                                    credits: plan.credits,
                                    notes: plan?.notes || '',
                                  };
                                  return updatedStates;
                                })
                              }
                            />
                          }
                          label=""
                        />
                      </Tooltip>
                    </Box>
                  </Grid>
                  {accordionStates[index]?.included ? (
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                  ) : null}

                  {accordionStates[index]?.included &&
                    accordionStates[index].type !== '10' && (
                      <>
                        <Grid
                          item
                          xs={12}
                          width={12}
                          display={'flex'}
                          justifyContent={'space-between'}
                          height={'3.5rem'}
                        >
                          <FormControlLabel
                            control={
                              <Checkbox
                                disabled={true}
                                checked={
                                  accordionStates[index]?.credits !== null
                                }
                                onChange={(event, checked) =>
                                  updateAccordionState(index, {
                                    ...accordionStates[index],
                                    credits: checked ? 0 : null,
                                  })
                                }
                              />
                            }
                            labelPlacement="start"
                            label={
                              <LabelWithHelp
                                label="Global Credits Limits"
                                helpText="Limit Total Credits?"
                                maxWidth="150px"
                              />
                            }
                          />
                          {accordionStates[index]?.credits !== null && (
                            <TextField
                              type="number"
                              onChange={(event) => {
                                updateAccordionState(index, {
                                  ...accordionStates[index],
                                  credits: event.target.value,
                                });
                              }}
                              value={accordionStates[index]?.credits}
                              defaultValue={plan?.credits}
                              size="small"
                            />
                          )}
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          width={12}
                          display={'flex'}
                          justifyContent={'space-between'}
                          height={'3.5rem'}
                        >
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={plan.creditPerUser !== null}
                                disabled={true}
                                onChange={(event, checked) =>
                                  updateAccordionState(index, {
                                    ...accordionStates[index],
                                    creditPerUser: checked ? 0 : null,
                                  })
                                }
                              />
                            }
                            labelPlacement="start"
                            label={
                              <LabelWithHelp
                                label="Credits Restriction Per User"
                                helpText="Restrict credits per user?"
                                maxWidth="150px"
                              />
                            }
                          />
                          {accordionStates[index]?.creditPerUser !== null && (
                            <TextField
                              type="number"
                              disabled={true}
                              onChange={(event) =>
                                updateAccordionState(index, {
                                  ...accordionStates[index],
                                  creditPerUser: event.target.value,
                                })
                              }
                              value={accordionStates[index]?.creditPerUser}
                              size="small"
                            />
                          )}
                        </Grid>
                        <Grid item xs={12} width={12} marginLeft={'16px'}>
                          <LocalizationProvider dateAdapter={AdapterMoment}>
                            <InputLabel id="date-picker-inline">
                              Valid till
                            </InputLabel>
                            <DesktopDatePicker
                              showToolbar={false}
                              inputFormat="yyyy/MM/DD"
                              mask="____/__/__"
                              value={
                                accordionStates[index]?.expiresAt || new Date()
                              }
                              onChange={(date) => {
                                let formattedDate;
                                if (
                                  date &&
                                  moment(date).isSameOrAfter(moment(), 'day')
                                ) {
                                  formattedDate = new Date(date);
                                } else {
                                  formattedDate = new Date();
                                }
                                updateAccordionState(index, {
                                  ...accordionStates[index],
                                  expiresAt: formattedDate,
                                });
                              }}
                              OpenPickerButtonProps={{
                                'aria-label': 'change date',
                              }}
                              renderInput={(props: any) => (
                                <TextField
                                  id="date-picker-inline"
                                  {...props}
                                  size="small"
                                  fullWidth
                                />
                              )}
                            />
                          </LocalizationProvider>
                        </Grid>
                        <Grid item xs={12} width={12} marginLeft={'16px'}>
                          <InputLabel>
                            <LabelWithHelp
                              label="Notes"
                              helpText="Notes like: top-up, extensions, refund..."
                              maxWidth="150px"
                            />
                            <TextField
                              size="small"
                              onChange={(event) =>
                                updateAccordionState(index, {
                                  ...accordionStates[index],
                                  notes: event.target.value,
                                })
                              }
                              inputProps={{ maxLength: 50 }}
                              value={accordionStates[index]?.notes}
                              fullWidth
                            />
                          </InputLabel>
                        </Grid>
                      </>
                    )}
                </Grid>
              </FeatureBox>
            </Grid>
          );
        })}
      </Grid>
    </GenericDialog>
  );
};

export default CreditEditDialogRevised;
