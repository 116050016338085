import { Box, Typography } from '@mui/material';
import { ReactComponent as ConfusedIcon } from '../../../assets/ConfusedIcon.svg';
import Config from '../../../config';
import StyledButton from '../../widgets/styledButton/StyledButton';

function Unauthorised() {
  return (
    <Box
      display={'flex'}
      alignItems={'center'}
      justifyContent={'center'}
      flexDirection={'column'}
      gap={2}
    >
      <ConfusedIcon height={350} width={450} />
      <Typography variant="h5">
        🔐 You are not Authorised to access this page
      </Typography>
      <StyledButton
        margin={0}
        variant="ghost"
        onClick={() =>
          (window.location.href = `/admin${Config.paths.realHome}`)
        }
      >
        Back to home
      </StyledButton>
    </Box>
  );
}

export default Unauthorised;
