import { CaseNoteTypes } from '../../../../../shared/constants/Provider';

export interface ICaseNotes {
  id: string;
  status: string;
  type: string;
  scheduledDate: string;
  scheduledStartTime: string;
  scheduledEndTime: string;
  friendlyName: string;
  userId: number;
  providerId: number;
  provider: string;
  notes: Record<string, string>;
}

export const CaseNotesFilterFields = [
  {
    field: 'id',
    label: 'ID',
  },
  {
    field: 'caseId',
    label: 'Case ID',
  },
  {
    field: 'friendlyName',
    label: 'Friendly Name',
  },
  {
    field: 'provider',
    label: 'Provider',
  },
  {
    field: 'status',
    label: 'Session Status',
  },
  {
    field: 'type',
    label: 'Session Medium',
  },
  {
    field: 'scheduledDate',
    label: 'Session Date',
  },
  {
    field: 'scheduledStartTime',
    label: 'Scheduled Start Time',
  },
  {
    field: 'scheduledEndTime',
    label: 'Scheduled End Time',
  },

  {
    field: 'mainPresentingIssue',
    label: 'Main Presenting Issue',
    caseNoteType: CaseNoteTypes.PrimaryGoal,
  },
  {
    field: 'subPresentingIssue',
    label: 'Sub Presenting Issue',
    caseNoteType: CaseNoteTypes.SecondaryGoal,
  },
  {
    field: 'riskLevel',
    label: 'Risk Level',
    caseNoteType: CaseNoteTypes.RiskAssesment,
  },
  {
    field: 'techniques',
    label: 'Techniques/modalities/interventions',
    caseNoteType: CaseNoteTypes.InterventionsAndModality,
  },
  {
    field: 'formulations',
    label: 'Formulation',
    caseNoteType: CaseNoteTypes.FormulationAndTreatment,
  },
  {
    field: 'recommendations',
    label: 'Recommendations',
    caseNoteType: CaseNoteTypes.Recommendations,
  },
  {
    field: 'additionalSessionRequired',
    label: 'Additional Session Required',
    caseNoteType: CaseNoteTypes.AdditonalSessionRecommended,
  },
  {
    field: 'progressTowardsGoals',
    label: 'Progress Towards Goals',
    caseNoteType: CaseNoteTypes.ProgressTowardsGoals,
  },
  {
    field: 'followUpPlan',
    label: 'Follow Up Plan',
    caseNoteType: CaseNoteTypes.FollowUpPlan,
  },
  {
    field: 'sharedFollowUpPlan',
    label: 'Shared Follow Up Plan',
    caseNoteType: CaseNoteTypes.SharedFollowUpPlan,
  },
  {
    field: 'suicidalAssesment',
    label: 'Suicidal Assesment',
    caseNoteType: CaseNoteTypes.SuicidalAssesment,
  },
];

export const caseNoteTypeMapping = [
  { field: 'mainPresentingIssue', caseNoteType: CaseNoteTypes.PrimaryGoal },
  { field: 'subPresentingIssue', caseNoteType: CaseNoteTypes.SecondaryGoal },
  { field: 'riskLevel', caseNoteType: CaseNoteTypes.RiskAssesment },
  { field: 'techniques', caseNoteType: CaseNoteTypes.InterventionsAndModality },
  {
    field: 'formulations',
    caseNoteType: CaseNoteTypes.FormulationAndTreatment,
  },
  { field: 'recommendations', caseNoteType: CaseNoteTypes.Recommendations },
  {
    field: 'additionalSessionRequired',
    caseNoteType: CaseNoteTypes.AdditonalSessionRecommended,
  },
  {
    field: 'progressTowardsGoals',
    caseNoteType: CaseNoteTypes.ProgressTowardsGoals,
  },
  { field: 'followUpPlan', caseNoteType: CaseNoteTypes.FollowUpPlan },
  {
    field: 'sharedFollowUpPlan',
    caseNoteType: CaseNoteTypes.SharedFollowUpPlan,
  },
  { field: 'suicidalAssesment', caseNoteType: CaseNoteTypes.SuicidalAssesment },
];
