import { useInfiniteQuery } from '@tanstack/react-query';
import { OrganisationServiceV2 } from '../../../../../../shared/service/services_v2';
import { OrganisationTeamItem } from '../../../../../../shared/types/organisation/OrganisationTeamItem';

export const useGetOrgTeamsWithMembersCount = (
  orgId?: number,
  debouncedTeamName?: string,
) =>
  useInfiniteQuery({
    enabled: Boolean(orgId),
    queryKey: ['org-teams-with-members-count', orgId, debouncedTeamName],
    queryFn: async ({ pageParam = 1 }) => {
      if (orgId) {
        return OrganisationServiceV2.getTeamsWithMembersCount(orgId, {
          take: 10,
          page: pageParam,
          teamName: debouncedTeamName,
        });
      }
    },
    getNextPageParam: (lastPage, allPages) => {
      const hasMore = lastPage?.data?.data?.length;
      // if recieved blank array means no data there so stop
      if (hasMore) {
        return allPages.length + 1;
      }
      return undefined;
    },
    staleTime: 5 * 60 * 1000, // Data is fresh for 2 minutes
    cacheTime: 10 * 60 * 1000, // Data is kept in cache for 5 minutes
    select: (data) => ({
      pages: data.pages.flatMap(
        (page) => page?.data?.data ?? [],
      ) as OrganisationTeamItem[],
      pageParams: data.pageParams,
    }),
    meta: {
      errorMessage: 'An error occurred while attempting to get org teams',
    },
  });
