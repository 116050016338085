import {
  Autocomplete,
  Box,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import toast from 'react-hot-toast';

import { ClearOutlined } from '@mui/icons-material';
import { AlertIcon } from '@primer/octicons-react';
import { MaterialIcons } from '../../../../../../assets/MaterialIcons';
import {
  OrganisationTeamMemberRoleDropdownValues,
  TeamMemberStatusDropdownValues,
  TeamMemberStatusEnum,
} from '../../../../../../shared/constants/Organisation';
import { OrganisationServiceV2 } from '../../../../../../shared/service/services_v2';
import { NumberFieldStyles } from '../../../../../../shared/styles/Common';
import { OrganisationTeamMemberItem } from '../../../../../../shared/types/organisation/OrganisationTeamMemberItem';
import useDebounce from '../../../../../../utilities/hooks/useDebounce';
import TableViewComponent from '../../../../../components/TableViewComponent/TableViewComponent';
import ConfirmDialog from '../../../../../components/confirmDialog/ConfirmDialog';
import DownloadTemplateView from '../../../../../components/fileUpload/downloadTemplate';
import { ExcelExportView } from '../../../../../components/fileUpload/excelExport';
import { ExcelUploadView } from '../../../../../components/fileUpload/excelUpload';
import StyledButton from '../../../../../widgets/styledButton/StyledButton';
import { ClientMembersGridColumns } from '../../../utils/grid-columns/ClientMembersGridColumns';
import { OrganisationoDetailContext } from '../OrganisationDetailView';
import { useGetOrgMembers } from '../hooks/useGetOrgMembers';
import { useGetOrgOperationalCountries } from '../hooks/useGetOrgOperationalCountries';
import BulkOffboardDialog from './BulkOffboardDialog';
import DeleteMembersByEmailDialog from './DeleteMembersByEmailDialog';
import EditBulkMembersTeamDialog from './EditBulkMembersTeamDialog';
import EditMemberDialog from './EditMembersDialog';
import MembersInMultipleTeamsDialog from './MembersInMultipleTeamsDialog';
import { GridCallbackDetails, GridRowSelectionModel } from '@mui/x-data-grid';
import { GenericDialog } from '../../../../../components/genericDialog';
import {
  AclActions,
  AclModules,
} from '../../../../../../shared/constants/Ability';

interface IMemberTableRevisedProps {
  orgId: number;
  refreshData?: () => void;
  teamId?: number; // denotes whether it's a particular team view or global members view (all members)
  refreshMembersKey?: boolean;
}

/**
 * Max limit for changing user status & teams at a single time
 */
export const MAX_LIMIT_FOR_BLK_MEMS_UPDATE = 50;

const MemberTableRevised = ({
                              refreshData,
                              orgId,
                              teamId,
                              refreshMembersKey = false,
                            }: IMemberTableRevisedProps) => {
  const {
    membersInMultipleTeams,
    orgTeams,
    csRep,
    refetchOrgTeams,
    refetchMembersCount,
  } = useContext(OrganisationoDetailContext);
  const [pageSize, setPageSize] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [viewOnlyPageSize, setViewOnlyPageSize] = useState<number>(10);
  const [viewOnlycurrentPage, setViewOnlyCurrentPage] = useState<number>(0);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [members, setMembers] = useState<OrganisationTeamMemberItem[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [selectedMembers, setSelectedMembers] = useState<
    OrganisationTeamMemberItem[]
  >([]);
  const [showConfirmDeleteMembersDialog, setShowConfirmDeleteMembersDialog] =
    useState<boolean>(false);
  const [showDeleteMembersOptionDialog, setShowDeleteMembersOptionDialog] =
    useState<boolean>(false);
  const [
    showConfirmDeleteMembersNoResetDialog,
    setShowConfirmDeleteMembersNoResetDialog,
  ] = useState<boolean>(false);
  const [
    showConfirmActivateMembersDialog,
    setShowConfirmActivateMembersDialog,
  ] = useState<boolean>(false);

  const [
    showConfirmChangePendingMembersDialog,
    setShowConfirmChangePendingMembersDialog,
  ] = useState<boolean>(false);

  const [showDeleteMembersByEmailDialog, setShowDeleteMembersByEmailDialog] =
    useState<boolean>(false);

  const [showBulkOffboardDialog, setShowBulkOffboardDialog] =
    useState<boolean>(false);

  const [showBulkTeamChangeDialog, setShowBulkTeamChangeDialog] =
    useState<boolean>(false);

  const [
    showMembersInMultipleTeamsDialog,
    setShowMembersInMultipleTeamsDialog,
  ] = useState(false);

  const [showEditMemberDialog, setShowEditMemberDialog] =
    useState<boolean>(false);
  const [memberToBeEdited, setMemberToBeEdited] =
    useState<OrganisationTeamMemberItem | null>(null);

  const handleEditMemberDialogClose = () => {
    setMemberToBeEdited(null);
    setShowEditMemberDialog(false);
  };
  const handleClose = () => {
    setShowOnlySelect(false);
  };
  const [memberIds, setMemberIds] = useState<number[]>([]);

  const [showOnlySelect, setShowOnlySelect] = useState(false);

  // Handle toggle switch
  const handleSwitchChange = (event: any) => {
    setShowOnlySelect(event.target.checked);
  };

  // useEffect(() => {
  //   setMemberIds([]);
  // }, [members]);

  const [filterInputs, setFilterInputs] = useState<{
    id: string;
    emailAddress: string;
    userId: string;
    organisationTeamId: string;
    organisationTeamName: string;
    countryName: string | null;
    status: string;
    role: string;
    employeeCode: string;
  }>({
    id: '',
    emailAddress: '',
    userId: '',
    organisationTeamId: teamId?.toString() ?? '',
    organisationTeamName: '',
    countryName: null,
    status: '',
    role: '',
    employeeCode: '',
  });
  const debouncedFilter = useDebounce(filterInputs, 500);

  const { data: operationalCountries } = useGetOrgOperationalCountries();

  const organisationOperationalCountries = useMemo(() => {
    return operationalCountries?.map((item) => item?.countryName) ?? [];
  }, [operationalCountries]);

  const { data, refetch: refreshMembers } = useGetOrgMembers(
    orgId,
    pageSize,
    currentPage + 1,
    debouncedFilter?.id,
    debouncedFilter?.userId,
    debouncedFilter?.emailAddress,
    debouncedFilter?.organisationTeamId,
    debouncedFilter?.organisationTeamName,
    debouncedFilter?.countryName,
    debouncedFilter?.status,
    debouncedFilter?.role,
    debouncedFilter?.employeeCode,
  );

  useEffect(() => {
    setMembers(data?.members ?? []);
    setTotal(data?.total ?? 0);
  }, [data]);

  const handleRefreshData = useCallback(() => {
    refreshMembers();
    refetchMembersCount();
    refetchOrgTeams();
    refreshData && refreshData();
  }, [refreshData, refreshMembers, refetchMembersCount, refetchOrgTeams]);

  useEffect(() => {
    refreshMembers();
  }, [refreshMembers, refreshMembersKey]);

  const deleteSelectedMembers = async (softDelete?: any) => {
    let response = null;

    try {
      setConfirmLoading(true);
      response = await OrganisationServiceV2.deleteMultipleMembersById(
        memberIds,
        softDelete,
      );

      if (response && response.data.success) {
        toast.success('Selected members removed successfully!');
        handleRefreshData();
        setShowConfirmDeleteMembersDialog(false);
        setShowConfirmDeleteMembersNoResetDialog(false);
        setShowDeleteMembersOptionDialog(false);
      }
    } catch (error) {
      toast.error(
        'An error occurred while trying to remove the selected members. Please try again.',
      );
    } finally {
      setMemberIds([]);
      setSelectedMembers([]);
      setConfirmLoading(false);
      setShowOnlySelect(false);
    }
  };

  const activateSelectedMembers = async () => {
    let response = null;

    try {
      setConfirmLoading(true);
      response = await OrganisationServiceV2.activateSelectedMembers(
        orgId,
        memberIds,
      );

      if (response && response.data.success) {
        toast.success('Selected members activated successfully!');
        handleRefreshData();
        setShowConfirmActivateMembersDialog(false);
      }
    } catch (error) {
      toast.error(
        'An error occurred while trying to remove the selected members. Please try again.',
      );
    } finally {
      setMemberIds([]);
      setSelectedMembers([]);
      setConfirmLoading(false);
      setShowOnlySelect(false);
    }
  };

  const changeStatusToPending = async () => {
    try {
      if (
        DisableChangeToPendingButton ||
        memberIds?.length > MAX_LIMIT_FOR_BLK_MEMS_UPDATE
      ) {
        return;
      }

      setConfirmLoading(true);
      const response = await OrganisationServiceV2.updateMembersToPendingState(
        orgId,
        memberIds,
      );
      if (response && response.data.success) {
        toast.success('Removed the selected members successfully!');
        handleRefreshData();
        setShowConfirmChangePendingMembersDialog(false);
      }
    } catch (error) {
      toast.error(
        'An error occurred while trying to change status of the selected members. Please try again.',
      );
    } finally {
      setMemberIds([]);
      setSelectedMembers([]);
      setConfirmLoading(false);
      setShowOnlySelect(false);
    }
  };

  const TeamNameOptions = useMemo(() => {
    return (
      orgTeams?.map((item) => ({ label: item?.name, value: item?.name })) ?? []
    );
  }, [orgTeams]);

  const SelectedTeamName = useMemo(() => {
    return TeamNameOptions?.find(
      (item) => item?.value === filterInputs?.organisationTeamName,
    );
  }, [TeamNameOptions, filterInputs?.organisationTeamName]);

  const SelectedRole = useMemo(() => {
    return OrganisationTeamMemberRoleDropdownValues?.find(
      (item) => item?.value === filterInputs?.role,
    );
  }, [filterInputs?.role]);

  const SelectedStatus = useMemo(() => {
    return TeamMemberStatusDropdownValues?.find(
      (item) => item?.value === filterInputs?.status,
    );
  }, [filterInputs?.status]);

  /**
   * To make sure only Active/New users can migrate to Pending state
   */
  const DisableChangeToPendingButton = useMemo(() => {
    return members?.some(
      (item) =>
        memberIds?.includes(item?.id) &&
        item?.status !== TeamMemberStatusEnum.ACTIVE &&
        item?.status !== TeamMemberStatusEnum.NEW,
    );
  }, [memberIds, members]);

  const handleEditRow = useCallback(
    (index: number) => {
      const findMember = members?.find((item) => item?.id === index) ?? null;
      if (findMember) {
        setMemberToBeEdited({
          ...findMember,
        });
        setShowEditMemberDialog(true);
      }
    },
    [members, setMemberToBeEdited, setShowEditMemberDialog],
  );

  const columns = useMemo(() => {
    return ClientMembersGridColumns({ handleEditRow, showEdit: true });
  }, [handleEditRow]);

  const readOnlyViewColumns = useMemo(() => {
    return ClientMembersGridColumns({ handleEditRow, showEdit: false });
  }, [handleEditRow]);

  const handleRowSelection = (
    rowSelectionModel: GridRowSelectionModel,
    details: GridCallbackDetails<any>,
  ) => {
    const selectedIds = rowSelectionModel as number[];
    setMemberIds(selectedIds);
    const previousSelectedIds = selectedMembers.map((member) => member.id);

    // Filter to get only the newly selected IDs that are not already in selectedMembers
    const newSelectedIds = selectedIds.filter(
      (id) => !previousSelectedIds.includes(+id),
    );

    // Fetch the member objects for the newly selected IDs
    const newSelectedMembers = members.filter((member) =>
      newSelectedIds.includes(member.id),
    );

    // Combine previously selected members with the newly selected ones
    const updatedSelectedMembers = [...selectedMembers, ...newSelectedMembers];

    // Now remove the deselected members
    const finalSelectedMembers = updatedSelectedMembers.filter((member) =>
      selectedIds.includes(member.id),
    );

    setSelectedMembers(finalSelectedMembers);
  };

  // useEffect to handle filter changes
  useEffect(() => {
    setCurrentPage(0);
  }, [filterInputs]);
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <InputLabel>Ref ID</InputLabel>
          <TextField
            fullWidth
            type="number"
            size="small"
            placeholder=""
            value={filterInputs.id}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    sx={{
                      visibility: filterInputs.id ? 'visible' : 'hidden',
                    }}
                    onClick={() =>
                      setFilterInputs((prev) => ({ ...prev, id: '' }))
                    }
                  >
                    <ClearOutlined fontSize="small" />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            onChange={(e) => {
              setFilterInputs((prev) => ({ ...prev, id: e.target.value }));
            }}
            sx={NumberFieldStyles}
          />
        </Grid>
        {csRep && (
          <Grid item xs={3}>
            <InputLabel>Email</InputLabel>
            <TextField
              fullWidth
              size="small"
              value={filterInputs.emailAddress}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      sx={{
                        visibility: filterInputs.emailAddress
                          ? 'visible'
                          : 'hidden',
                      }}
                      onClick={() =>
                        setFilterInputs((prev) => ({
                          ...prev,
                          emailAddress: '',
                        }))
                      }
                    >
                      <ClearOutlined fontSize="small" />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              onChange={(e) => {
                setFilterInputs((prev) => ({
                  ...prev,
                  emailAddress: e.target.value,
                }));
              }}
            />
          </Grid>
        )}
        <Grid item xs={3}>
          <InputLabel>User ID</InputLabel>
          <TextField
            fullWidth
            type="number"
            size="small"
            value={filterInputs.userId}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    sx={{
                      visibility: filterInputs.userId ? 'visible' : 'hidden',
                    }}
                    onClick={() =>
                      setFilterInputs((prev) => ({ ...prev, userId: '' }))
                    }
                  >
                    <ClearOutlined fontSize="small" />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            onChange={(e) => {
              setFilterInputs((prev) => ({ ...prev, userId: e.target.value }));
            }}
            sx={NumberFieldStyles}
          />
        </Grid>
        <Grid item xs={3}>
          <InputLabel>Team ID</InputLabel>
          <TextField
            fullWidth
            type="number"
            size="small"
            value={filterInputs.organisationTeamId}
            disabled={Boolean(teamId)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    sx={{
                      visibility: filterInputs.organisationTeamId
                        ? 'visible'
                        : 'hidden',
                    }}
                    disabled={Boolean(teamId)}
                    onClick={() =>
                      setFilterInputs((prev) => ({
                        ...prev,
                        organisationTeamId: '',
                      }))
                    }
                  >
                    <ClearOutlined fontSize="small" />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            onChange={(e) => {
              setFilterInputs((prev) => ({
                ...prev,
                organisationTeamId: e.target.value,
              }));
            }}
            sx={NumberFieldStyles}
          />
        </Grid>
        <Grid item xs={3}>
          <InputLabel>Team Name</InputLabel>
          <Autocomplete
            fullWidth={true}
            size="small"
            options={TeamNameOptions}
            value={SelectedTeamName}
            getOptionLabel={(option) => option.value}
            disabled={Boolean(teamId)}
            onChange={(event, newValue) => {
              setFilterInputs((prev) => ({
                ...prev,
                organisationTeamName: newValue?.value ?? '',
              }));
            }}
            renderInput={(params) => <TextField {...params} />}
          />
        </Grid>
        <Grid item xs={3}>
          <InputLabel>Country</InputLabel>
          <Autocomplete
            fullWidth={true}
            size="small"
            options={organisationOperationalCountries ?? []}
            value={filterInputs?.countryName}
            onChange={(event, newValue) => {
              setFilterInputs((prev) => ({
                ...prev,
                countryName: newValue ?? null,
              }));
            }}
            renderInput={(params) => <TextField {...params} />}
          />
        </Grid>
        <Grid item xs={3}>
          <InputLabel>Role</InputLabel>
          <Autocomplete
            fullWidth={true}
            size="small"
            options={OrganisationTeamMemberRoleDropdownValues}
            value={SelectedRole}
            getOptionLabel={(option) => option.label}
            onChange={(event, newValue) => {
              setFilterInputs((prev) => ({
                ...prev,
                role: newValue?.value ?? '',
              }));
            }}
            renderInput={(params) => <TextField {...params} />}
          />
        </Grid>
        <Grid item xs={3}>
          <InputLabel>Status</InputLabel>
          <Autocomplete
            fullWidth={true}
            size="small"
            options={TeamMemberStatusDropdownValues}
            value={SelectedStatus}
            getOptionLabel={(option) => option.label}
            onChange={(event, newValue) => {
              setFilterInputs((prev) => ({
                ...prev,
                status: newValue?.value ?? '',
              }));
            }}
            renderInput={(params) => <TextField {...params} />}
          />
        </Grid>
      </Grid>
      <Divider sx={{ my: 4 }} />
      {/* Only showing this in org view not in teams view */}
      {!teamId && membersInMultipleTeams?.length ? (
        <Box display={'flex'} justifyContent={'flex-end'}>
          <StyledButton
            color="red"
            startIcon={<AlertIcon />}
            onClick={() => setShowMembersInMultipleTeamsDialog(true)}
            size="small"
            margin={0}
          >
            Check Duplicate Users
          </StyledButton>
        </Box>
      ) : (
        <></>
      )}

      <Box
        paddingX={3}
        marginTop={2}
        height="2rem"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        {memberIds.length > 0 ? (
          <>
            <Typography variant="subtitle1">
              <b>{memberIds.length}</b> member
              {memberIds.length !== 1 && 's'} selected
              <StyledButton
                color="gray"
                variant="ghost"
                startIcon={MaterialIcons.Close}
                onClick={() => setMemberIds([])}
                sx={{ margin: 0 }}
              >
                Clear Selection
              </StyledButton>
              <StyledButton
                variant="ghost"
                color="gray"
                startIcon={MaterialIcons.Search}
                onClick={() => {
                  setShowOnlySelect(true);
                }}
                padding={0}
                margin={0}
              >
                {'View Selected'}
              </StyledButton>
            </Typography>
            <Box display={'flex'} justifyContent={'flex-end'}>
              {teamId ? (
                <StyledButton
                  color="gray"
                  variant="ghost"
                  startIcon={MaterialIcons.Switch}
                  onClick={() => setShowBulkTeamChangeDialog(true)}
                  permission={{
                    action: AclActions.Manage,
                    subject: AclModules.ORGANISATION,
                  }}
                >
                  Change Team
                </StyledButton>
              ) : (
                <></>
              )}

              <StyledButton
                variant="ghost"
                startIcon={MaterialIcons.CheckBoxCircle}
                onClick={() => setShowConfirmActivateMembersDialog(true)}
                sx={{ color: 'var(--chip-green-color)' }}
                permission={{
                  action: AclActions.Manage,
                  subject: AclModules.ORGANISATION,
                }}
              >
                Activate selected members
              </StyledButton>
              <StyledButton
                color="red"
                variant="ghost"
                startIcon={MaterialIcons.Delete}
                onClick={() => setShowDeleteMembersOptionDialog(true)}
                permission={{
                  action: AclActions.Manage,
                  subject: AclModules.ORGANISATION,
                }}
              >
                Remove selected members
              </StyledButton>
              <StyledButton
                color="gray"
                variant="ghost"
                onClick={() => setShowConfirmChangePendingMembersDialog(true)}
                startIcon={MaterialIcons.History}
                permission={{
                  action: AclActions.Manage,
                  subject: AclModules.ORGANISATION,
                }}
              >
                Make it Pending
              </StyledButton>
            </Box>
          </>
        ) : (
          <Box display="flex">
            {/*<StyledButton*/}
            {/*  color="red"*/}
            {/*  variant="ghost"*/}
            {/*  startIcon={MaterialIcons.Delete}*/}
            {/*  onClick={() => setShowDeleteMembersByEmailDialog(true)}*/}
            {/*>*/}
            {/*  Delete members by email*/}
            {/*</StyledButton>*/}
            <StyledButton
              color="red"
              variant="ghost"
              startIcon={MaterialIcons.Delete}
              onClick={() => setShowBulkOffboardDialog(true)}
              permission={{
                action: AclActions.Manage,
                subject: AclModules.ORGANISATION,
              }}
            >
              Bulk offboard members
            </StyledButton>
            <ExcelExportView orgId={orgId} />
            <ExcelUploadView orgId={orgId} />
            <DownloadTemplateView />
          </Box>
        )}
      </Box>
      <GenericDialog
        open={showOnlySelect}
        noFullScreen
        fitToContent
        handleClose={() => handleClose()}
        title={'All Selected Members'}
      >
        <TableViewComponent
          rows={selectedMembers}
          columns={readOnlyViewColumns}
          total={selectedMembers?.length || 0}
          pageSizeCustom={viewOnlyPageSize}
          onPageSizeChange={(pageSize) => {
            setViewOnlyPageSize(pageSize);
          }}
          onPageChange={(page) => {
            setViewOnlyCurrentPage(page);
          }}
          currentPage={viewOnlycurrentPage}
          checkboxSelection={!showOnlySelect}
          keepNonExistentRowsSelected={true}
          paginationMode="client"
          hideColumns={!csRep ? ['emailAddress'] : []}
        />
      </GenericDialog>
      <Box mt={2}>
        <TableViewComponent
          rows={members}
          columns={columns}
          total={total}
          pageSizeCustom={pageSize}
          currentPage={currentPage}
          onPageSizeChange={(pageSize) => {
            setPageSize(pageSize);
          }}
          onPageChange={(page) => {
            setCurrentPage(page);
          }}
          checkboxSelection={!showOnlySelect}
          keepNonExistentRowsSelected={true}
          onRowSelection={handleRowSelection}
          rowSelectionModel={memberIds}
          paginationMode="server"
          hideColumns={!csRep ? ['emailAddress'] : []}
        />
      </Box>
      <ConfirmDialog
        onConfirm={activateSelectedMembers}
        handleClose={() => setShowConfirmActivateMembersDialog(false)}
        open={showConfirmActivateMembersDialog}
        title="Activate members?"
        disabled={memberIds?.length > 10 || confirmLoading}
      >
        {memberIds?.length > 10 ? (
          <>You cannot activate more than 10 members at a time.</>
        ) : (
          <>
            Are you sure you want to activate {memberIds.length} member
            {memberIds?.length !== 1 ? 's' : ''}?
          </>
        )}
      </ConfirmDialog>
      <ConfirmDialog
        onConfirm={() => setShowConfirmDeleteMembersDialog(true)}
        handleClose={() => setShowDeleteMembersOptionDialog(false)}
        open={showDeleteMembersOptionDialog}
        title="Delete the user?"
        disabled={confirmLoading}
        confirmButtonTitle={'Reset Credits'}
        confirmSecondaryButtonTitle={'Just Delete'}
        onSecondOptionConfirm={() =>
          setShowConfirmDeleteMembersNoResetDialog(true)
        }
      >
        Choose how to remove {memberIds.length} member
        {memberIds?.length !== 1 ? 's' : ''} from the organisation?
      </ConfirmDialog>
      <ConfirmDialog
        onConfirm={() => deleteSelectedMembers()}
        handleClose={() => setShowConfirmDeleteMembersDialog(false)}
        open={showConfirmDeleteMembersDialog}
        title="Remove members?"
        disabled={confirmLoading}
      >
        Are you sure you want to remove {memberIds.length} member
        {memberIds?.length !== 1 ? 's' : ''} from the organisation by resetting
        his credits?
      </ConfirmDialog>
      <ConfirmDialog
        onConfirm={() => deleteSelectedMembers(true)}
        handleClose={() => setShowConfirmDeleteMembersNoResetDialog(false)}
        open={showConfirmDeleteMembersNoResetDialog}
        title="Remove members?"
        disabled={confirmLoading}
      >
        Are you sure you want to remove {memberIds.length} member
        {memberIds?.length !== 1 ? 's' : ''} from the organisation without
        resetting the credits?
      </ConfirmDialog>

      <ConfirmDialog
        onConfirm={changeStatusToPending}
        handleClose={() => setShowConfirmChangePendingMembersDialog(false)}
        open={showConfirmChangePendingMembersDialog}
        title="Change status to Pending?"
        disabled={
          DisableChangeToPendingButton ||
          memberIds?.length > MAX_LIMIT_FOR_BLK_MEMS_UPDATE ||
          confirmLoading
        }
      >
        {DisableChangeToPendingButton
          ? 'Only Active/New users can be changed to Pending status.'
          : memberIds?.length > MAX_LIMIT_FOR_BLK_MEMS_UPDATE
            ? `Only ${MAX_LIMIT_FOR_BLK_MEMS_UPDATE} members are allowed to be updated at a single time.`
            : `Are you sure you want to change ${memberIds.length} member${
              memberIds?.length !== 1 ? 's' : ''
            } status to Pending status?`}
      </ConfirmDialog>

      <DeleteMembersByEmailDialog
        handleClose={() => setShowDeleteMembersByEmailDialog(false)}
        refreshData={handleRefreshData}
        open={showDeleteMembersByEmailDialog}
        orgId={orgId}
      />
      <BulkOffboardDialog
        handleClose={() => setShowBulkOffboardDialog(false)}
        refreshData={handleRefreshData}
        open={showBulkOffboardDialog}
      />
      <EditBulkMembersTeamDialog
        open={showBulkTeamChangeDialog}
        handleClose={() => setShowBulkTeamChangeDialog(false)}
        teams={orgTeams}
        refreshData={handleRefreshData}
        memberIds={memberIds}
        orgId={orgId}
        currentTeamId={teamId}
      />

      <MembersInMultipleTeamsDialog
        open={showMembersInMultipleTeamsDialog}
        onClose={() => setShowMembersInMultipleTeamsDialog(false)}
        orgId={orgId}
      />

      <EditMemberDialog
        member={memberToBeEdited}
        teams={orgTeams}
        open={showEditMemberDialog}
        handleClose={handleEditMemberDialogClose}
        refreshData={handleRefreshData}
        orgId={orgId}
      />
    </>
  );
};

export default MemberTableRevised;
