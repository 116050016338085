import { ReactNode, useEffect, useState } from 'react';
import defineAbilityFor, { AppAbility } from '../../config/Ability';
import { AuthServiceV2 } from '../service/services_v2';
import { getUserCookie } from '../../utilities/Api';
import { AbilityContext, defaultAbility } from '../../view/components/Acl/Can';
import { CircularProgress } from '@mui/material';

export const AclGuard = ({ children }: { children: ReactNode }) => {
  const isLoggedIn = getUserCookie();
  const [ability, setAbility] = useState<AppAbility>(defaultAbility);
  const [isLoading, setIsLoading] = useState(false);

  const getUserPermissions = async () => {
    try {
      setIsLoading(true);
      const response = await AuthServiceV2.getUserPermissions();
      if (response && response.data.success) {
        const result = defineAbilityFor(
          response?.data?.data?.permissions ?? [],
        );
        setAbility(result);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isLoggedIn) {
      getUserPermissions();
    }
  }, []);

  if (!isLoggedIn) return <>{children}</>;

  return (
    <AbilityContext.Provider value={ability}>
      {!isLoading ? children : <CircularProgress />}
    </AbilityContext.Provider>
  );
};
