import { ApiConfigV2 } from '../../apiConfigV2';
import { NetworkInstanceV2 } from './NetworkInstance';
import { MessageTemplate } from '../../../view/screens/messages/utils/messages';

export class AutomatedMessage {
  client: NetworkInstanceV2;

  constructor(_client: NetworkInstanceV2) {
    this.client = _client;
  }

  updateTemplate = async (id: string, template: Partial<MessageTemplate>) =>
    this.client.patch(
      ApiConfigV2.api.message.updateTemplate.replace('{{templateId}}', id),
      {
        template: encodeURIComponent(template.template as string),
      },
    );

  testTemplate = async (
    id: string,
    payload: { conversationId: string; data: any },
  ) =>
    this.client.post(
      ApiConfigV2.api.message.updateTemplate.replace('{{templateId}}', id),
      payload,
    );

  getTemplate = async (id: string) => {
    return this.client.get(
      ApiConfigV2.api.message.getTemplates.replace('{{templateId}}', id),
    );
  };

  getTriggers = async (page?: number, pageSize?: number) => {
    const config = { params: { page, pageSize } };
    return this.client.get(ApiConfigV2.api.message.getTriggers, config);
  };

  getTriggerTemplates = async (
    id: string,
    page?: number,
    pageSize?: number,
  ) => {
    const config = { params: { page, pageSize } };
    return this.client.get(
      ApiConfigV2.api.message.getTriggerTemplates.replace('{{triggerId}}', id),
      config,
    );
  };
}
