import {
  Box,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  TextField,
} from '@mui/material';
import { useCallback, useContext, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import Config from '../../../config';
import { ApiConfigV2 } from '../../../shared/apiConfigV2';
import {
  encodeQueryStrings,
  useQueryString,
} from '../../../utilities/hooks/useQueryString';
import AuditLogs from '../../components/AuditLogs/AuditLogs';
import SectionHeader from '../../components/SectionHeader/SectionHeader';
import TableViewComponent from '../../components/TableViewComponent/TableViewComponent';
import ConfirmDialog from '../../components/confirmDialog/ConfirmDialog';
import { LoadingView } from '../../components/loadingView';
import { RootViewContext } from '../RootView';
import { useGetDomainVerifiedRequests } from './hooks/useGetDomainRequests';
import { useGetRequestAccessCodes } from './hooks/useGetReqAccessCodes';
import { useUpdateRequests } from './hooks/useUpdateRequest';
import AccessCodeDialog from './showAccessCodesView';
import { DomainVerifiedRequestsGridColumns } from './utils/grid-columns/DomainRequestGridColumn';
import { useHistory } from 'react-router-dom';
import useDebounce from '../../../utilities/hooks/useDebounce';
import { useGetGeneralRequests } from './hooks/useGetGeneralRequests';
import { ClearOutlined } from '@mui/icons-material';
import { NumberFieldStyles } from '../../../shared/styles/Common';

const DefaultPageSize = 10;

const DomainRequestView = () => {
  const queryString = useQueryString({
    arrayFormat: 'separator',
    arrayFormatSeparator: ',',
  });
  const { setAppBreadCrumbs } = useContext(RootViewContext);
  const [pageSize, setPageSize] = useState<number>(
    queryString?.pageSize ? +queryString?.pageSize : DefaultPageSize,
  );
  const [page, setPage] = useState<number>(
    queryString?.page ? +queryString?.page : 0,
  );

  const [requestToApprove, setRequestToApprove] = useState<number | null>(null);
  const { data: accessCodesData, isLoading: isAccessCodesLoading } =
    useGetRequestAccessCodes(requestToApprove);

  const [showAccessCodeDropDown, setShowAccessCodeDropDown] =
    useState<boolean>(false);
  const [refetchAuditLogs, setRefetchAuditLogs] = useState(false);

  const handleRefetchAuditLogs = useCallback(() => {
    setRefetchAuditLogs(!refetchAuditLogs);
  }, [refetchAuditLogs, setRefetchAuditLogs]);

  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState<{
    open: boolean;
    msg: string;
    requestId: number | null;
  }>({ open: false, msg: '', requestId: null });

  const { mutateAsync, isLoading: isUpdating } = useUpdateRequests();
  const [total, setTotal] = useState(0);
  const history = useHistory();

  const [filterInputs, setFilterInputs] = useState<{
    employeeId: string;
    organisation: string;
    workEmail: string;
    personalEmail: string;
  }>({
    employeeId: (queryString?.employeeId || '') as string,
    organisation: (queryString?.organisation || '') as string,
    workEmail: (queryString?.workEmail || '') as string,
    personalEmail: (queryString?.personalEmail || '') as string,
  });
  const debouncedFilter = useDebounce(filterInputs, 500);

  const { data, isLoading, refetch } = useGetDomainVerifiedRequests(
    page,
    pageSize,
    debouncedFilter?.employeeId,
    debouncedFilter?.organisation,
    debouncedFilter?.workEmail,
    debouncedFilter?.personalEmail,
  );

  useEffect(() => {
    const params: Record<string, any> = {
      ...queryString,
      pageSize,
      page,
      employeeId: debouncedFilter?.employeeId,
      organisation: debouncedFilter?.organisation,
      workEmail: debouncedFilter?.workEmail,
      personalEmail: debouncedFilter?.personalEmail,
    };
    history.replace({ search: encodeQueryStrings(params) });
  }, [pageSize, page, debouncedFilter]);

  useEffect(() => {
    setTotal(data?.total ?? total);
  }, [data?.total]);

  /**
   * Handler for Updating the request based on type
   *
   * 1 -> Approve
   * 2 -> Reject
   * @param requestId
   * @param type
   * @returns
   */
  const handleAccessCodeRequest = async (requestId: number, type: string) => {
    try {
      if (!requestId || !type) return;
      if (type === '1') {
        setRequestToApprove(requestId);
        setShowAccessCodeDropDown(true);
      } else if (type === '2') {
        setShowDeleteConfirmation({
          open: true,
          msg: 'Are you sure you want to reject this request?',
          requestId: requestId,
        });
      }
    } catch (err) {
      toast.error('Error occurred while attempting to update request');
    }
  };

  const onRejectConfirm = async (requestId: number) => {
    try {
      await mutateAsync({
        reqId: requestId,
        status: 'rejected',
        accessCode: null,
        type: 'DOMAIN_REQUESTS',
      });
      toast.success('Request rejected successfully');
      refetch();
      handleRefetchAuditLogs();
    } catch (err) {
      toast.error('Error occurred while rejecting the request');
    } finally {
      setShowDeleteConfirmation({ open: false, msg: '', requestId: null });
    }
  };

  useEffect(() => {
    setAppBreadCrumbs([
      {
        label: 'Domain Requests',
        path: Config.paths.triaing.domainRequests,
        type: 'link',
      },
      {
        label: 'Domain Requests',
        type: 'text',
      },
    ]);
  }, []);

  if (isLoading) {
    return <LoadingView />;
  }

  return (
    <Box>
      <SectionHeader title={'Domain Requests'} showDivider />
      <Grid container spacing={0.5} mb={2}>
        <Grid item xs={3} sx={{ p: 0 }}>
          <InputLabel>Employee Id</InputLabel>
          <TextField
            type="string"
            size="small"
            placeholder=""
            value={filterInputs.employeeId}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    sx={{
                      visibility: filterInputs.employeeId
                        ? 'visible'
                        : 'hidden',
                    }}
                    onClick={() =>
                      setFilterInputs((prev) => ({ ...prev, employeeId: '' }))
                    }
                  >
                    <ClearOutlined fontSize="small" />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            onChange={(e) => {
              setFilterInputs((prev) => ({
                ...prev,
                employeeId: e.target.value,
              }));
            }}
            sx={NumberFieldStyles}
          />
        </Grid>
        <Grid item xs={3}>
          <InputLabel>Organisation</InputLabel>
          <TextField
            type="string"
            size="small"
            value={filterInputs.organisation}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    sx={{
                      visibility: filterInputs.organisation
                        ? 'visible'
                        : 'hidden',
                    }}
                    onClick={() =>
                      setFilterInputs((prev) => ({ ...prev, organisation: '' }))
                    }
                  >
                    <ClearOutlined fontSize="small" />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            onChange={(e) => {
              setFilterInputs((prev) => ({
                ...prev,
                organisation: e.target.value,
              }));
            }}
            sx={NumberFieldStyles}
          />
        </Grid>
        <Grid item xs={3}>
          <InputLabel>Personal Email</InputLabel>
          <TextField
            type="string"
            size="small"
            placeholder=""
            value={filterInputs.personalEmail}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    sx={{
                      visibility: filterInputs.personalEmail
                        ? 'visible'
                        : 'hidden',
                    }}
                    onClick={() =>
                      setFilterInputs((prev) => ({
                        ...prev,
                        personalEmail: '',
                      }))
                    }
                  >
                    <ClearOutlined fontSize="small" />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            onChange={(e) => {
              setFilterInputs((prev) => ({
                ...prev,
                personalEmail: e.target.value,
              }));
            }}
            sx={NumberFieldStyles}
          />
        </Grid>
        <Grid item xs={3}>
          <InputLabel>Work Email</InputLabel>
          <TextField
            type="string"
            size="small"
            placeholder=""
            value={filterInputs.workEmail}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    sx={{
                      visibility: filterInputs.workEmail ? 'visible' : 'hidden',
                    }}
                    onClick={() =>
                      setFilterInputs((prev) => ({ ...prev, workEmail: '' }))
                    }
                  >
                    <ClearOutlined fontSize="small" />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            onChange={(e) => {
              setFilterInputs((prev) => ({
                ...prev,
                workEmail: e.target.value,
              }));
            }}
            sx={NumberFieldStyles}
          />
        </Grid>
      </Grid>
      <TableViewComponent
        rows={data.membershipRequests ?? []}
        columns={DomainVerifiedRequestsGridColumns({ handleAccessCodeRequest })}
        pageSizeCustom={pageSize}
        currentPage={page}
        getRowId={(row: any) => row?.id}
        loading={isLoading}
        onPageSizeChange={(pageSize) => {
          setPageSize(pageSize);
        }}
        onPageChange={(page) => {
          setPage(page);
        }}
        paginationMode="server"
        total={data.total}
      />
      {showAccessCodeDropDown && requestToApprove && (
        <AccessCodeDialog
          open={showAccessCodeDropDown}
          accessCodes={accessCodesData}
          handleClose={() => setShowAccessCodeDropDown(false)}
          handleSuccess={() => {
            refetch();
            handleRefetchAuditLogs();
          }}
          reqId={requestToApprove}
          refetch={refetch}
        />
      )}
      <ConfirmDialog
        open={showDeleteConfirmation.open}
        handleClose={() =>
          setShowDeleteConfirmation({ open: false, msg: '', requestId: null })
        }
        onConfirm={() => {
          if (showDeleteConfirmation.requestId !== null) {
            onRejectConfirm(showDeleteConfirmation.requestId);
          }
        }}
        title="Confirm Rejection?"
        cancelButtonTitle="Cancel"
        confirmButtonTitle="Reject"
        disabled={isUpdating}
      >
        {showDeleteConfirmation.msg ||
          'Are you sure you want to reject this request'}
      </ConfirmDialog>
      <AuditLogs
        customUrl={ApiConfigV2.api.auditlog.getDomainRequestsAuditLogs}
        refetch={refetchAuditLogs}
      />
    </Box>
  );
};

export default DomainRequestView;
