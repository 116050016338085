import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  IconButton,
  Typography,
} from '@mui/material';
import { ReactNode } from 'react';
import { MaterialIcons } from '../../../assets/MaterialIcons';
import StyledButton from '../../widgets/styledButton/StyledButton';

const ConfirmDialog = ({
  open,
  handleClose,
  onConfirm,
  onSecondOptionConfirm,
  title,
  cancelButtonTitle,
  confirmButtonTitle,
  children,
  disabled = false,
  confirmSecondaryButtonTitle,
}: {
  open: boolean;
  handleClose: () => void;
  onConfirm: () => void;
  onSecondOptionConfirm?: () => void;
  title: string;
  cancelButtonTitle?: string;
  confirmButtonTitle?: string;
  confirmSecondaryButtonTitle?: string;
  children: ReactNode;
  disabled?: boolean;
}) => (
  <Dialog
    open={open}
    onClose={handleClose}
    aria-labelledby="confirm-title"
    aria-describedby="confirm-dialog-description"
    sx={{
      '.MuiDialog-paper': {
        borderRadius: '0.5rem',
        padding: 1,
        minWidth: '40vw',
      },
      '& .MuiDialogTitle-root': {
        fontWeight: 'bold',
        fontSize: 24,
      },
      '& .MuiDialogContentText-root': {
        fontSize: 18,
        color: '#8C90A6',
      },
    }}
  >
    <Box
      display={'flex'}
      justifyContent={'space-between'}
      padding={'1.5rem 1.5rem 0 1.5rem'}
    >
      <Typography
        variant="h5"
        fontWeight="bold"
        marginRight={5}
        sx={{
          flex: 1,
        }}
      >
        {title}
      </Typography>
      <IconButton onClick={handleClose} aria-label="close" sx={{}}>
        {MaterialIcons.Close}
      </IconButton>
    </Box>
    <DialogContent>
      <DialogContentText id="confirm-dialog-description">
        {children}
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <StyledButton
        variant="ghost"
        size="large"
        onClick={handleClose}
        color="red"
        padding="0.5rem"
        sx={{ width: '7.5rem' }}
        disabled={disabled}
        autoFocus
      >
        {cancelButtonTitle ?? 'No'}
      </StyledButton>
      <StyledButton
        size="large"
        onClick={onConfirm}
        padding="0.5rem"
        sx={{ width: '7.5rem' }}
        disabled={disabled}
      >
        {confirmButtonTitle ?? 'Yes'}
      </StyledButton>
      {confirmSecondaryButtonTitle && (
        <StyledButton
          size="large"
          onClick={onSecondOptionConfirm}
          padding="0.5rem"
          sx={{ width: '7.5rem' }}
          disabled={disabled}
        >
          {confirmSecondaryButtonTitle ?? 'Yes'}
        </StyledButton>
      )}
    </DialogActions>
  </Dialog>
);

export default ConfirmDialog;
