import { StorageItems } from '../shared/constants/App';
import {
  addItemToLocalStorage,
  getItemFromLocalStorage,
  removeItemFromLocalStorage,
} from './Storage';

/**
 * Adds an organisation's visit data with a timestamp,
 * sorts the data based on visits (timestamp), and stores the top 10 most recent organisations.
 *
 * @param orgId
 */
export const AddOrganisationToRecentlyVisited = (orgId: number) => {
  let recentlyVisitedOrgs = GetRecentlyVisitedOrganisations();

  recentlyVisitedOrgs = recentlyVisitedOrgs.filter(
    (item) => item.orgId !== orgId,
  );

  recentlyVisitedOrgs.push({ orgId, time: new Date().getTime() });

  recentlyVisitedOrgs.sort((a, b) => b.time - a.time);

  recentlyVisitedOrgs = recentlyVisitedOrgs.slice(0, 10);

  addItemToLocalStorage(
    StorageItems.RECENTLY_VISITED_ORGS,
    recentlyVisitedOrgs,
  );
};

/**
 * Returns last 10 visited organisations from the localstorage
 * @returns
 */
export const GetRecentlyVisitedOrganisations = () => {
  const existingVisitedOrgs = getItemFromLocalStorage(
    StorageItems.RECENTLY_VISITED_ORGS,
    'string',
  ) as string;

  const recentlyVisitedOrgs = JSON.parse(existingVisitedOrgs || '[]') as {
    orgId: number;
    time: number;
  }[];

  return recentlyVisitedOrgs;
};
