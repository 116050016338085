import { Grid } from '@mui/material';
import { CloudIcon } from '@primer/octicons-react';
import React, { useCallback } from 'react';
import { Accept, useDropzone } from 'react-dropzone';
import { ItemContains } from '../../../utilities/Common';
import toast from 'react-hot-toast';

interface IFileDropZoneProps {
  files: File[];
  setFiles: React.Dispatch<React.SetStateAction<File[]>>;
  disabled?: boolean;
  multiple?: boolean;
  accept?: Accept;
  maxFiles?: number;
  maxSize?: number; // in bytes
  title?: string;
  showIcon?: boolean;
}
/**
 * Accept Example
 * accept: {
    'image/png': ['.png'],
    'text/html': ['.html', '.htm'],
  }
 */

const FileDropZone = ({
  files,
  setFiles,
  multiple,
  disabled = false,
  accept = {},
  title = "Drag 'n' drop some files here, or click to select files",
  showIcon = true,
  maxFiles = 5,
  maxSize,
}: IFileDropZoneProps) => {
  const onDrop = useCallback(
    (acceptedFiles) => {
      const duplicate = acceptedFiles?.filter(
        (newFile: File) =>
          files?.find((item) => ItemContains(item.name, newFile.name)),
      );

      if (duplicate?.length) {
        toast.error('File with same name already exists');
        return;
      }

      setFiles((prev) => [...prev, ...acceptedFiles]);
    },
    [files, setFiles],
  );

  const onError = useCallback((acceptedFiles) => {
    // Do something with the files
  }, []);

  const onDropRejected = useCallback((acceptedFiles) => {
    toast.error(
      <div>
        Files not allowed:
        {acceptedFiles
          ?.map((item: any) => item?.file?.name)
          .map((file: string, index: number) => (
            <div key={index}>
              {index + 1}. {file}
            </div>
          ))}
      </div>,
    );
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    onDropRejected,
    multiple,
    onError,
    disabled,
    accept,
    maxFiles,
    maxSize,
  });

  return (
    <>
      <Grid
        container
        display={'flex'}
        direction="column"
        justifyContent={'center'}
        alignItems={'center'}
        sx={{
          border: '1px dashed',
          borderRadius: 'var(--default-border-radius)',
          padding: '20px',
          backgroundColor: '#fbfbfb',
          cursor: 'pointer',
          '&:hover': {
            backgroundColor: 'white',
          },
        }}
        {...getRootProps()}
      >
        {showIcon && (
          <Grid item xs={12}>
            <CloudIcon size={72} />
          </Grid>
        )}
        <Grid item xs={12}>
          <input {...getInputProps()} />
          <p style={{ fontSize: '16px' }}>{title}</p>
        </Grid>
      </Grid>
    </>
  );
};

export default FileDropZone;
