import {
  AccessAlarmOutlined,
  LinkOffOutlined,
  MoreVertOutlined,
  SaveOutlined,
  SpellcheckOutlined,
  TranslateOutlined,
  CollectionsBookmarkOutlined,
  LocalHospitalOutlined,
  DomainAddOutlined,
  AccountBalanceOutlined,
  Medication,
  InfoOutlined,
} from '@mui/icons-material';

// import LocalHospitalIcon from '@mui/icons-material/LocalHospital';

import {
  ArchiveIcon,
  BookIcon,
  CheckboxIcon,
  ChecklistIcon,
  ChevronRightIcon,
  CommentDiscussionIcon,
  CreditCardIcon,
  EyeClosedIcon,
  EyeIcon,
  FileBadgeIcon,
  GiftIcon,
  GraphIcon,
  HeartIcon,
  HomeIcon,
  HourglassIcon,
  LightBulbIcon,
  PackageDependenciesIcon,
  PackageDependentsIcon,
  PaperAirplaneIcon,
  PasteIcon,
  PencilIcon,
  PeopleIcon,
  PlusIcon,
  PulseIcon,
  RepoIcon,
  RepoPushIcon,
  SearchIcon,
  SortDescIcon,
  StackIcon,
  SyncIcon,
  ToolsIcon,
  TrashIcon,
  TrophyIcon,
  UploadIcon,
  XIcon,
  MailIcon,
  OrganizationIcon,
  DiffAddedIcon,
  ArrowDownIcon,
  LocationIcon,
  HistoryIcon,
  ArrowSwitchIcon,
  ChevronLeftIcon,
  GearIcon,
  FileIcon,
  DeviceDesktopIcon,
  LogIcon,
  DeviceMobileIcon,
  SignOutIcon,
  PersonIcon,
  NoteIcon,
  ArrowUpIcon,
  ChevronUpIcon,
  ChevronDownIcon,
  CheckCircleIcon,
  CalendarIcon,
  DashIcon,
  ProjectSymlinkIcon,
  CodeOfConductIcon,
  CheckIcon,
} from '@primer/octicons-react';

const MaterialIcons = {
  Home: <HomeIcon size={18} />,
  OldContent: <ArchiveIcon size={18} />,
  Leads: <GraphIcon size={18} />,
  Campaigns: <CommentDiscussionIcon size={18} />,
  LearningPath: <BookIcon size={18} />,
  Sessions: <StackIcon size={18} />,
  Catalogue: <RepoIcon size={18} />,
  RescueSession: <PulseIcon size={18} />,
  Journals: <PasteIcon size={18} />,
  Periodic: <HourglassIcon size={18} />,
  Checkins: <ChecklistIcon size={18} />,
  Toolkit: <ToolsIcon size={18} />,
  Rewards: <GiftIcon size={18} />,
  B2B: <PeopleIcon size={18} />,
  Providers: <HeartIcon size={18} />,
  Reports: <UploadIcon size={18} />,
  BillingPlans: <CreditCardIcon size={18} />,
  CreditRequests: <PackageDependentsIcon size={18} />,
  CreditRefunds: <PackageDependenciesIcon size={18} />,
  CreditBalanceIcon: <AccountBalanceOutlined fontSize="small" />,
  SendReports: <PaperAirplaneIcon size={18} />,
  Recommendations: <LightBulbIcon size={18} />,
  Organisation: <OrganizationIcon size={18} />,
  DomainRequests: <ProjectSymlinkIcon size={18} />,

  Edit: <PencilIcon size={18} />,
  Save: <SaveOutlined fontSize="small" />,
  Close: <XIcon size={18} />,
  Add: <PlusIcon size={18} />,
  Minus: <DashIcon size={18} />,
  Eye: <EyeIcon size={18} />,
  EyeClosed: <EyeClosedIcon size={18} />,
  Delete: <TrashIcon size={18} />,
  SpellCheck: <SpellcheckOutlined fontSize="small" />,
  Translate: <TranslateOutlined fontSize="small" />,
  Sync: <SyncIcon size={18} />,
  Switch: <ArrowSwitchIcon size={18} />,
  Publish: <RepoPushIcon size={18} />,
  Sort: <SortDescIcon size={18} />,
  CheckIn: <FileBadgeIcon size={18} />,
  ChevronRight: <ChevronRightIcon size={18} />,
  ChevronLeft: <ChevronLeftIcon size={18} />,
  ChevronUp: <ChevronUpIcon size={18} />,
  ChevronDown: <ChevronDownIcon size={18} />,
  AccessAlarm: <AccessAlarmOutlined />,
  CheckBox: <CheckboxIcon size={18} />,
  CheckBoxCircle: <CheckCircleIcon size={18} />,
  Trophy: <TrophyIcon size={18} />,
  LinkOff: <LinkOffOutlined fontSize="small" />,
  MoreVert: <MoreVertOutlined />,
  Search: <SearchIcon size={18} />,
  Email: <MailIcon size={18} />,
  Collections: <CollectionsBookmarkOutlined fontSize="small" />,
  RenewPlan: <DiffAddedIcon size={16} />,
  ExpandIcon: <ArrowDownIcon size={16} />,
  ArrowUpIcon: <ArrowUpIcon size={16} />,
  InPerson: <LocalHospitalOutlined fontSize="small" />,
  Location: <LocationIcon size={18} />,
  History: <HistoryIcon size={18} />,
  GearIcon: <GearIcon size={18} />,
  FileIcon: <FileIcon size={18} />,
  DesktopIcon: <DeviceDesktopIcon size={18} />,
  MobileIcon: <DeviceMobileIcon size={18} />,
  LogIcon: <LogIcon size={18} />,
  LogoutIcon: <SignOutIcon size={18} />,
  DomainAddIcon: <DomainAddOutlined fontSize="small" />,
  PersonIcon: <PersonIcon size={18} />,
  NoteIcon: <NoteIcon size={18} />,
  CalendarIcon: <CalendarIcon size={18} />,
  MedicationIcon: <Medication fontSize="small" />,
  InfoIcon: <InfoOutlined fontSize="small" />,
  CareIcon: <CodeOfConductIcon size={18} />,
  CheckIcon: <CheckIcon size={18} />,
  Chat: <MailIcon size={18} />,
};
export { MaterialIcons };
