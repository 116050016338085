import React from 'react';
import { SxProps, Theme } from '@mui/material/styles';

import { CheckIcon } from '@primer/octicons-react';
import StyledButton, { StyledButtonColors } from '../styledButton/StyledButton';

type SwitchButtonProps = {
  active: boolean | undefined;
  sx?: SxProps<Theme>;
  children: React.ReactNode;
  activeIcon?: JSX.Element;
  inactiveIcon?: JSX.Element;
  color?: keyof typeof StyledButtonColors;
  [x: string]: unknown;
};

const SwitchButton = ({
  active,
  children,
  activeIcon,
  inactiveIcon,
  sx = [],
  ...otherProps
}: SwitchButtonProps) => (
  <StyledButton
    startIcon={
      active ? activeIcon ?? <CheckIcon size={18} /> : inactiveIcon ?? null
    }
    variant={active ? 'filled' : 'ghost'}
    color="gray"
    sx={[
      {
        border: '1.75px solid',
      },
      ...(Array.isArray(sx) ? sx : [sx]),
    ]}
    {...otherProps}
  >
    {children}
  </StyledButton>
);

export default SwitchButton;
