import Cookies from 'js-cookie';
import {
  ProductionHostname,
  ProductionJPHostname,
} from '../shared/constants/App';
import { gotoRoute } from './Path';
import { removeAllItemsFromLocalStorage } from './Storage';

export const isProduction = () =>
  window.location.hostname === ProductionHostname;

export const isProductionJapan = () =>
  window.location.hostname === ProductionJPHostname;

/**
 * Removes cookie & localstorage
 */
export const logOutUser = () => {
  Cookies.remove('user');
  removeAllItemsFromLocalStorage();
  gotoRoute('/admin/login');
};
