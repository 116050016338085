import { Box, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import { InfoIcon } from '@primer/octicons-react';
import moment from 'moment';

import { CreditPoolTypes } from '../../../../../../../shared/constants/Organisation';
import { getDisplayDate } from '../../../../../../../utilities/Display';
import FeatureBox from '../../../../../../widgets/featureBox';
import SectionCard from '../../../../../../components/SectionCard/SectionCard';

const renderPlanDetails = ({
  pool,
  handleOpenPopover,
}: {
  pool: any;
  handleOpenPopover: (event: any) => void;
}) => {
  return (
    <>
      <Typography fontSize={'12px'} fontWeight={300} sx={{ display: 'block' }}>
        (
        {pool.credits === null &&
          pool.creditPerUser === null &&
          'Unlimited Plan'}
        {pool.credits === null &&
          pool.creditPerUser !== null &&
          'Credits per user plan'}
        {pool.credits !== null && pool.creditPerUser !== null && 'Hybrid plan'}
        {pool.credits !== null && pool.creditPerUser === null && 'Pool plan'})
      </Typography>
      <div
        style={{
          fontSize: 18,
          fontWeight: 400,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          gap: '5px',
        }}
      >
        Utilisation: {pool.creditsUsed}
        {pool.credits !== null && `/ ${pool.credits}`}
        <Tooltip
          title={'Click for a monthly break down'}
          onClick={handleOpenPopover}
          data-pool-id={pool.id}
        >
          <IconButton sx={{ padding: 0 }}>
            <InfoIcon size={16} />
          </IconButton>
        </Tooltip>
      </div>
      <Box
        style={{
          fontSize: 12,
          fontWeight: 300,
        }}
      >
        (Including future sessions)
      </Box>
      <Box className="txt-overflow" fontSize={'14px'}>
        Restrictions:{' '}
        {pool.creditPerUser != null
          ? `${pool.creditPerUser} per user`
          : 'No limit per user'}
      </Box>

      <Box
        className="txt-overflow"
        sx={{
          fontSize: 12,
          fontStyle: 'italic',
          maxWidth: '100%',
        }}
      >
        {pool.notes ? `Note: ${pool.notes.replace(/\n/g, ' ')}` : ''}
      </Box>
    </>
  );
};
const RenderPlans = ({
  credits,
  handleOpenPopover,
}: {
  credits: any[];
  handleOpenPopover: (event: any) => void;
}) => {
  return (
    <Grid container spacing={2}>
      {credits
        ?.sort((a, b) => a.expiresAt - b.expiresAt)
        ?.sort((a, b) => +a.type - +b.type)
        .map((pool: any, index: number) => {
          const diffDays = moment(pool.expiresAt).diff(moment(), 'days');
          return (
            <Grid item xs={12} sm={12} md={4} lg={4} key={pool.id}>
              <SectionCard
                sx={{
                  padding: '10px',
                  textAlign: 'center',
                  minHeight: 156,
                  margin: 0,
                }}
              >
                <Typography fontSize={'18px'} fontWeight={600}>
                  {CreditPoolTypes.find((item) => item.type === pool.type)
                    ?.label || ''}
                </Typography>
                {pool.type !== '10' &&
                  renderPlanDetails({ pool, handleOpenPopover })}
                {pool.type === '10' && 'Same as clinical'}
                <Box
                  fontSize={'12px'}
                  fontWeight={300}
                  className="txt-overflow"
                >
                  Valid: {getDisplayDate(pool.createdAt)} /{' '}
                  {getDisplayDate(pool.expiresAt)}`
                </Box>
              </SectionCard>
            </Grid>
          );
        })}
    </Grid>
  );
};

export default RenderPlans;
