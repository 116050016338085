import { Box } from '@mui/material';
import { useGetPermissions } from './hooks/usePermissions';
import StyledButton from '../../widgets/styledButton/StyledButton';
import { MaterialIcons } from '../../../assets/MaterialIcons';
import { useCallback, useMemo, useState } from 'react';
import { IPermission } from './utils/types/access-control.interface';
import AddPermissionDialog from './AddPermissionDialog';
import TableViewComponent from '../../components/TableViewComponent/TableViewComponent';
import { PermissionGridColumns } from './utils/grid-columns/PermissionGridColumns';
import {
  AclActions,
  AclModuleRows,
  AclModules,
} from '../../../shared/constants/Ability';

function PermissionsList() {
  const [pageSize, setPageSize] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(0);

  const [openPermissionDialog, setOpenPermissionDialog] = useState<{
    open: boolean;
    edit: boolean;
    permission: IPermission | null;
  }>({ open: false, edit: false, permission: null });

  const {
    data: permissions,
    refetch: refetchPermissions,
    isLoading,
  } = useGetPermissions();

  const isAdminAccessAdded = useMemo(() => {
    return (
      permissions?.some(
        (pr) =>
          pr.action === AclActions.Manage && pr.subject === AclModules.ALL,
      ) ?? false
    );
  }, [permissions]);

  const handleAddPermission = useCallback(() => {
    setOpenPermissionDialog({ open: true, edit: false, permission: null });
  }, []);

  const handleDialogClose = useCallback(() => {
    setOpenPermissionDialog({ open: false, edit: false, permission: null });
  }, []);

  const handleDialogSuccess = () => {
    handleDialogClose();
    refetchPermissions();
  };

  const AclModulesMap = useMemo(() => {
    const mapping = AclModuleRows?.reduce(
      (result: Record<string, string>, item) => {
        if (item && !result?.[item?.subject]) {
          result[item.subject] = item?.moduleName;
        }
        return result;
      },
      {},
    );
    mapping[AclModules.ALL] = 'All';

    return mapping;
  }, []);

  return (
    <Box>
      <Box display={'flex'} justifyContent={'flex-end'}>
        <StyledButton
          variant="ghost"
          size="small"
          startIcon={MaterialIcons.Add}
          margin={0}
          onClick={handleAddPermission}
        >
          Add Permission
        </StyledButton>
      </Box>

      <TableViewComponent
        rows={permissions ?? []}
        columns={PermissionGridColumns(setOpenPermissionDialog, AclModulesMap)}
        loading={isLoading}
        pageSizeCustom={pageSize}
        currentPage={currentPage}
        onPageSizeChange={(pageSize) => {
          setPageSize(pageSize);
        }}
        onPageChange={(page) => {
          setCurrentPage(page);
        }}
        paginationMode="client"
        total={permissions?.length}
      />

      <AddPermissionDialog
        open={openPermissionDialog.open}
        edit={openPermissionDialog.edit}
        existingPermission={openPermissionDialog.permission}
        handleClose={handleDialogClose}
        handleSuccess={handleDialogSuccess}
        isAdminAccessAdded={isAdminAccessAdded}
      />
    </Box>
  );
}

export default PermissionsList;
