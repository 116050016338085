import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  Grid,
  TablePagination,
  Typography,
} from '@mui/material';
import { ChevronDownIcon } from '@primer/octicons-react';
import { useCallback, useEffect, useState } from 'react';
import { AuditLogService } from '../../../shared/service/services_v2';
import { IAuditLog } from './utils/types/audit-log.interface';
import AuditLogRow from './AuditLogRow';
import AuditLogDetailsModal from './AuditLogDetailsModal';

interface IAuditLogsProps {
  refId?: string;
  customUrl?: string;
  refetch?: boolean;
}
const AuditLogs = ({ refId = '', customUrl, refetch }: IAuditLogsProps) => {
  const [auditLogs, setAuditLogs] = useState<IAuditLog[]>([]);
  const [total, setTotal] = useState(0);
  const [openDetailView, setOpenDetailView] = useState<{
    open: boolean;
    log: IAuditLog | null;
  }>({
    open: false,
    log: null,
  });

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);

  const getAuditLogs = useCallback(
    async (refId: string, page: number, pageSize: number) => {
      try {
        const response = await AuditLogService.getAuditLogs({
          refId,
          url: customUrl,
          queryParams: {
            take: page * pageSize,
            pageSize,
          },
        });
        if (response && response.data.success) {
          setAuditLogs(response?.data?.data?.items ?? []);
          setTotal(response?.data?.data?.total ?? 0);
        }
      } catch (err) {
        console.log(err);
      }
    },
    [],
  );

  useEffect(() => {
    getAuditLogs(refId, page, pageSize);
  }, [refetch, refId, page, pageSize]);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setPageSize(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <>
      <Accordion
        sx={{
          border: 'none',
        }}
      >
        <AccordionSummary
          sx={{ padding: 0 }}
          expandIcon={<ChevronDownIcon size={18} />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <Typography variant="h6">Audit Logs</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Divider />
          {auditLogs?.length ? (
            <Grid container>
              {auditLogs?.map((log, idx) => {
                return (
                  <AuditLogRow
                    key={log._id}
                    log={log}
                    setOpenDetailView={setOpenDetailView}
                  />
                );
              })}
              <Grid item xs={12}>
                <TablePagination
                  component="div"
                  count={total}
                  page={page}
                  onPageChange={handleChangePage}
                  rowsPerPage={pageSize}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Grid>
            </Grid>
          ) : (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              sx={{ p: '24px' }}
            >
              <>
                <Box display={'flex'} flexDirection={'column'} gap={4}>
                  <Box
                    sx={{
                      fontSize: '16px',
                      color: '#252B2C',
                      textAlign: 'center',
                    }}
                  >
                    Sorry !! No results found
                  </Box>
                </Box>
              </>
            </Box>
          )}
        </AccordionDetails>
      </Accordion>

      <AuditLogDetailsModal
        open={openDetailView.open}
        log={openDetailView.log}
        handleClose={() => setOpenDetailView({ open: false, log: null })}
      />
    </>
  );
};

export default AuditLogs;
