import { NetworkClient } from './network_v2/NetworkInstance';
import { App } from './network_v2/App';
import { CourseV2 } from './network_v2/Course';
import { Catalogue } from './network_v2/Catalogue';
import { OrganisationV2 } from './network_v2/Organisation';
import { BillingV2 } from './network_v2/Billing';
import { MarketingV2 } from './network_v2/Marketing';
import { AuthV2 } from './network_v2/Auth';
import { EmailTemplate } from './network_v2/EmailTemplate';
import { Content } from './network_v2/Content';
import { ProviderV2 } from './network_v2/Provider';
import { Collection } from './network_v2/Collection';
import { RewardV2 } from './network_v2/Reward';
import { ChiefProvider } from './network_v2/ChiefProvider';
import { AuditLog } from './network_v2/AuditLog';
import { UserManagement } from './network_v2/UserManagement';
import { OnSiteDeployment } from './network_v2/OnSiteDeployment';
import { Triaging } from './network_v2/Triaging';
import { UniteV2 } from './network_v2/Unite';
import { AutomatedMessage } from './network_v2/Message';
import { AccessControl } from './network_v2/AccessControl';
import { CareNav } from './network_v2/CareNav';

const AppServiceV2 = new App(NetworkClient);
const CourseServiceV2 = new CourseV2(NetworkClient);
const CatalogueService = new Catalogue(NetworkClient);
const CollectionService = new Collection(NetworkClient);
const AuthServiceV2 = new AuthV2(NetworkClient);
const MarketingServiceV2 = new MarketingV2(NetworkClient);
const OrganisationServiceV2 = new OrganisationV2(NetworkClient);
const ProviderServiceV2 = new ProviderV2(NetworkClient);
// const ContentService = new Content(NetworkClient);
const BillingServiceV2 = new BillingV2(NetworkClient);
const RewardService = new RewardV2(NetworkClient);
const EmailTemplateService = new EmailTemplate(NetworkClient);
const ContentService = new Content(NetworkClient);
const ChiefProviderServiceV2 = new ChiefProvider(NetworkClient);
const AuditLogService = new AuditLog(NetworkClient);
const UserManagementService = new UserManagement(NetworkClient);
const OnSiteDeploymentService = new OnSiteDeployment(NetworkClient);
const TriagingService = new Triaging(NetworkClient);
const UniteService = new UniteV2(NetworkClient);
const AutomatedMessageService = new AutomatedMessage(NetworkClient);
const AccessControlService = new AccessControl(NetworkClient);
const CareNavService = new CareNav(NetworkClient);

export {
  AppServiceV2,
  CourseServiceV2,
  CatalogueService,
  OrganisationServiceV2,
  BillingServiceV2,
  MarketingServiceV2,
  AuthServiceV2,
  EmailTemplateService,
  ContentService,
  ProviderServiceV2,
  CollectionService,
  RewardService,
  ChiefProviderServiceV2,
  AuditLogService,
  UserManagementService,
  OnSiteDeploymentService,
  TriagingService,
  UniteService,
  AutomatedMessageService,
  AccessControlService,
  CareNavService,
};
