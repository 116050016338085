import React from 'react';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import AssignmentIcon from '@mui/icons-material/Assignment';
import { Link } from 'react-router-dom';
import {
  Collapse,
  Divider,
  List,
  ListItem,
  ListItemButton,
  Tooltip,
  Typography,
} from '@mui/material';

export interface ISideBarItem {
  title: string;
  path: string;
  icon: any;
  children?: ISideBarItem[];
}

export const SideBarItem = ({
  item,
  activeItem,
  setActiveItem,
  open,
}: {
  item: ISideBarItem;
  activeItem: string[];
  setActiveItem: (item: string[]) => void;
  open: boolean;
}) => {
  return (
    <div>
      {open && (
        <ListItem
          button
          component={Link}
          to={item.path}
          onClick={() => setActiveItem([item.title])}
          selected={activeItem?.includes(item.title)}
        >
          <ListItemText
            primary={
              <Typography
                fontWeight="bold"
                color={'#667085'}
                sx={{
                  ...(activeItem?.includes(item.title)
                    ? { color: '#000' }
                    : { color: '#667085' }),
                }}
              >
                {item.title}
              </Typography>
            }
          />
        </ListItem>
      )}

      <Collapse timeout="auto" unmountOnExit in>
        {item.children &&
          item.children.map((subItem: ISideBarItem) => (
            <Tooltip
              key={subItem.title}
              title={open ? '' : subItem.title}
              placement="right"
            >
              <List key={subItem.title} component="div" disablePadding>
                <ListItemButton
                  sx={{
                    margin: '0rem',
                    paddingY: '0.25rem',
                    paddingLeft: '1.5rem',
                  }}
                  component={Link}
                  to={subItem.path}
                  selected={activeItem?.includes(subItem.title)}
                  onClick={() => setActiveItem([subItem.title])}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: '32px',
                      ...(activeItem?.includes(subItem.title)
                        ? { color: '#000' }
                        : { color: '#667085' }),
                    }}
                  >
                    {subItem.icon}
                  </ListItemIcon>
                  <ListItemText
                    sx={{ opacity: open ? 1 : 0 }}
                    primary={
                      <Typography
                        fontWeight="500"
                        color={
                          activeItem?.includes(subItem.title)
                            ? '#000'
                            : '#667085'
                        }
                        fontSize={'14px'}
                      >
                        {subItem.title}
                      </Typography>
                    }
                  />
                </ListItemButton>
              </List>
            </Tooltip>
          ))}
      </Collapse>
      <Divider variant="middle" />
    </div>
  );
};

export const secondaryListItems = (
  <div>
    <ListSubheader inset>Saved reports</ListSubheader>
    <ListItem button>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Current month" />
    </ListItem>
    <ListItem button>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Last quarter" />
    </ListItem>
    <ListItem button>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Year-end sale" />
    </ListItem>
  </div>
);
