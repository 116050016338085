import { Switch } from 'react-router-dom';
import { AuthenticatedRoute } from '../../components/authenticatedRoute/AuthenticatedRoute';
import Config from '../../../config';

import AccessControlHome from './AccessControlHome';

const AccessControlView = () => {
  return (
    <Switch>
      <AuthenticatedRoute
        exact
        path={Config.paths.accessControl.home}
        component={AccessControlHome}
      />
    </Switch>
  );
};

export default AccessControlView;
