import { useQuery } from '@tanstack/react-query';
import { TriagingService } from '../../../../shared/service/services_v2';

export const useGetDomainVerifiedRequests = (
  page: any,
  pageSize: any,
  employeeId: any,
  organisation: any,
  workEmail?: any,
  personalEmail?: any,
) =>
  useQuery({
    queryKey: [
      'domainVerifiedRequests',
      page,
      pageSize,
      employeeId,
      organisation,
      workEmail,
      personalEmail,
    ],
    queryFn: async () => {
      const response = await TriagingService.getDomainVerifiedRequests(
        page,
        pageSize,
        employeeId,
        organisation,
        workEmail,
        personalEmail,
      );
      return response.data.data;
    },
    meta: {
      errorMessage:
        'An error occurred while attempting to get the domain requests.',
    },
  });
