import {
  Box,
  CircularProgress,
  IconButton,
  InputAdornment,
  TextField,
} from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import toast from 'react-hot-toast';

import { MaterialIcons } from '../../../../../../assets/MaterialIcons';
import { OrganisationServiceV2 } from '../../../../../../shared/service/services_v2';
import { OrganisationTeamItem } from '../../../../../../shared/types/organisation/OrganisationTeamItem';
import StyledButton from '../../../../../widgets/styledButton/StyledButton';
import { useGetOrgTeamsWithMembersCount } from '../hooks/useGetOrgTeamsWithMembersCount';
import { OrganisationoDetailContext } from '../OrganisationDetailView';
import AddTeamDialog from './AddTeamDialog';
import TeamList from './TeamList';
import { useQueryClient } from '@tanstack/react-query';
import useDebounce from '../../../../../../utilities/hooks/useDebounce';
import { SearchIcon } from '@primer/octicons-react';
import { ClearOutlined } from '@mui/icons-material';
import { getAllTheTeams } from './LinkToTeamDialog';
import {
  AclActions,
  AclModules,
} from '../../../../../../shared/constants/Ability';

const TeamView = () => {
  const { orgData, refetchOrgTeams, refetchMembersCount, getOrgData } =
    useContext(OrganisationoDetailContext);
  const [currentlyExpandedTeams, setCurrentlyExpandedTeams] = useState<{
    [teamId: number]: boolean;
  }>({});
  const [teams, setTeams] = useState<OrganisationTeamItem[]>([]);
  const [showAddTeamDialog, setShowAddTeamDialog] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const [teamNameFilter, setTeamNameFilter] = useState('');
  const debouncedTeamName = useDebounce(teamNameFilter, 500);
  const queryClient = useQueryClient();

  const {
    data,
    refetch: refreshTeams,
    isLoading,
    fetchNextPage,
  } = useGetOrgTeamsWithMembersCount(orgData?.id, debouncedTeamName);

  const resetInfiniteScrollTeamsQuery = async () => {
    await queryClient.setQueryData(
      ['org-teams-with-members-count', orgData?.id, debouncedTeamName],
      () => ({
        pages: [],
        pageParams: [1],
      }),
    );
    await refreshTeams();
  };

  const handleRefreshAllTeams = async () => {
    refetchOrgTeams();
    refetchMembersCount();
    resetInfiniteScrollTeamsQuery();
  };

  useEffect(() => {
    setTeams(data?.pages ?? []);
  }, [data]);

  useEffect(() => {
    if (debouncedTeamName) {
      const allTeams: Partial<OrganisationTeamItem>[] = [];
      getAllTheTeams(teams, allTeams);

      const teamsMap = allTeams?.reduce(
        (result: Record<number, boolean>, team) => {
          if (team?.id) {
            result[team.id] = true;
          }

          return result;
        },
        {},
      );
      setCurrentlyExpandedTeams(teamsMap);
    } else {
      setCurrentlyExpandedTeams({});
    }
  }, [teams, debouncedTeamName]);

  const handleAddTeam = async (name: string) => {
    let response = null;
    try {
      if (!orgData?.id) return;

      setLoading(true);
      response = await OrganisationServiceV2.addTeam(orgData?.id, {
        teamName: name,
      });
    } catch (e) {
      toast.error('An error occurred while attempting to add the new team.');
    } finally {
      setLoading(false);
    }
    if (response && response.data.success) {
      toast.success('Team added successfully!');
      setShowAddTeamDialog(false);
      handleRefreshAllTeams();
    }
  };

  const setTeamAsDefault = async (id: number) => {
    let response = null;
    try {
      if (!orgData?.id) return;

      response = await OrganisationServiceV2.update(orgData.id, {
        name: orgData.name,
        defaultTeamId: id,
      });
    } catch (e) {
      toast.error(
        'An error occurred while attempting to set the team as default.',
      );
    }
    if (response && response.data.success) {
      toast.success('Team set as default.');
      getOrgData();
    }
  };
  const disableTeamAsDefault = async (id: null) => {
    let response = null;
    try {
      if (!orgData?.id) return;

      response = await OrganisationServiceV2.update(orgData.id, {
        name: orgData.name,
        defaultTeamId: null,
      });
    } catch (e) {
      toast.error(
        'An error occurred while attempting to remove the team as default.',
      );
    }
    if (response && response.data.success) {
      toast.success('Team removed as default');
      handleRefreshAllTeams();
    }
  };

  return (
    <>
      <Box display="flex" flexDirection="column">
        <Box
          display={'flex'}
          justifyContent={'flex-end'}
          gap={2}
          alignItems={'center'}
        >
          <TextField
            size="small"
            placeholder="Search Teams by Name"
            value={teamNameFilter}
            onChange={(e) => setTeamNameFilter(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon size={18} fill="#8c90a6" />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    sx={{
                      visibility: teamNameFilter ? 'visible' : 'hidden',
                    }}
                    onClick={() => setTeamNameFilter('')}
                  >
                    <ClearOutlined fontSize="small" />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <StyledButton
            variant="ghost"
            color="gray"
            startIcon={MaterialIcons.Add}
            onClick={() => setShowAddTeamDialog(true)}
            sx={{ alignSelf: 'flex-end' }}
            margin={0}
            permission={{
              action: AclActions.Create,
              subject: AclModules.ORGANISATION,
            }}
          >
            Add team
          </StyledButton>
        </Box>
        {isLoading && (
          <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
            <CircularProgress color="secondary" />
          </Box>
        )}
        {!isLoading && orgData ? (
          <TeamList
            teams={teams}
            loading={isLoading}
            currentlyExpandedTeams={currentlyExpandedTeams}
            setCurrentlyExpandedTeams={setCurrentlyExpandedTeams}
            defaultTeamId={orgData?.defaultTeamId}
            refreshTeams={handleRefreshAllTeams}
            setTeamAsDefault={setTeamAsDefault}
            orgId={orgData?.id}
            disableTeamAsDefault={disableTeamAsDefault}
            fetchMoreTeams={() => fetchNextPage()}
            teamNameFilter={debouncedTeamName}
          />
        ) : null}
      </Box>
      <AddTeamDialog
        open={showAddTeamDialog}
        handleClose={() => setShowAddTeamDialog(false)}
        handleSubmit={handleAddTeam}
        loading={loading}
      />
    </>
  );
};

export default TeamView;
