import { Switch } from 'react-router-dom';

import Config from '../../../../config';
import { AuthenticatedRoute } from '../../../components/authenticatedRoute/AuthenticatedRoute';
import OrganisationDetailView from './organisationDetail/OrganisationDetailView';
import OrganisationList from './organisationList/OrganisationList';
import { AclActions, AclModules } from '../../../../shared/constants/Ability';

const OrganisationViewV3 = () => (
  <Switch>
    <AuthenticatedRoute
      exact
      path={Config.paths.organisationV3}
      component={OrganisationList}
      action={AclActions.Read}
      subject={AclModules.ORGANISATION}
    />
    <AuthenticatedRoute
      exact
      path={`${Config.paths.organisationV3}/:orgId`}
      component={OrganisationDetailView}
      action={AclActions.Read}
      subject={AclModules.ORGANISATION}
    />
  </Switch>
);

export default OrganisationViewV3;
