import { useMutation, useQuery, UseQueryResult } from '@tanstack/react-query';
import {
  AccessControlService,
  UserManagementService,
} from '../../../../shared/service/services_v2';
import {
  IAssociatedRole,
  IPermission,
} from '../utils/types/access-control.interface';
import toast from 'react-hot-toast';
import { AxiosError } from 'axios';

export const useGetRoles = (): UseQueryResult<IAssociatedRole[]> => {
  return useQuery<IAssociatedRole[]>({
    queryKey: ['access-control-roles'],
    queryFn: async () => {
      const response = await UserManagementService.getRoles();

      return response?.data?.data ?? [];
    },
  });
};

export const useRoleDetails = (
  roleId?: number,
): UseQueryResult<IAssociatedRole> => {
  return useQuery<IAssociatedRole>({
    queryKey: ['access-control-role-details', roleId],
    queryFn: async () => {
      if (!roleId) return;

      const response = await AccessControlService.getRoleDetails(roleId);

      return response?.data?.data ?? [];
    },
    enabled: Boolean(roleId),
  });
};

export const useCreateRole = (onSuccess: () => void) =>
  useMutation({
    mutationKey: ['acl-create-role'],
    mutationFn: (body: { name: string }) =>
      AccessControlService.createRole(body),
    onSuccess: () => {
      toast.success('Role created successfully!');
      onSuccess();
    },
    onError: (error: AxiosError) => {
      const errorMsg = error?.response?.data?.message ?? 'Something went wrong';
      console.log('Error:', errorMsg);
      toast.error(errorMsg);
    },
  });

export const useUpdateRole = (onSuccess: () => void) =>
  useMutation({
    mutationKey: ['acl-update-role'],
    mutationFn: (body: { roleId: number; roleData: { name: string } }) => {
      const { roleId, roleData } = body;

      return AccessControlService.updateRole(roleId, roleData);
    },
    onSuccess: () => {
      toast.success('Role updated successfully!');
      onSuccess();
    },
    onError: (error: AxiosError) => {
      const errorMsg = error?.response?.data?.message ?? 'Something went wrong';
      console.log('Error:', errorMsg);
      toast.error(errorMsg);
    },
  });

export const useAssignPermissionsToRole = (onSuccess?: () => void) =>
  useMutation({
    mutationKey: ['acl-assign-permissions-to-role'],
    mutationFn: (body: { roleId: number; permissionIds: number[] }) => {
      const { roleId, ...rest } = body;
      return AccessControlService.assignPermissionsToRole(roleId, rest);
    },
    onSuccess: () => {
      toast.success('Permissions assigned successfully!');
      onSuccess && onSuccess();
    },
    onError: (error: AxiosError) => {
      const errorMsg = error?.response?.data?.message ?? 'Something went wrong';
      console.log('Error:', errorMsg);
      toast.error(errorMsg);
    },
  });

export const useRolePermissions = (
  roleIds?: number[],
): UseQueryResult<IPermission[]> => {
  return useQuery<IPermission[]>({
    queryKey: ['access-control-role-permissions', roleIds],
    queryFn: async () => {
      const response = await AccessControlService.getRolePermissions(
        roleIds ?? [],
      );

      return response?.data?.data?.rolePermissions ?? [];
    },
    enabled: Boolean(roleIds),
  });
};
