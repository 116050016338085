import React, { useContext } from 'react';
import toast from 'react-hot-toast';
import { Redirect, Route, RouteProps, useLocation } from 'react-router-dom';

import Config from '../../../config';
import { getUserCookie } from '../../../utilities/Api';
import { AclActions, AclModules } from '../../../shared/constants/Ability';
import { AbilityContext } from '../Acl/Can';

interface IAuthenticatedRouteProps extends RouteProps {
  action?: AclActions;
  subject?: AclModules;
}

export const AuthenticatedRoute = ({
  component: Component,
  path,
  action,
  subject,
}: IAuthenticatedRouteProps) => {
  const ability = useContext(AbilityContext);
  const isLoggedIn = !!getUserCookie();

  const prevLocation = useLocation();
  if (!isLoggedIn) {
    toast.error('Your session has timed out. Please sign in again.');
    return (
      <Redirect
        to={`${Config.paths.login}?redirectTo=${prevLocation.pathname}`}
      />
    );
  }

  if (
    action &&
    subject &&
    !ability.can(action, subject) &&
    ![Config.paths.unauthorised, Config.paths.realHome]?.includes(
      prevLocation.pathname,
    )
  ) {
    return <Redirect to={Config.paths.unauthorised} />;
  }

  return <Route component={Component} path={path} />;
};
