import { Box } from '@mui/material';
import { useCallback, useState } from 'react';
import { MaterialIcons } from '../../../assets/MaterialIcons';
import TableViewComponent from '../../components/TableViewComponent/TableViewComponent';
import StyledButton from '../../widgets/styledButton/StyledButton';
import AddRoleDialog from './AddRoleDialog';
import AssignPermissionsToRoleDialog from './AssignPermissionsToRoleDialog';
import { useGetPermissions } from './hooks/usePermissions';
import { useGetRoles } from './hooks/useRoles';
import { RoleGridColumns } from './utils/grid-columns/RoleGridColumns';
import { IAssociatedRole } from './utils/types/access-control.interface';

function RolesList() {
  const [pageSize, setPageSize] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(0);

  const [openRoleDialog, setOpenRoleDialog] = useState<{
    open: boolean;
    edit: boolean;
    role: IAssociatedRole | null;
  }>({ open: false, edit: false, role: null });

  const [openManagePermissionsDialog, setOpenManagePermissionsDialog] =
    useState<{
      open: boolean;
      role: IAssociatedRole | null;
    }>({ open: false, role: null });

  const { data: roles, isLoading, refetch: refetchRoles } = useGetRoles();
  const { data: permissions } = useGetPermissions();

  const handleAddRole = useCallback(() => {
    setOpenRoleDialog({ open: true, edit: false, role: null });
  }, []);

  const handleDialogClose = useCallback(() => {
    setOpenRoleDialog({ open: false, edit: false, role: null });
  }, []);

  const handleDialogSuccess = () => {
    handleDialogClose();
    refetchRoles();
  };

  const handlePermissionsDialogClose = useCallback(() => {
    setOpenManagePermissionsDialog({ open: false, role: null });
  }, []);

  const handlePermissionsDialogSuccess = () => {
    handlePermissionsDialogClose();
    refetchRoles();
  };

  return (
    <Box>
      <Box display={'flex'} justifyContent={'flex-end'}>
        <StyledButton
          variant="ghost"
          size="small"
          startIcon={MaterialIcons.Add}
          margin={0}
          onClick={handleAddRole}
        >
          Add Role
        </StyledButton>
      </Box>
      <TableViewComponent
        rows={roles ?? []}
        columns={RoleGridColumns(
          setOpenRoleDialog,
          setOpenManagePermissionsDialog,
        )}
        loading={isLoading}
        pageSizeCustom={pageSize}
        currentPage={currentPage}
        onPageSizeChange={(pageSize) => {
          setPageSize(pageSize);
        }}
        onPageChange={(page) => {
          setCurrentPage(page);
        }}
        paginationMode="client"
        total={roles?.length}
      />

      <AddRoleDialog
        open={openRoleDialog.open}
        edit={openRoleDialog.edit}
        existingRole={openRoleDialog.role}
        handleClose={handleDialogClose}
        handleSuccess={handleDialogSuccess}
      />

      <AssignPermissionsToRoleDialog
        open={openManagePermissionsDialog.open}
        role={openManagePermissionsDialog.role}
        handleClose={handlePermissionsDialogClose}
        permissions={permissions}
        handleSuccess={handlePermissionsDialogSuccess}
      />
    </Box>
  );
}

export default RolesList;
