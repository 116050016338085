import { useQuery } from '@tanstack/react-query';
import { TriagingService } from '../../../../shared/service/services_v2';

export const useGetGeneralRequests = (
  page: any,
  pageSize: any,
  reqType: any,
  employeeId?: any,
  organisation?: any,
  workEmail?: any,
  personalEmail?: any,
) =>
  useQuery({
    queryKey: [
      'generalRequests',
      page,
      pageSize,
      reqType,
      employeeId,
      organisation,
      workEmail,
      personalEmail,
    ],
    queryFn: async () => {
      const response = await TriagingService.getGeneralRequests(
        page,
        pageSize,
        reqType,
        employeeId,
        organisation,
        workEmail,
        personalEmail,
      );
      return response.data.data;
    },
    meta: {
      errorMessage:
        'An error occurred while attempting to get the domain requests.',
    },
  });
