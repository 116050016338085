import { Box, IconButton, Tooltip, Typography } from '@mui/material';
import { useContext, useMemo } from 'react';
import StyledButton from '../../../../widgets/styledButton/StyledButton';
import { MaterialIcons } from '../../../../../assets/MaterialIcons';
import {
  OrganisationEditingModules,
  OrganisationoDetailContext,
} from './OrganisationDetailView';
import { AbilityContext } from '../../../../components/Acl/Can';
import {
  AclActions,
  AclModules,
} from '../../../../../shared/constants/Ability';

interface IOrganisationDetailSectionHeaderProps {
  title: string;
  editingModule: keyof OrganisationEditingModules;
  customLoading?: boolean;
  customCallbacks?: boolean;
  customSaveCallback?: () => void;
  customCancelCallback?: () => void;
}

const OrganisationDetailSectionHeader = ({
  title,
  editingModule,
  customCallbacks = false,
  customSaveCallback,
  customCancelCallback,
  customLoading = false,
}: IOrganisationDetailSectionHeaderProps) => {
  const {
    isEditing,
    setIsEditing,
    handleOrganisationUpdate,
    loading,
    orgData,
    setUpdatedData,
  } = useContext(OrganisationoDetailContext);
  const ability = useContext(AbilityContext);

  const anyActiveEditMode = useMemo(() => {
    return Object.values(isEditing ?? {})?.some((item) => item);
  }, [isEditing]);

  return (
    <Box display={'flex'} justifyContent={'space-between'} height={'2rem'}>
      <Typography variant="h6" className="txt-overflow ">
        {title}
      </Typography>

      <Box>
        {!isEditing?.[editingModule] ? (
          <Tooltip title={`Edit ${title || 'Details'}`}>
            <IconButton
              onClick={() =>
                setIsEditing((prev) => ({
                  ...prev,
                  [editingModule]: true,
                }))
              }
              disabled={
                !ability.can(AclActions.Manage, AclModules.ORGANISATION) ||
                anyActiveEditMode
              }
            >
              {MaterialIcons.Edit}
            </IconButton>
          </Tooltip>
        ) : (
          <Box>
            <StyledButton
              color="gray"
              variant="ghost"
              startIcon={MaterialIcons.Save}
              disabled={loading || customLoading}
              onClick={async () => {
                if (customCallbacks) {
                  customSaveCallback && customSaveCallback();
                } else {
                  handleOrganisationUpdate();
                  setIsEditing((prev) => ({
                    ...prev,
                    [editingModule]: false,
                  }));
                }
              }}
              sx={{ margin: 0, width: '20px' }}
            >
              {''}
            </StyledButton>
            <StyledButton
              color="red"
              variant="ghost"
              startIcon={MaterialIcons.Close}
              onClick={() => {
                if (!customCallbacks) {
                  if (orgData) setUpdatedData({ ...orgData });
                } else {
                  customCancelCallback && customCancelCallback();
                }

                setIsEditing((prev) => ({
                  ...prev,
                  [editingModule]: false,
                }));
              }}
              sx={{ margin: 0, width: '20px' }}
            >
              {''}
            </StyledButton>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default OrganisationDetailSectionHeader;
