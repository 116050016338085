import { Box, IconButton, Tooltip, Typography } from '@mui/material';
import { ArrowRightIcon } from '@primer/octicons-react';
import { useEffect, useMemo, useRef, useState } from 'react';

import { MaterialIcons } from '../../../../../../assets/MaterialIcons';
import { OrganisationTeamItem } from '../../../../../../shared/types/organisation/OrganisationTeamItem';
import {
  getChildTeamCountSum,
  ItemContains,
} from '../../../../../../utilities/Common';
import StyledButton from '../../../../../widgets/styledButton/StyledButton';
import DeleteTeamDialog from './DeleteTeamDialog';
import EditTeamDialog from './EditTeamDialog';
import SectionCard from '../../../../../components/SectionCard/SectionCard';

const TeamList = ({
  teams,
  currentlyExpandedTeams,
  setCurrentlyExpandedTeams,
  defaultTeamId,
  refreshTeams,
  setTeamAsDefault,
  orgId, // handleDeleteTeam,
  disableTeamAsDefault,
  fetchMoreTeams,
  teamNameFilter,
  loading = false,
  isChildTeamView = false,
}: {
  teams: OrganisationTeamItem[];
  currentlyExpandedTeams: {
    [teamId: number]: boolean;
  };
  setCurrentlyExpandedTeams: React.Dispatch<
    React.SetStateAction<{
      [teamId: number]: boolean;
    }>
  >;
  defaultTeamId: number | null;
  refreshTeams: () => void;
  setTeamAsDefault: (id: number) => void;
  orgId: number;
  disableTeamAsDefault: (id: null) => void;
  hasMore?: boolean;
  loading?: boolean;
  fetchMoreTeams?: () => void;
  isChildTeamView?: boolean;
  teamNameFilter: string;
  // handleDeleteTeam: (id: number) => void;
}) => {
  const [showEditTeamDialog, setShowEditTeamDialog] = useState<boolean>(false);
  const [teamToEdit, setTeamToEdit] = useState<OrganisationTeamItem | null>(
    null,
  );
  const [teamToDelete, setTeamToDelete] = useState<OrganisationTeamItem | null>(
    null,
  );
  const [showDeleteTeamDialog, setShowDeleteTeamDialog] =
    useState<boolean>(false);

  const handleClose = () => {
    setShowEditTeamDialog(false);
    setTeamToEdit(null);
    setShowDeleteTeamDialog(false);
  };

  const teamsWithCount = useMemo(() => {
    return teams?.map((team) => ({
      childMemberCount: getChildTeamCountSum(team),
      ...team,
    }));
  }, [teams]);

  const observerTarget = useRef(null);

  useEffect(() => {
    if (loading) return;

    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && !isChildTeamView) {
          fetchMoreTeams && fetchMoreTeams();
        }
      },
      { threshold: 1 },
    );

    if (observerTarget.current) {
      observer.observe(observerTarget.current);
    }

    return () => {
      if (observerTarget.current) {
        observer.unobserve(observerTarget.current);
      }
    };
  }, [observerTarget, loading, fetchMoreTeams, isChildTeamView]);

  return (
    <>
      <Box>
        {teamsWithCount &&
          teamsWithCount?.map((team) => {
            const isCoachEnabled = team.addOns && team.addOns.includes('coach');

            return (
              <Box key={`childTeam-${team.id}`}>
                <SectionCard
                  sx={{
                    display: 'grid',
                    gridTemplateColumns: '2fr 2fr',
                    alignItems: 'center',
                    padding: 1,
                    ...(teamNameFilter &&
                    ItemContains(team?.name, teamNameFilter)
                      ? { borderColor: '#F79009', backgroundColor: '#FFFAEB' }
                      : {}),
                    // marginBottom: !team.parentTeamId ? 5 : 2,
                  }}
                  // padding={2}
                >
                  <Box display="flex" alignItems="center">
                    <IconButton
                      sx={{
                        transform: currentlyExpandedTeams[team.id]
                          ? 'rotate(90deg)'
                          : 'rotate(0deg)',
                        visibility:
                          team.childTeams && team.childTeams.length > 0
                            ? 'visible'
                            : 'hidden',
                      }}
                      onClick={() => {
                        setCurrentlyExpandedTeams({
                          ...currentlyExpandedTeams,
                          [team.id]: !currentlyExpandedTeams[team.id],
                        });
                      }}
                      disabled={team.childTeams && team.childTeams.length === 0}
                      size="large"
                    >
                      {MaterialIcons.ChevronRight}
                    </IconButton>
                    <Box>
                      <Box display="flex" gap="16px" alignItems="center">
                        <Tooltip title={team.name}>
                          <Typography
                            variant="h6"
                            fontWeight="bold"
                            className="txt-overflow"
                            maxWidth={'350px'}
                          >
                            {team.name}
                          </Typography>
                        </Tooltip>
                        <Typography
                          style={{
                            backgroundColor: team?.isActive
                              ? 'var(--chip-green-bg-color)'
                              : 'var(--chip-red-bg-color)',
                            color: team?.isActive
                              ? 'var(--chip-green-color)'
                              : 'var(--chip-red-color)',
                            alignItems: 'center',
                            paddingLeft: 5,
                            paddingRight: 5,
                            borderRadius: 5,
                            height: 25,
                            marginTop: '4px',
                          }}
                          textTransform="initial"
                        >
                          {team?.isActive ? 'Active' : 'In-Active'}
                        </Typography>
                      </Box>
                      <Tooltip
                        title={
                          <>
                            <Typography
                              color="inherit"
                              display={'block'}
                              fontWeight={'bold'}
                            >
                              Current: {team?.memberCount ?? 0}
                            </Typography>
                            <Typography
                              color="inherit"
                              display={'block'}
                              fontWeight={'bold'}
                            >
                              Child: {team?.childMemberCount ?? 0}
                            </Typography>
                          </>
                        }
                      >
                        <Typography variant="subtitle2">
                          {`Members: ${
                            (team?.memberCount ?? 0) +
                            (team?.childMemberCount ?? 0)
                          }`}
                        </Typography>
                      </Tooltip>
                    </Box>
                  </Box>

                  {/* <Typography
                    fontWeight="bold"
                    color={isCoachEnabled ? 'success.main' : '#8C90A6'}
                  >
                    Coach
                    {isCoachEnabled
                      ? `: ${team.coachCredits || 0} credits`
                      : ' disabled'}
                  </Typography> */}
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="flex-end"
                  >
                    {team?.childTeams?.length < 1 && (
                      <StyledButton
                        color="red"
                        variant="ghost"
                        startIcon={MaterialIcons.Delete}
                        onClick={() => {
                          setShowDeleteTeamDialog(true);
                          setTeamToDelete(team);
                        }}
                      >
                        Delete
                      </StyledButton>
                    )}

                    <StyledButton
                      size="small"
                      onClick={() => {
                        if (defaultTeamId === team.id) {
                          disableTeamAsDefault(null);
                        } else {
                          setTeamAsDefault(team.id);
                        }
                      }}
                      sx={{
                        width: '150px',
                        color:
                          defaultTeamId === team.id
                            ? 'var(--chip-green-color)'
                            : 'var(--chip-blue-color)',
                        backgroundColor:
                          defaultTeamId === team.id
                            ? 'var(--chip-green-bg-color)'
                            : 'var(--chip-blue-bg-color)',
                        '&:hover': {
                          backgroundColor:
                            defaultTeamId === team.id
                              ? 'var(--chip-green-bg-color)'
                              : 'var(--chip-blue-bg-color)',
                        },
                      }}
                    >
                      {defaultTeamId === team.id ? 'Default' : 'Set Default'}
                    </StyledButton>
                    <StyledButton
                      size="small"
                      color="gray"
                      margin="0 1rem"
                      variant="ghost"
                      endIcon={<ArrowRightIcon size={18} />}
                      onClick={() => {
                        setShowEditTeamDialog(true);
                        setTeamToEdit(team);
                      }}
                    >
                      View
                    </StyledButton>
                  </Box>
                </SectionCard>
                {currentlyExpandedTeams[team.id] && team.childTeams && (
                  <Box marginLeft={2}>
                    <TeamList
                      teams={team.childTeams}
                      currentlyExpandedTeams={currentlyExpandedTeams}
                      setCurrentlyExpandedTeams={setCurrentlyExpandedTeams}
                      defaultTeamId={defaultTeamId}
                      refreshTeams={refreshTeams}
                      setTeamAsDefault={setTeamAsDefault}
                      orgId={orgId}
                      disableTeamAsDefault={disableTeamAsDefault}
                      isChildTeamView={false}
                      teamNameFilter={teamNameFilter}
                    />
                  </Box>
                )}
              </Box>
            );
          })}
      </Box>
      <Box ref={observerTarget}></Box>

      <EditTeamDialog
        open={showEditTeamDialog}
        teamToEdit={teamToEdit}
        handleClose={handleClose}
        refreshTeams={refreshTeams}
        orgId={orgId}
      />
      <DeleteTeamDialog
        open={showDeleteTeamDialog}
        refreshTeams={refreshTeams}
        handleClose={handleClose}
        teamToDelete={teamToDelete}
        orgId={orgId}
      />
    </>
  );
};

export default TeamList;
