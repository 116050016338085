// ** React Imports
import { ReactNode, useContext } from 'react';

import { AbilityContext } from './Can';
import { NavGroup, NavLink } from '../../../shared/types/Navbar/Navbar';

interface Props {
  navGroup?: NavGroup;
  children: ReactNode;
}

const CanViewNavGroup = (props: Props) => {
  // ** Props
  const { children, navGroup } = props;

  // ** Hook
  const ability = useContext(AbilityContext);

  const canViewMenuGroup = (item: NavGroup) => {
    if (item?.alwaysShow) return true;

    const hasAnyVisibleChild =
      item.children &&
      item.children.some(
        (i: NavLink) =>
          (ability &&
            i?.action &&
            i?.subject &&
            ability.can(i?.action, i.subject)) ||
          i?.alwaysShow,
      );

    if (!(item.action && item.subject)) {
      return hasAnyVisibleChild;
    }

    return (
      ability && ability.can(item.action, item.subject) && hasAnyVisibleChild
    );
  };

  return navGroup && canViewMenuGroup(navGroup) ? <>{children}</> : null;
};

export default CanViewNavGroup;
