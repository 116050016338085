import {
  Autocomplete,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Popover,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { Fragment, useEffect, useState } from 'react';
import { MaterialIcons } from '../../../assets/MaterialIcons';
import {
  CaseNotesMeetingStatusMapping,
  CaseNotesMeetingTypeMapping,
} from '../../../shared/constants/Provider';
import { ItemContains } from '../../../utilities/Common';
import StyledButton from '../../widgets/styledButton/StyledButton';
import { encodeUserId } from '../provider/detail/providerDetailTabs/meetings/ProviderSessionView';
import {
  CaseNotesFilterFields,
  caseNoteTypeMapping,
} from './utils/types/CaseNotes';

export interface FilterRow {
  type: string;
  value: string;
  match: boolean;
}

export const filterData = (
  data: Record<string, any>[],
  filters: FilterRow[],
) => {
  return data.filter((item) =>
    filters.every((filter) => {
      if (filter.type === 'caseId') {
        if (filter.match) {
          return ItemContains(encodeUserId(item?.userId), filter.value);
        } else {
          return !ItemContains(encodeUserId(item?.userId), filter.value);
        }
      }
      const matchingMapping = caseNoteTypeMapping.find(
        (mapping) => mapping.field === filter.type,
      );

      if (matchingMapping) {
        const caseNoteKey = matchingMapping.caseNoteType;
        const noteValue = item.notes?.[caseNoteKey];

        if (filter.match) {
          return ItemContains(noteValue, filter.value);
        } else {
          return !ItemContains(noteValue, filter.value);
        }
      } else {
        // If no mapping exists, fallback to direct field access
        const itemValue = item[filter.type as keyof typeof item];
        if (itemValue && filter.match) {
          return ItemContains(itemValue, filter.value);
        } else {
          return !ItemContains(itemValue, filter.value);
        }
      }
    }),
  );
};

const CaseNotesFilters = ({
  anchorEl,
  handleClose,
  onFilterSubmit,
  handleResetFilters,
  defaultFilters,
}: {
  defaultFilters: FilterRow[];
  anchorEl: HTMLElement | null;
  handleClose: () => void;
  onFilterSubmit: any;
  handleResetFilters: () => void;
}) => {
  const [filters, setFilters] = useState<FilterRow[]>([
    { type: '', value: '', match: true },
  ]);

  useEffect(() => {
    setFilters(
      defaultFilters?.length
        ? defaultFilters
        : [{ type: '', value: '', match: true }],
    );
  }, [defaultFilters]);

  const handleAddFilter = () => {
    setFilters([...filters, { type: '', value: '', match: true }]);
  };

  const submitFilteredData = () => {
    const validFilters = filters.filter(
      (filter) => filter.type.trim() || filter.value.trim(),
    );
    onFilterSubmit(validFilters);
    handleClose();
  };

  const handleRemoveFilter = (index: number) => {
    setFilters(filters.filter((_, i) => i !== index));
  };

  const handleFilterChange = (
    index: number,
    field: keyof FilterRow,
    value: string | boolean,
  ) => {
    const updatedFilters = [...filters];

    // Ensure that we handle each field type correctly
    if (field === 'match') {
      updatedFilters[index][field] = value as boolean;
    } else {
      updatedFilters[index][field] = value as string;
    }

    setFilters(updatedFilters);
  };
  return (
    <Popover
      anchorEl={anchorEl}
      open={!!anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      sx={{
        borderRadius: '2rem',
        zIndex: '1201 !important',
        padding: '2rem',
        marginRight: '-1rem',
      }}
      slotProps={{
        paper: {
          style: { width: '55rem', padding: '1rem' },
        },
      }}
    >
      <Grid container spacing={2} width={'100%'}>
        <Grid item xs={12}>
          <Typography variant="h5">Filters</Typography>
        </Grid>
        <Grid container padding={4} spacing={2} alignItems="center">
          <Grid item xs={4} display={'flex'} justifyContent={'center'}>
            <InputLabel>Column</InputLabel>
          </Grid>
          <Grid item xs={3} display={'flex'} justifyContent={'center'}>
            <InputLabel>Mode</InputLabel>
          </Grid>
          <Grid item xs={4} display={'flex'} justifyContent={'center'}>
            <InputLabel>Value</InputLabel>
          </Grid>

          {filters.map((filter, index) => (
            <Fragment key={index}>
              <Grid item xs={4}>
                <Autocomplete
                  // key={`${index}`}
                  options={CaseNotesFilterFields}
                  getOptionLabel={(option) => option.label}
                  renderInput={(params) => (
                    <TextField {...params} placeholder="Select Filter" />
                  )}
                  size="small"
                  ChipProps={{ size: 'small' }}
                  onChange={(e, value) => {
                    handleFilterChange(index, 'type', value?.field || '');
                  }}
                  value={
                    CaseNotesFilterFields.find(
                      (field) => field.field === filter.type,
                    ) || null
                  }
                  getOptionDisabled={(option) =>
                    filters.some(
                      (f, i) => f.type === option.field && i !== index,
                    )
                  }
                  isOptionEqualToValue={(option, value) =>
                    option.field === value?.field
                  }
                />
              </Grid>

              {/* Match Dropdown */}
              <Grid item xs={3}>
                <Select
                  size="small"
                  fullWidth
                  value={filter.match ? 'true' : 'false'}
                  onChange={(e: any) =>
                    handleFilterChange(
                      index,
                      'match',
                      e.target.value === 'true',
                    )
                  }
                >
                  <MenuItem value="true">Contains</MenuItem>
                  <MenuItem value="false">Not Contains</MenuItem>
                </Select>
              </Grid>
              <Grid item xs={4}>
                {filter.type === 'status' ? (
                  <TextField
                    size="small"
                    select
                    fullWidth
                    value={filter.value}
                    onChange={(e) =>
                      handleFilterChange(index, 'value', e.target.value)
                    }
                    placeholder="Select status"
                  >
                    {Object.entries(CaseNotesMeetingStatusMapping).map(
                      ([key, label]) => (
                        <MenuItem key={key} value={key}>
                          {label}
                        </MenuItem>
                      ),
                    )}
                  </TextField>
                ) : filter.type === 'type' ? (
                  <TextField
                    size="small"
                    select
                    fullWidth
                    value={filter.value}
                    onChange={(e) =>
                      handleFilterChange(index, 'value', e.target.value)
                    }
                    placeholder="Select meeting type"
                  >
                    {Object.entries(CaseNotesMeetingTypeMapping).map(
                      ([key, label]) => (
                        <MenuItem key={key} value={key}>
                          {label}
                        </MenuItem>
                      ),
                    )}
                  </TextField>
                ) : (
                  <TextField
                    size="small"
                    fullWidth
                    value={filter.value}
                    onChange={(e) =>
                      handleFilterChange(index, 'value', e.target.value)
                    }
                    placeholder="Enter value to filter"
                  />
                )}
              </Grid>
              <Grid item xs={1}>
                <IconButton
                  onClick={() => handleRemoveFilter(index)}
                  disabled={filters.length === 1}
                  color="error"
                >
                  {MaterialIcons.Delete}
                </IconButton>
              </Grid>
            </Fragment>
          ))}
        </Grid>
        <Grid container spacing={2}>
          {/* Row for Add Filter button */}
          <Grid item xs={8} display="flex" justifyContent="flex-start">
            <StyledButton
              variant="ghost"
              size="small"
              margin={0}
              onClick={handleAddFilter}
              startIcon={MaterialIcons.Add}
            >
              Add Filter
            </StyledButton>
          </Grid>

          {/* Row for Filter and Reset buttons */}
          <Grid item xs={4} display="flex" justifyContent="flex-end">
            <StyledButton
              variant="ghost"
              size="small"
              margin={0}
              onClick={() => {
                setFilters([{ type: '', value: '', match: true }]);
                handleResetFilters();
                handleClose();
              }}
              startIcon={MaterialIcons.LinkOff}
              style={{ marginLeft: '10px' }}
            >
              Reset
            </StyledButton>
            <StyledButton
              variant="ghost"
              size="small"
              margin={0}
              onClick={submitFilteredData}
              startIcon={MaterialIcons.Search}
            >
              Filter
            </StyledButton>
          </Grid>
        </Grid>
      </Grid>
    </Popover>
  );
};
export default CaseNotesFilters;
