import { Switch } from 'react-router';
import Config from '../../../config';
import { AuthenticatedRoute } from '../../components/authenticatedRoute/AuthenticatedRoute';
import OnsiteSessionsList from './OnsiteSessionsList';
import OnsiteSessionDetails from './details/OnsiteSessionDetails';
import { OnsiteMeetings } from './reporting/OnsiteMeetings';
import { AclActions, AclModules } from '../../../shared/constants/Ability';

export default function OnsiteSessionsView() {
  return (
    <Switch>
      <AuthenticatedRoute
        path={Config.paths.onsiteSession.details + '/:onSiteId'}
        action={AclActions.Read}
        subject={AclModules.ONSITE_SESSIONS}
        component={OnsiteSessionDetails}
        exact={true}
      />
      <AuthenticatedRoute
        path={Config.paths.onsiteSession.details + '/:onSiteId/meetings'}
        action={AclActions.Read}
        subject={AclModules.ONSITE_SESSIONS}
        component={OnsiteMeetings}
        exact={true}
      />
      <AuthenticatedRoute
        path={Config.paths.onsiteSession.addNew}
        action={AclActions.Create}
        subject={AclModules.ONSITE_SESSIONS}
        component={OnsiteSessionDetails}
      />
      <AuthenticatedRoute
        path={Config.paths.onsiteSession.home}
        action={AclActions.Read}
        subject={AclModules.ONSITE_SESSIONS}
        component={OnsiteSessionsList}
      />
    </Switch>
  );
}
