import {
  Autocomplete,
  Box,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import {
  AclActions,
  AclModuleRows,
  AclModules,
} from '../../../shared/constants/Ability';
import { GenericDialog } from '../../components/genericDialog';
import LabelWithAsterik from '../../components/LabelWithAsterik/LabelWithAsterik';
import StyledButton from '../../widgets/styledButton/StyledButton';
import {
  useCreatePermission,
  useUpdatePermission,
} from './hooks/usePermissions';
import { IPermission } from './utils/types/access-control.interface';
import ToggleSwitchButton from '../../widgets/ToggleSwitchButton/ToggleSwitchButton';

interface IAddPermissionDialogProps {
  open: boolean;
  edit: boolean;
  existingPermission: Partial<IPermission> | null;
  handleClose: () => void;
  handleSuccess: () => void;
  isAdminAccessAdded?: boolean;
}

function AddPermissionDialog({
  open,
  edit,
  handleClose,
  handleSuccess,
  existingPermission,
  isAdminAccessAdded,
}: IAddPermissionDialogProps) {
  const [permissionData, setPermissionData] = useState<Partial<IPermission>>({
    name: '',
    description: '',
  });
  const [adminAccess, setAdminAccess] = useState(false);

  useEffect(() => {
    if (existingPermission) {
      const { name, description, action, subject } = existingPermission;
      setPermissionData({ name, description, action, subject });
      setAdminAccess(
        action === AclActions.Manage && subject === AclModules.ALL
          ? true
          : false,
      );
    }
  }, [existingPermission]);

  useEffect(() => {
    setPermissionData((prev) => ({
      ...prev,
      action: AclActions.Manage,
      subject: AclModules.ALL,
    }));
  }, [adminAccess]);

  const { mutate: addPermission, isLoading: addPermissionLoading } =
    useCreatePermission(handleSuccess);
  const { mutate: updatePermission, isLoading: updatePermissionLoading } =
    useUpdatePermission(handleSuccess);

  const hasError =
    !permissionData?.name ||
    !permissionData?.action ||
    !permissionData?.subject;

  const handleSubmit = () => {
    if (hasError) return;

    if (edit && existingPermission?.id) {
      updatePermission({
        permissionId: existingPermission?.id,
        permissionData,
      });
    } else {
      addPermission(permissionData);
    }
  };

  const ModuleOptions = AclModuleRows?.map((item) => ({
    label: item?.moduleName,
    value: item?.subject,
  }));

  const selectedModuleOption = useMemo(() => {
    return ModuleOptions?.find(
      (item) => item?.value === existingPermission?.subject,
    );
  }, [ModuleOptions, existingPermission]);

  return (
    <GenericDialog
      noFullScreen
      fitToContent
      minWidth="44vw"
      open={open}
      handleClose={handleClose}
      title={`${edit ? 'Edit' : 'Add'} Permission`}
      footerElements={
        <Box display={'flex'} justifyContent={'flex-end'}>
          <StyledButton
            size="small"
            loading={true}
            type="submit"
            margin={0}
            onClick={handleSubmit}
            disabled={
              hasError || addPermissionLoading || updatePermissionLoading
            }
          >
            Submit
          </StyledButton>
        </Box>
      }
    >
      <Grid container spacing={2} p={2}>
        <Grid item xs={4} width={4}>
          <InputLabel>
            <LabelWithAsterik title="Name" />
          </InputLabel>
          <TextField
            size="small"
            placeholder="Name"
            value={permissionData?.name ?? ''}
            onChange={(e) =>
              setPermissionData((prev) => ({ ...prev, name: e.target.value }))
            }
          />
        </Grid>
        <Grid item xs={4} width={4}>
          <InputLabel>Description</InputLabel>
          <TextField
            size="small"
            placeholder="Description"
            value={permissionData?.description ?? ''}
            onChange={(e) =>
              setPermissionData((prev) => ({
                ...prev,
                description: e.target.value,
              }))
            }
          />
        </Grid>
        {!isAdminAccessAdded && (
          <Grid item xs={4} width={4}>
            <InputLabel>Admin (Full) Access ?</InputLabel>
            <ToggleSwitchButton
              width="160px"
              uniqueKey=""
              checked={adminAccess}
              onChange={(v) => {
                setAdminAccess(v);
              }}
            />
          </Grid>
        )}
        {!adminAccess && (
          <>
            <Grid item xs={4} width={4}>
              <InputLabel>
                <LabelWithAsterik title="Action" />
              </InputLabel>
              <Select
                size="small"
                fullWidth
                value={permissionData?.action ?? ''}
                onChange={(e) =>
                  setPermissionData((prev) => ({
                    ...prev,
                    action: e.target.value,
                  }))
                }
              >
                <MenuItem value={AclActions.Read}>Read</MenuItem>
                <MenuItem value={AclActions.Create}>Create</MenuItem>
                <MenuItem value={AclActions.Manage}>Manage</MenuItem>
              </Select>
            </Grid>
            <Grid item xs={4} width={4}>
              <InputLabel>
                <LabelWithAsterik title="Module" />
              </InputLabel>
              <Autocomplete
                size="small"
                options={ModuleOptions}
                renderInput={(params) => <TextField {...params} />}
                value={selectedModuleOption}
                onChange={(e, value) => {
                  setPermissionData((prev) => ({
                    ...prev,
                    subject: value?.value,
                  }));
                }}
                fullWidth
              />
            </Grid>
          </>
        )}
      </Grid>
    </GenericDialog>
  );
}

export default AddPermissionDialog;
