import { Box, InputLabel, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { GenericDialog } from '../../components/genericDialog';
import LabelWithAsterik from '../../components/LabelWithAsterik/LabelWithAsterik';
import StyledButton from '../../widgets/styledButton/StyledButton';
import { useCreateRole, useUpdateRole } from './hooks/useRoles';
import { IAssociatedRole } from './utils/types/access-control.interface';

interface IAddRoleDialogProps {
  open: boolean;
  edit: boolean;
  existingRole: Partial<IAssociatedRole> | null;
  handleClose: () => void;
  handleSuccess: () => void;
}

function AddRoleDialog({
  open,
  edit,
  handleClose,
  handleSuccess,
  existingRole,
}: IAddRoleDialogProps) {
  const [roleName, setRoleName] = useState('');

  const { mutate: addRole, isLoading: addRoleLoading } =
    useCreateRole(handleSuccess);

  const { mutate: updateRole, isLoading: updateRoleLoading } =
    useUpdateRole(handleSuccess);

  const handleSubmit = () => {
    if (!roleName) return;

    if (edit && existingRole?.id) {
      updateRole({ roleId: existingRole?.id, roleData: { name: roleName } });
    } else {
      addRole({ name: roleName });
    }
  };

  useEffect(() => {
    setRoleName(existingRole?.name ?? '');
  }, [existingRole]);

  return (
    <GenericDialog
      noFullScreen
      fitToContent
      minWidth="36vw"
      open={open}
      handleClose={handleClose}
      title={`${edit ? 'Edit' : 'Add'} Role`}
      footerElements={
        <Box display={'flex'} justifyContent={'flex-end'}>
          <StyledButton
            size="small"
            loading={true}
            type="submit"
            margin={0}
            onClick={handleSubmit}
            disabled={!roleName || addRoleLoading || updateRoleLoading}
          >
            Submit
          </StyledButton>
        </Box>
      }
    >
      <Box px={2}>
        <InputLabel>
          <LabelWithAsterik title="Role Name" />
        </InputLabel>
        <TextField
          size="small"
          placeholder="Role Name"
          value={roleName}
          onChange={(e) => setRoleName(e.target.value)}
        />
      </Box>
    </GenericDialog>
  );
}

export default AddRoleDialog;
