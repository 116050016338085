import { Box, Grid, Popover, Typography } from '@mui/material';
import moment from 'moment';
import React, { Fragment, useContext, useState } from 'react';
import toast from 'react-hot-toast';

import { MaterialIcons } from '../../../../../../../assets/MaterialIcons';
import { OrganisationServiceV2 } from '../../../../../../../shared/service/services_v2';
import { CreditPlanRequestPayload } from '../../../../../../../shared/types/organisation/OrganisationDetailItem';
import SectionHeader from '../../../../../../components/SectionHeader/SectionHeader';
import StyledButton from '../../../../../../widgets/styledButton/StyledButton';
import { OrganisationoDetailContext } from '../../OrganisationDetailView';
import CreditEditDialogRevised from './CreditEditDialogRevised';
import CreditRenewDialog from './CreditRenewDialog';
import RenderPlans from './RenderPlans';
import RenderPlansTabular from './RenderPlansTablular';
import { AbilityContext } from '../../../../../../components/Acl/Can';
import {
  AclActions,
  AclModules,
} from '../../../../../../../shared/constants/Ability';

const CreditPoolView = () => {
  const {
    getOrgCredits: refreshOrg,
    orgData,
    orgCredits,
    refetchAuditLogs,
    setRefetchAuditLogs,
  } = useContext(OrganisationoDetailContext);
  const ability = useContext(AbilityContext);
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [popoverAnchor, setPopoverAnchor] = useState<HTMLElement | null>(null);
  const [selectedBreakDown, setSelectedBreakDown] = useState<any>([]);
  const [showCreditPoolRenewDialog, setShowCreditPoolRenewDialog] =
    useState<boolean>(false);

  const [showCreditPoolEditDialog, setShowCreditPoolEditDialog] =
    useState<boolean>(false);
  const [editLoading, setEditLoading] = useState(false);
  const [renewLoading, setRenewLoading] = useState(false);

  const handleCreditRenewSubmit = async (pool: CreditPlanRequestPayload[]) => {
    const data = pool;

    let response = null;

    try {
      setRenewLoading(true);
      response = await OrganisationServiceV2.renewCredits(data, orgData?.id);

      if (response && response.data.success) {
        toast.success(
          response?.data?.data ??
            'Credit pool renewal started, please check after sometime',
        );
      }
    } catch (error) {
      toast.error('An error occurred while renewing the credit plan.');
    } finally {
      setShowCreditPoolRenewDialog(false);
      setRenewLoading(false);
    }
  };

  const handleCreditEditSubmit = async (pool: CreditPlanRequestPayload[]) => {
    const data = pool;

    let response = null;

    try {
      setEditLoading(true);
      response = await OrganisationServiceV2.updateCredits(data, orgData?.id);

      if (response && response.data.success) {
        toast.success('Credit pool updated successfully!');
        refreshOrg();
        setRefetchAuditLogs(!refetchAuditLogs);
      }
    } catch (error) {
      toast.error('An error occurred while editing the plan.');
    } finally {
      setShowCreditPoolEditDialog(false);
      setEditLoading(false);
    }
  };

  const handleOpenPopover = async (event: React.MouseEvent<HTMLElement>) => {
    setPopoverAnchor(event.currentTarget);
    const poolId = event.currentTarget.getAttribute('data-pool-id');
    if (poolId) {
      let response = null;
      response = await OrganisationServiceV2.getPlanHistory(poolId);
      if (response?.data?.data) {
        setSelectedBreakDown(response?.data?.data);
        setPopoverOpen(true);
      }
    }
  };

  const handleClosePopover = () => {
    setPopoverOpen(false);
  };

  const today = moment();
  const currentPlans =
    orgCredits?.filter((plan: any) =>
      moment(plan.expiresAt).isAfter(today, 'day'),
    ) ?? [];

  const expiredPlans =
    orgCredits?.filter(
      (plan: any) => !moment(plan.expiresAt).isAfter(today, 'day'),
    ) ?? [];

  const isRenewDisabled = !currentPlans || currentPlans.length === 0;
  const isEditDisabled = !currentPlans || currentPlans.length === 0;
  const isAddDisabled = currentPlans?.length > 0;

  return (
    <>
      <Box>
        <SectionHeader
          title={<Typography variant="h6">Current Plan</Typography>}
          rightElement={
            <>
              {!isAddDisabled && (
                <StyledButton
                  size="small"
                  variant="ghost"
                  startIcon={MaterialIcons.Add}
                  onClick={() => setShowCreditPoolRenewDialog(true)}
                  margin={0}
                  permission={{
                    action: AclActions.Manage,
                    subject: AclModules.BILLING_PLANS,
                  }}
                >
                  Add plan
                </StyledButton>
              )}
              {!isRenewDisabled && (
                <StyledButton
                  size="small"
                  variant="ghost"
                  startIcon={MaterialIcons.RenewPlan}
                  onClick={() => setShowCreditPoolRenewDialog(true)}
                  margin={0}
                  permission={{
                    action: AclActions.Manage,
                    subject: AclModules.BILLING_PLANS,
                  }}
                >
                  Renew Plan
                </StyledButton>
              )}
              {!isEditDisabled && (
                <StyledButton
                  size="small"
                  variant="ghost"
                  startIcon={MaterialIcons.Edit}
                  onClick={() => setShowCreditPoolEditDialog(true)}
                  margin={0}
                  permission={{
                    action: AclActions.Manage,
                    subject: AclModules.BILLING_PLANS,
                  }}
                >
                  Edit Plan
                </StyledButton>
              )}
            </>
          }
        />
        <Grid container spacing={2} paddingX={1} mt={1}>
          <Grid item xs={12}>
            <Typography variant="h6" marginBottom={2}>
              Employee plan
            </Typography>
            <RenderPlans
              credits={currentPlans.filter(
                (plan: any) => plan.planUse === 'emp',
              )}
              handleOpenPopover={handleOpenPopover}
              key={1}
            />
          </Grid>
          {!!orgCredits.filter((plan: any) => plan.planUse === 'dep')
            .length && (
            <Grid item xs={12}>
              <Typography variant="h6" marginBottom={2}>
                Dependent plan
              </Typography>
              <RenderPlans
                credits={currentPlans.filter(
                  (plan: any) => plan.planUse === 'dep',
                )}
                handleOpenPopover={handleOpenPopover}
                key={2}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <Typography variant="h6" marginBottom={2}>
              Expired plan
            </Typography>
            {expiredPlans.length > 0 ? (
              <>
                {/* <RenderPlans
                  credits={expiredPlans}
                  handleOpenPopover={handleOpenPopover}
                  key={3}
                /> */}
                <RenderPlansTabular
                  credits={expiredPlans}
                  handleOpenPopover={handleOpenPopover}
                  key={4}
                />
              </>
            ) : (
              <Typography>No Past Plans</Typography>
            )}
          </Grid>
        </Grid>
      </Box>

      <Popover
        open={popoverOpen}
        anchorEl={popoverAnchor}
        onClose={handleClosePopover}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        sx={{ borderRadius: '1rem', zIndex: '1201 !important' }}
      >
        {selectedBreakDown && selectedBreakDown.length > 0 ? (
          <Grid container spacing={4} maxWidth="18vw" p={4} alignItems="center">
            {''}
            <Grid item xs={6} textAlign="right">
              <Typography variant="body1" fontWeight="bold">
                Month & Year
              </Typography>
            </Grid>
            <Grid item xs={4} textAlign="center">
              <Typography variant="body1" fontWeight="bold">
                Credits Utilised
              </Typography>
            </Grid>
            {selectedBreakDown?.map((breakdownItem: any, index: number) => (
              <Fragment key={index}>
                <Grid item xs={6} textAlign="left">
                  <Typography variant="body1" fontWeight="bold">
                    {`${breakdownItem.month} ${breakdownItem.year}`}
                  </Typography>
                </Grid>

                <Grid item xs={4} textAlign="center">
                  <Typography variant="body2">{breakdownItem.count}</Typography>
                </Grid>
                {index + 1 < selectedBreakDown.length && (
                  <Grid item xs={2}></Grid>
                )}
              </Fragment>
            ))}
          </Grid>
        ) : (
          <Typography variant="h6">No breakdown data available</Typography>
        )}
      </Popover>
      <CreditRenewDialog
        isAddPlan={false}
        status={orgData?.status ?? ''}
        handleClose={() => setShowCreditPoolRenewDialog(false)}
        open={showCreditPoolRenewDialog}
        handleSubmit={handleCreditRenewSubmit}
        key={`${orgData?.id}-${showCreditPoolRenewDialog}-credit-renew`}
        loading={renewLoading}
      />
      <CreditEditDialogRevised
        currentPlans={currentPlans.filter(
          (plan: any) => plan.planUse === 'emp',
        )}
        handleClose={() => setShowCreditPoolEditDialog(false)}
        open={showCreditPoolEditDialog}
        handleSubmit={handleCreditEditSubmit}
        key={`${orgData?.id}-${showCreditPoolEditDialog}-credit-edit`}
        loading={editLoading}
      />
    </>
  );
};

export default CreditPoolView;
