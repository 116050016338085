import { useMutation, useQuery, UseQueryResult } from '@tanstack/react-query';
import { AccessControlService } from '../../../../shared/service/services_v2';
import { IPermission } from '../utils/types/access-control.interface';
import toast from 'react-hot-toast';
import { AxiosError } from 'axios';

export const useGetPermissions = (): UseQueryResult<IPermission[]> => {
  return useQuery<IPermission[]>({
    queryKey: ['access-control-permissions'],
    queryFn: async () => {
      const response = await AccessControlService.getPermissions();

      return response?.data?.data ?? [];
    },
  });
};

export const useCreatePermission = (onSuccess?: () => void) =>
  useMutation({
    mutationKey: ['acl-create-permission'],
    mutationFn: (body: Partial<IPermission>) =>
      AccessControlService.createPermission(body),
    onSuccess: () => {
      toast.success('Permission created successfully!');
      onSuccess && onSuccess();
    },
    onError: (error: AxiosError) => {
      const errorMsg = error?.response?.data?.message ?? 'Something went wrong';
      console.log('Error:', errorMsg);
      toast.error(errorMsg);
    },
  });

export const useUpdatePermission = (onSuccess?: () => void) =>
  useMutation({
    mutationKey: ['acl-update-permission'],
    mutationFn: (body: {
      permissionId: number;
      permissionData: Partial<IPermission>;
    }) => {
      const { permissionId, permissionData } = body;
      return AccessControlService.updatePermission(
        permissionId,
        permissionData,
      );
    },
    onSuccess: () => {
      toast.success('Permission updated successfully!');
      onSuccess && onSuccess();
    },
    onError: (error: AxiosError) => {
      const errorMsg = error?.response?.data?.message ?? 'Something went wrong';
      console.log('Error:', errorMsg);
      toast.error(errorMsg);
    },
  });
