import {
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { Fragment, useMemo, useState } from 'react';
import { MaterialIcons } from '../../../../../../assets/MaterialIcons';
import { ConfigItem } from '../../../../../../shared/types/organisation/OrganisationDetailItem';
import { GenericDialog } from '../../../../../components/genericDialog';
import StyledButton from '../../../../../widgets/styledButton/StyledButton';
import { ConfigKeys } from './constants';

const DefaultConfigItem: Partial<ConfigItem> = {
  key: '',
  value: '',
  isPublic: false,
};

const AddConfigDialog = ({
  open,
  handleClose,
  handleSubmit,
  existingKeys,
}: {
  open: boolean;
  handleClose: () => void;
  handleSubmit: (configs: Partial<ConfigItem>[]) => void;
  existingKeys?: string[];
}) => {
  const [configs, setConfigs] = useState<Partial<ConfigItem>[]>([
    { ...DefaultConfigItem, isPublic: false },
  ]);

  const handleEditItem = (
    index: number,
    key: string,
    value: string | boolean,
  ) => {
    setConfigs([
      ...configs.slice(0, index),
      { ...configs[index], [key]: value },
      ...configs.slice(index + 1),
    ]);
  };

  const handleDeleteItem = (index: number) => {
    setConfigs(configs.filter((_config, i: number) => i !== index));
  };

  const NewlyAddedKeys = useMemo(() => {
    return configs?.map((item) => item?.key)?.filter(Boolean);
  }, [configs]);

  const FilteredConfigKeys = useMemo(() => {
    return Object.keys(ConfigKeys)?.filter(
      (item) => !existingKeys?.includes(item),
    );
  }, [existingKeys]);

  return (
    <GenericDialog
      open={open}
      handleClose={handleClose}
      noFullScreen
      title="Add a config"
      extraElements={
        <>
          <StyledButton
            size="small"
            variant="ghost"
            startIcon={MaterialIcons.Add}
            onClick={() => setConfigs([...configs, { ...DefaultConfigItem }])}
          >
            Add a config
          </StyledButton>
          <StyledButton
            size="small"
            sx={{ width: '8rem' }}
            onClick={() => handleSubmit(configs)}
          >
            Submit
          </StyledButton>
        </>
      }
    >
      <Grid
        container
        spacing={3}
        padding={3}
        columns={13}
        maxWidth="75vw"
        width="75vw"
        maxHeight="75vh"
        overflow="auto"
      >
        {configs.map((config: Partial<ConfigItem>, index: number) => (
          <Fragment key={config.id}>
            <Grid item xs={4}>
              <InputLabel>Key</InputLabel>
              <Select
                fullWidth
                labelId="select-key"
                id="select-key"
                value={config.key}
                label="Config"
                onChange={(event: any) =>
                  handleEditItem(index, 'key', event.target.value)
                }
                size="small"
              >
                {FilteredConfigKeys.map((k) => (
                  <MenuItem
                    key={k}
                    value={k}
                    disabled={NewlyAddedKeys.includes(k)}
                  >
                    {ConfigKeys[k].title}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item xs={6}>
              <InputLabel>Value</InputLabel>
              <TextField
                fullWidth
                onChange={(
                  event: React.ChangeEvent<
                    HTMLInputElement | HTMLTextAreaElement
                  >,
                ) => handleEditItem(index, 'value', event.target.value)}
                value={config.value}
                size="small"
              />
            </Grid>
            <Grid item xs={1} display={'flex'} alignItems={'center'}>
              <IconButton
                sx={{ marginTop: '24px' }}
                color="error"
                onClick={() => handleDeleteItem(index)}
              >
                {MaterialIcons.Delete}
              </IconButton>
            </Grid>
          </Fragment>
        ))}
      </Grid>
    </GenericDialog>
  );
};

export default AddConfigDialog;
