import { InfoOutlined } from '@mui/icons-material';
import {
  Box,
  Checkbox,
  CircularProgress,
  InputLabel,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  TooltipProps,
  Typography,
} from '@mui/material';
import { Fragment, useEffect, useMemo, useState } from 'react';
import {
  AclActions,
  AclModuleRows,
  AclModules,
  IAclModuleRow,
} from '../../../shared/constants/Ability';
import { GenericDialog } from '../../components/genericDialog';
import StyledButton from '../../widgets/styledButton/StyledButton';
import { useAssignPermissionsToRole, useRoleDetails } from './hooks/useRoles';
import {
  IAssociatedRole,
  IPermission,
} from './utils/types/access-control.interface';

interface IAssignPermissionsToRoleProps {
  open: boolean;
  handleClose: () => void;
  handleSuccess: () => void;
  role: IAssociatedRole | null;
  permissions?: IPermission[];
}

export const InformationIconWithTooltip = ({
  title,
  placement = 'top',
}: {
  title: string;
  placement?: TooltipProps['placement'];
}) => (
  <Tooltip placement={placement} title={title}>
    <InfoOutlined sx={{ ml: 0.5, fontSize: '15px' }} />
  </Tooltip>
);

function AssignPermissionsToRoleDialog({
  open,
  handleClose,
  handleSuccess,
  permissions,
  role,
}: IAssignPermissionsToRoleProps) {
  const [adminAccess, setAdminAccess] = useState<boolean>(false);
  const [selectedPermissions, setSelectedPermissions] = useState<number[]>([]);

  const { data: roleDetails, isLoading } = useRoleDetails(role?.id);
  const { mutate: assignPermissions, isLoading: assignPermissionsLoading } =
    useAssignPermissionsToRole(handleSuccess);

  const isAdminAccessAdded = useMemo(() => {
    return permissions?.find(
      (pr) => pr.action === AclActions.Manage && pr.subject === AclModules.ALL,
    );
  }, [permissions]);

  useEffect(() => {
    const existingRolePermissions =
      roleDetails?.permissions?.map((item) => item?.id) ?? [];

    setSelectedPermissions(existingRolePermissions);
    setAdminAccess(
      isAdminAccessAdded?.id &&
        existingRolePermissions?.includes(isAdminAccessAdded?.id)
        ? true
        : false,
    );
  }, [roleDetails, isAdminAccessAdded]);

  const permissionsModuleMap = useMemo(() => {
    return (
      permissions?.reduce((result: Record<string, number>, permission) => {
        const key = `${permission.action}:${permission.subject}`;

        result[key] = permission?.id;

        return result;
      }, {}) ?? {}
    );
  }, [permissions]);

  const addPermission = (permissionId: number) => {
    setSelectedPermissions((prev) => [...prev, permissionId]);
  };

  const removePermission = (permissionId: number) => {
    setSelectedPermissions((prev) => {
      const index = prev.indexOf(permissionId);

      return [...prev.slice(0, index), ...prev.slice(index + 1)];
    });
  };

  const handleSubmit = () => {
    if (!role?.id) return;

    assignPermissions({
      roleId: role?.id,
      permissionIds:
        adminAccess && isAdminAccessAdded
          ? [isAdminAccessAdded?.id]
          : selectedPermissions,
    });
  };

  const PermissionRow = ({ row }: { row: IAclModuleRow }) => {
    const canReadId = permissionsModuleMap[`read:${row.subject}`];
    const canCreateId = permissionsModuleMap[`create:${row.subject}`];
    const canManageId = permissionsModuleMap[`manage:${row.subject}`];

    return (
      <Fragment>
        <TableRow sx={{ height: 48 }}>
          <TableCell width={460} component="th" scope="row">
            <Typography>{row.moduleName}</Typography>
          </TableCell>
          <TableCell>
            <Box display={'flex'} justifyContent={'center'}>
              {canReadId ? (
                <Checkbox
                  checked={selectedPermissions.includes(canReadId)}
                  onChange={(e) => {
                    e.target.checked
                      ? addPermission(canReadId)
                      : removePermission(canReadId);
                  }}
                />
              ) : (
                '-'
              )}
            </Box>
          </TableCell>
          <TableCell>
            <Box display={'flex'} justifyContent={'center'}>
              {canCreateId ? (
                <Checkbox
                  checked={selectedPermissions.includes(canCreateId)}
                  onChange={(e) => {
                    e.target.checked
                      ? addPermission(canCreateId)
                      : removePermission(canCreateId);
                  }}
                />
              ) : (
                '-'
              )}
            </Box>
          </TableCell>
          <TableCell>
            <Box display={'flex'} justifyContent={'center'}>
              {canManageId ? (
                <Checkbox
                  checked={selectedPermissions.includes(canManageId)}
                  onChange={(e) => {
                    e.target.checked
                      ? addPermission(canManageId)
                      : removePermission(canManageId);
                  }}
                />
              ) : (
                '-'
              )}
            </Box>
          </TableCell>
        </TableRow>
      </Fragment>
    );
  };

  if (isLoading) return <CircularProgress />;

  return (
    <GenericDialog
      noFullScreen
      fitToContent
      minWidth="48vw"
      open={open}
      handleClose={handleClose}
      title={'Manage Role Permissions'}
      footerElements={
        <Box display={'flex'} justifyContent={'flex-end'}>
          <StyledButton
            size="small"
            margin={0}
            onClick={handleSubmit}
            disabled={assignPermissionsLoading || !selectedPermissions?.length}
          >
            Submit
          </StyledButton>
        </Box>
      }
    >
      <Box px={2} maxHeight={'64vh'} overflow={'auto'}>
        <Box>
          <InputLabel>Row</InputLabel>
          <Typography fontWeight={'bold'} fontSize={'18px'}>
            {roleDetails?.name}
          </Typography>
        </Box>
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow sx={{ height: 48 }}>
                <TableCell width={460} component="th" scope="row"></TableCell>
                <TableCell width={150}>
                  <Box
                    sx={{
                      display: 'flex',
                      fontSize: '1rem',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    Read
                    <InformationIconWithTooltip title="User with this permission, will be able to read/view selected module." />
                  </Box>
                </TableCell>
                <TableCell width={150}>
                  <Box
                    sx={{
                      display: 'flex',
                      fontSize: '1rem',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    Create
                    <InformationIconWithTooltip title="User with this permission, will be able to create/update selected module." />
                  </Box>
                </TableCell>
                <TableCell width={150}>
                  <Box
                    sx={{
                      display: 'flex',
                      fontSize: '1rem',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    Manage
                    <InformationIconWithTooltip title="User with this permission, will be able to do all the read/view, create/update, delete selected module." />
                  </Box>
                </TableCell>
              </TableRow>
              <TableRow sx={{ height: 48 }}>
                <TableCell>
                  <Box
                    sx={{
                      display: 'flex',
                      fontSize: '1rem',
                      alignItems: 'center',
                      textTransform: 'capitalize',
                    }}
                  >
                    Administrator Access
                    <Tooltip
                      placement="top"
                      title="Allows a full access to the system"
                    >
                      <InfoOutlined sx={{ ml: 0.5, fontSize: '15px' }} />
                    </Tooltip>
                  </Box>
                </TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell>
                  <Box display={'flex'} justifyContent={'center'}>
                    {isAdminAccessAdded ? (
                      <Checkbox
                        checked={adminAccess}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>,
                        ) => {
                          const checked = event.target.checked;
                          setAdminAccess(checked);

                          if (!checked) {
                            removePermission(isAdminAccessAdded?.id);
                          } else {
                            addPermission(isAdminAccessAdded?.id);
                          }
                        }}
                      />
                    ) : (
                      '-'
                    )}
                  </Box>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody
              sx={{
                display: adminAccess ? 'none' : 'visible',
              }}
            >
              {AclModuleRows?.map((row: IAclModuleRow) => (
                <PermissionRow key={row.moduleName} row={row} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </GenericDialog>
  );
}

export default AssignPermissionsToRoleDialog;
