import React, { useContext } from 'react';
import { Button, Typography, SxProps, Theme, ButtonProps } from '@mui/material';
import { ArrowLeftIcon, ArrowRightIcon } from '@primer/octicons-react';
import { AclActions, AclModules } from '../../../shared/constants/Ability';
import { AbilityContext } from '../../components/Acl/Can';

export const StyledButtonColors = {
  light: '#8c90a6',
  gray: '#707484',
  darkGray: '#3E445B',
  red: '#ec7979',
  green: '#5ebb50',
};

interface StyledButtonProps {
  variant?: 'filled' | 'ghost';
  color?: keyof typeof StyledButtonColors;
  padding?: string | number;
  margin?: string | number;
  children: React.ReactNode;
  sx?: SxProps<Theme>;
  disabled?: boolean;
  startIcon?: React.ReactNode;
  permission?: { action: AclActions; subject: AclModules };
  [x: string]: unknown;
}

const StyledButton = ({
  sx = [],
  padding = '0.5rem 1rem',
  margin = '1rem',
  children,
  ghostHoverColor = '#F2F2F2',
  variant = 'filled',
  color = 'darkGray',
  permission,
  ...otherProps
}: Omit<ButtonProps, 'variant' | 'color'> & StyledButtonProps) => {
  const ability = useContext(AbilityContext);

  const isDisabled =
    (permission && !ability.can(permission.action, permission.subject)) ||
    otherProps?.disabled;

  return (
    <Button
      sx={[
        {
          borderRadius: '0.5rem',
          backgroundColor:
            variant === 'filled' ? StyledButtonColors[color] : 'transparent',
          color: variant === 'filled' ? '#FFFFFF' : StyledButtonColors[color],
          padding,
          margin,
          textTransform: 'initial',
          transition: 'all 0.2s linear',
          fontWeight: 700,
          minWidth: '1rem',
          '&:hover': {
            backgroundColor:
              variant === 'filled'
                ? StyledButtonColors[color]
                : ghostHoverColor,
            filter: variant === 'filled' ? 'brightness(85%)' : ghostHoverColor,
          },
          '&.Mui-disabled': {
            backgroundColor: '#FFFFFF',
            color: 'rgba(0, 0, 0, 0.40)',
            border: variant === 'filled' ? '1px solid #d9d9d9' : 'none',
          },
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      {...otherProps}
      disabled={isDisabled}
    >
      {children}
    </Button>
  );
};

export default StyledButton;

export const ArrowButton = ({
  direction = 'right',
  children,
  sx = [],
  ...otherProps
}: {
  direction?: 'left' | 'right';
  children: React.ReactNode;
  sx?: SxProps<Theme>;
  [x: string]: unknown;
}) => (
  <StyledButton
    sx={[
      {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      },
      ...(Array.isArray(sx) ? sx : [sx]),
    ]}
    {...otherProps}
  >
    {direction === 'left' && <ArrowLeftIcon size={24} />}

    <Typography fontWeight="bold" fontSize={16}>
      {children}
    </Typography>

    {direction === 'right' && <ArrowRightIcon size={24} />}
  </StyledButton>
);
