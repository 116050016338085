import {
  Box,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  Link,
  Popover,
  Tooltip,
  Typography,
} from '@mui/material';
import { CalendarIcon, XIcon } from '@primer/octicons-react';
import * as React from 'react';
import { MaterialIcons } from '../../../../../../assets/MaterialIcons';
import {
  STATUS_TEXT,
  STATUS_TEXT_CHIP_COLORS,
} from '../../../../../../shared/constants/Provider';
import StyledButton from '../../../../../widgets/styledButton/StyledButton';
import { useGetMeetingDetails } from '../../../hooks/useGetMeetingDetails';
import CancelMeetingDialog from './CancelMeetingDialog';
import RescheduleMeetingDialog from './RescheduleMeetingDialog';
import moment from 'moment/moment';
import Config from '../../../../../../config';

export const MeetingInfoPopup = ({
  anchorEl,
  meetingId,
  handleClose,
  refreshData,
}: {
  anchorEl: HTMLDivElement | null;
  meetingId: string;
  handleClose: () => void;
  refreshData: () => void;
}) => {
  const [showCancelMeetingDialog, setShowCancelMeetingDialog] =
    React.useState<boolean>(false);

  const [showRescheduleMeetingDialog, setShowRescheduleMeetingDialog] =
    React.useState<boolean>(false);

  const { data, isLoading } = useGetMeetingDetails(meetingId);

  const meeting = React.useMemo(() => {
    return data?.data?.data?.meeting;
  }, [data]);

  return (
    <>
      <Popover
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        sx={{ borderRadius: '1rem', zIndex: '1201 !important' }}
      >
        <Grid
          container
          spacing={2}
          p={3}
          alignItems="center"
          width={'400px'}
          height={'500px'}
        >
          {isLoading ? (
            <Grid item xs={12}>
              <CircularProgress />
            </Grid>
          ) : meeting ? (
            <>
              <Grid
                item
                xs={12}
                display={'flex'}
                alignItems={'center'}
                justifyContent={'space-between'}
              >
                <Typography variant="h6">Meeting Details</Typography>
                <IconButton onClick={handleClose}>
                  {MaterialIcons.Close}
                </IconButton>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={4} textAlign="right">
                <Typography variant="subtitle1">ID</Typography>
              </Grid>
              <Grid item xs={8} textAlign="left">
                <Link
                  target="blank"
                  href={`/meeting/details/${meeting.id}`}
                  color={'secondary'}
                  underline="hover"
                >
                  <Tooltip title="Click to see meeting details">
                    <Typography color="secondary.main" fontWeight="900">
                      {meeting.id}
                    </Typography>
                  </Tooltip>
                </Link>
              </Grid>
              <Grid item xs={4} textAlign="right">
                <Typography variant="subtitle1">Provider</Typography>
              </Grid>
              <Grid item xs={8} textAlign="left">
                <Link
                  target="blank"
                  href={`${Config.paths.provider.list}/${meeting?.providerId}#5`}
                  color={'secondary'}
                  underline="hover"
                >
                  <Tooltip title="Click to see provider details">
                    <Typography color="secondary.main" fontWeight="900">
                      {meeting?.provider?.profile?.name ?? 'N/A'}
                    </Typography>
                  </Tooltip>
                </Link>
                {/* <Typography variant="h6"> */}
              </Grid>
              <Grid item xs={4} textAlign="right">
                <Typography variant="subtitle1">Participants</Typography>
              </Grid>
              <Grid item xs={8} textAlign="left">
                <Typography variant="h6">
                  {meeting?.participants
                    ?.map((p: any) => p.friendlyName)
                    ?.join(', ') ?? ''}
                </Typography>
              </Grid>
              <Grid item xs={4} textAlign="right">
                <Typography variant="subtitle1">Date</Typography>
              </Grid>
              <Grid item xs={8} textAlign="left">
                <Typography variant="h6">
                  {meeting?.displayDate ?? 'N/A'}
                </Typography>
              </Grid>
              <Grid item xs={4} textAlign="right">
                <Typography variant="subtitle1">Time</Typography>
              </Grid>
              <Grid item xs={8} textAlign="left">
                <Typography variant="h6">
                  {moment(meeting?.displayTime, 'HH:mmA')
                    .add(
                      moment.tz(meeting?.meetingTimeZone).utcOffset(),
                      'minute',
                    )
                    .format('hh:mm A')}
                  -
                  {moment(meeting?.displayTimeEnd, 'HH:mmA')
                    .add(
                      moment.tz(meeting?.meetingTimeZone).utcOffset(),
                      'minute',
                    )
                    .format('hh:mm A')}
                </Typography>
              </Grid>
              <Grid item xs={4} textAlign="right">
                <Typography variant="subtitle1">Status</Typography>
              </Grid>
              <Grid item xs={8} textAlign="left">
                <Typography
                  style={{
                    backgroundColor: STATUS_TEXT_CHIP_COLORS(meeting?.status)
                      .bgColor,
                    color: STATUS_TEXT_CHIP_COLORS(meeting?.status).color,
                    alignItems: 'center',
                    paddingLeft: 5,
                    paddingRight: 5,
                    borderRadius: 5,
                    height: 25,
                  }}
                  textTransform="initial"
                  className="txt-overflow "
                >
                  {STATUS_TEXT[meeting?.status]}
                </Typography>
              </Grid>
              <Grid item xs={6} display={'flex'} justifyContent={'center'}>
                <StyledButton
                  variant="ghost"
                  size="small"
                  startIcon={<CalendarIcon size={18} />}
                  onClick={() => setShowRescheduleMeetingDialog(true)}
                  disabled={meeting?.status !== '0'}
                  margin={0}
                >
                  Reschedule
                </StyledButton>
              </Grid>
              <Grid item xs={6} display={'flex'} justifyContent={'center'}>
                <StyledButton
                  size="small"
                  variant="ghost"
                  color="red"
                  startIcon={<XIcon size={18} />}
                  onClick={() => setShowCancelMeetingDialog(true)}
                  disabled={meeting?.status !== '0'}
                  margin={0}
                >
                  Cancel
                </StyledButton>
              </Grid>
            </>
          ) : (
            <Typography variant="subtitle1">
              Meeting data unavailable.
            </Typography>
          )}{' '}
        </Grid>
      </Popover>
      {meeting?.status === '0' ? (
        <>
          <CancelMeetingDialog
            open={showCancelMeetingDialog}
            handleClose={() => setShowCancelMeetingDialog(false)}
            meetingId={meeting?.id}
            userId={String(meeting?.participants?.[0]?.userId)}
            refreshData={refreshData}
          />
          <RescheduleMeetingDialog
            meeting={meeting}
            open={showRescheduleMeetingDialog}
            handleClose={() => setShowRescheduleMeetingDialog(false)}
            timezone={meeting?.provider?.timezone}
            closeMeetingInfo={handleClose}
            refreshData={refreshData}
          />
        </>
      ) : null}
    </>
  );
};
