import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { IPermission } from '../../access-control/utils/types/access-control.interface';
import { AccessControlService } from '../../../../shared/service/services_v2';

export const useUserOverriddenPermissions = (
  userId?: string,
): UseQueryResult<IPermission[]> => {
  return useQuery<IPermission[]>({
    queryKey: ['user-management-user-overridden-permissions', userId],
    queryFn: async () => {
      if (!userId) return [];

      const response = await AccessControlService.getUserOverriddenPermissions(
        userId,
      );

      return response?.data?.data?.userRolePermissions ?? [];
    },
    enabled: Boolean(userId),
  });
};
