import { Box, Divider, Grid, Typography } from '@mui/material';

import { useContext, useEffect, useState } from 'react';

import toast from 'react-hot-toast';
import ToggleSwitchButton from '../../../../widgets/ToggleSwitchButton/ToggleSwitchButton';
import OrganisationContractDetails from '../OrganisationContractDetails';
import OrganisationConfigurations from './OragnisationConfigurations';
import { OrganisationoDetailContext } from './OrganisationDetailView';
import OrganisationInformation from './OrganisationInformation';
import { useUpdateOrganisation } from './hooks/useUpdateOrganisation';
import AccessCodesView from './organisationBilling/accessCodes/AccessCodesView';
import CreditView from './organisationBilling/creditsV2/CreditView';
import AuditLogs from '../../../../components/AuditLogs/AuditLogs';
import { ApiConfigV2 } from '../../../../../shared/apiConfigV2';
import SectionCard from '../../../../components/SectionCard/SectionCard';

const OrganisationOverview = () => {
  const {
    updatedData,
    orgData,
    getOrgData,
    refetchAuditLogs,
    setRefetchAuditLogs,
  } = useContext(OrganisationoDetailContext);

  const { mutate: updateOrgData, isLoading } = useUpdateOrganisation(() => {
    getOrgData();
    setRefetchAuditLogs(!refetchAuditLogs);
    toast.success('Organisation data updated successfully!');
  });

  const [dependentAccess, setDependentAccess] = useState(
    orgData?.dependentEnabled,
  );
  const [holisticContent, setHolisticContent] = useState(
    orgData?.holisticsEnabled,
  );

  useEffect(() => {
    setDependentAccess(orgData?.dependentEnabled);
    setHolisticContent(orgData?.holisticsEnabled);
  }, [orgData?.dependentEnabled, orgData?.holisticsEnabled]);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <SectionCard sx={{ margin: 0 }}>
            <OrganisationConfigurations />
            <Divider sx={{ my: 2 }} />
            <OrganisationInformation />
          </SectionCard>
        </Grid>
        {/* <Grid item xs={0.5}>
          <Divider orientation="vertical" sx={{ mx: 2 }} />
        </Grid> */}
        <Grid item xs={9} width={9}>
          <Grid container spacing={2}>
            <Grid item xs={12} width={12}>
              <SectionCard sx={{ margin: 0 }}>
                <OrganisationContractDetails />
                <Box
                  display={'flex'}
                  justifyContent={'space-between'}
                  gap={2}
                  mt={2}
                >
                  <Box
                    flex={1}
                    display={'flex'}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                  >
                    <Typography
                      fontSize={'16px'}
                      margin={0}
                      className="txt-overflow"
                    >
                      Dependent Access
                    </Typography>

                    <ToggleSwitchButton
                      width="160px"
                      checked={!!dependentAccess}
                      onChange={(v) => {
                        if (orgData) {
                          updateOrgData({
                            orgId: orgData?.id,
                            updatedData: {
                              ...updatedData,
                              dependentEnabled: v,
                            },
                            isStatusUpdated:
                              updatedData.status !== orgData.status,
                          });
                        }
                      }}
                      uniqueKey={'1-dependent'}
                      disabled={isLoading}
                    />
                  </Box>

                  <Box
                    flex={1}
                    display={'flex'}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                  >
                    <Typography
                      fontSize={'16px'}
                      margin={0}
                      className="txt-overflow"
                    >
                      Holistic Content
                    </Typography>

                    <ToggleSwitchButton
                      width="160px"
                      checked={!!holisticContent}
                      uniqueKey={'2-holistic'}
                      disabled={isLoading}
                      onChange={(v) => {
                        if (orgData) {
                          updateOrgData({
                            orgId: orgData?.id,
                            updatedData: {
                              ...updatedData,
                              holisticsEnabled: v,
                            },
                            isStatusUpdated:
                              updatedData.status !== orgData.status,
                          });
                        }
                      }}
                    />
                  </Box>
                </Box>
              </SectionCard>
            </Grid>

            <Grid item xs={12} sx={{}}>
              <SectionCard
                sx={{
                  margin: 0,
                  width: '100%',
                  gap: 4,
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <AccessCodesView />
                <CreditView />
              </SectionCard>
            </Grid>
            {orgData?.id && (
              <Grid item xs={12} width={12}>
                <SectionCard sx={{ margin: 0 }}>
                  <AuditLogs
                    refId={orgData?.id?.toString()}
                    customUrl={
                      ApiConfigV2.api.auditlog.getOrganisationAuditLogs
                    }
                    refetch={refetchAuditLogs}
                  />
                </SectionCard>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default OrganisationOverview;
