import { ClearOutlined } from '@mui/icons-material';
import {
  Autocomplete,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  TextField,
} from '@mui/material';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import { useHistory } from 'react-router-dom';
import { MaterialIcons } from '../../../assets/MaterialIcons';
import { UserManagementService } from '../../../shared/service/services_v2';
import useDebounce from '../../../utilities/hooks/useDebounce';
import {
  convertQueryStringToArray,
  encodeQueryStrings,
  useQueryString,
} from '../../../utilities/hooks/useQueryString';
import SectionHeader from '../../components/SectionHeader/SectionHeader';
import TableViewComponent from '../../components/TableViewComponent/TableViewComponent';
import StyledButton from '../../widgets/styledButton/StyledButton';
import AddUserDialog from './AddUserDialog';
import { UserGridColumns } from './utils/grid-columns/UserGrirdColumns';
import { IUser } from './utils/types/user-management.interface';
import { RootViewContext } from '../RootView';
import { IAssociatedRole } from '../access-control/utils/types/access-control.interface';

const UsersList = () => {
  const { setAppBreadCrumbs } = useContext(RootViewContext);
  const queryString = useQueryString({
    arrayFormat: 'separator',
    arrayFormatSeparator: ',',
  });

  const [pageSize, setPageSize] = useState<number>(
    queryString?.pageSize ? +queryString?.pageSize : 10,
  );
  const history = useHistory();
  const [currentPage, setCurrentPage] = useState<number>(
    queryString?.currentPage ? +queryString?.currentPage : 0,
  );

  const [users, setUsers] = useState<IUser[]>([]);
  const [totalUsers, setTotalUsers] = useState(0);
  const [roles, setRoles] = useState<IAssociatedRole[]>([]);
  const [loading, setLoading] = useState(false);
  const [openUserDialog, setOpenUserDialog] = useState<{
    open: boolean;
    edit: boolean;
    user: IUser | null;
  }>({ open: false, edit: false, user: null });

  const [filterInputs, setFilterInputs] = useState<{
    name: string;
    email: string;
    roles: number[];
  }>({
    name: (queryString?.name || '') as string,
    email: (queryString?.email || '') as string,
    roles: (queryString?.languages
      ? convertQueryStringToArray(queryString?.languages)?.map((item) => +item)
      : []) as number[],
  });

  const debouncedFilter = useDebounce(filterInputs, 500);

  useEffect(() => {
    setAppBreadCrumbs([]);
  }, []);

  useEffect(() => {
    const params: Record<string, any> = {
      ...queryString,
      pageSize,
      currentPage,
      name: debouncedFilter?.name,
      email: debouncedFilter?.email,
      roles: debouncedFilter?.roles,
    };

    history.replace({ search: encodeQueryStrings(params) });
  }, [pageSize, currentPage, debouncedFilter]);

  const handleAddUser = useCallback(() => {
    setOpenUserDialog({ open: true, edit: false, user: null });
  }, []);

  const getUsers = useCallback(
    async (
      pageSize: number,
      currentPage: number,
      filterInputs: Record<string, any>,
    ) => {
      try {
        setLoading(true);
        const response = await UserManagementService.getAllUsers({
          page: currentPage + 1,
          take: pageSize,
          ...filterInputs,
        });

        if (response && response.data.success) {
          setUsers(response?.data?.data?.items ?? []);
          if (response?.data?.data?.total < totalUsers) {
            setCurrentPage(0);
          }

          setTotalUsers(response?.data?.data?.total ?? 0);
        }
      } catch (error) {
        toast.error('An error occured while fetching users');
      } finally {
        setLoading(false);
      }
    },
    [],
  );

  const getRoles = useCallback(async () => {
    try {
      const response = await UserManagementService.getRoles();

      if (response && response.data.success) {
        setRoles(response?.data?.data ?? []);
      }
    } catch (error) {
      toast.error('An error occured while fetching roles');
    }
  }, []);

  useEffect(() => {
    getRoles();
  }, [getRoles]);

  useEffect(() => {
    getUsers(pageSize, currentPage, debouncedFilter);
  }, [getUsers, debouncedFilter, pageSize, currentPage]);

  const selectedRoles = useMemo(() => {
    return roles?.filter((item) => filterInputs?.roles?.includes(item?.id));
  }, [roles, filterInputs?.roles]);

  const handleDialogClose = useCallback(() => {
    setOpenUserDialog({ open: false, edit: false, user: null });
  }, []);

  const handleDialogSuccess = () => {
    handleDialogClose();
    getUsers(pageSize, currentPage, debouncedFilter);
  };

  return (
    <>
      <SectionHeader
        title={'Users'}
        subTitle={'Manage users (staff users only)'}
        rightElement={
          <StyledButton
            variant="ghost"
            size="small"
            onClick={handleAddUser}
            startIcon={MaterialIcons.Add}
            margin={0}
          >
            Add User
          </StyledButton>
        }
        showDivider
      />

      <Grid container spacing={2} mb={2}>
        <Grid item xs={4}>
          <InputLabel>Name</InputLabel>
          <TextField
            size="small"
            onChange={(event) =>
              setFilterInputs((prev) => ({
                ...prev,
                name: event.target.value,
              }))
            }
            value={filterInputs.name}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    sx={{
                      visibility: filterInputs.name ? 'visible' : 'hidden',
                    }}
                    onClick={() =>
                      setFilterInputs((prev) => ({
                        ...prev,
                        name: '',
                      }))
                    }
                  >
                    <ClearOutlined fontSize="small" />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            fullWidth
          />
        </Grid>
        <Grid item xs={4}>
          <InputLabel>Email</InputLabel>
          <TextField
            size="small"
            onChange={(event) =>
              setFilterInputs((prev) => ({
                ...prev,
                email: event.target.value,
              }))
            }
            value={filterInputs.email}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    sx={{
                      visibility: filterInputs.email ? 'visible' : 'hidden',
                    }}
                    onClick={() =>
                      setFilterInputs((prev) => ({
                        ...prev,
                        email: '',
                      }))
                    }
                  >
                    <ClearOutlined fontSize="small" />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            fullWidth
          />
        </Grid>
        <Grid item xs={4}>
          <InputLabel>Roles</InputLabel>
          <Autocomplete
            multiple
            disablePortal
            limitTags={1}
            options={roles}
            getOptionLabel={(option) => option?.name}
            renderInput={(params) => <TextField {...params} />}
            size="small"
            ChipProps={{ size: 'small' }}
            value={selectedRoles}
            onChange={(e, values) => {
              setFilterInputs((prev) => ({
                ...prev,
                roles: values?.map((item) => item?.id),
              }));
            }}
            key={1}
          />
        </Grid>
      </Grid>

      <TableViewComponent
        rows={users}
        columns={UserGridColumns(setOpenUserDialog)}
        loading={loading}
        pageSizeCustom={pageSize}
        currentPage={currentPage}
        onPageSizeChange={(pageSize) => {
          setPageSize(pageSize);
        }}
        onPageChange={(page) => {
          setCurrentPage(page);
        }}
        paginationMode="server"
        total={totalUsers}
      />

      <AddUserDialog
        open={openUserDialog.open}
        edit={openUserDialog.edit}
        existingUser={openUserDialog?.user}
        roles={roles}
        handleClose={handleDialogClose}
        handleSuccess={handleDialogSuccess}
      />
    </>
  );
};

export default UsersList;
