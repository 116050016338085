import { Box, Divider, Typography } from '@mui/material';
import React from 'react';

interface ISectionHeaderProps {
  title: string | JSX.Element;
  titleChip?: JSX.Element;
  subTitle?: string | JSX.Element;
  leftIcon?: JSX.Element;
  rightElement?: JSX.Element;
  titleStyles?: React.CSSProperties;
  subTitleStyles?: React.CSSProperties;
  sx?: React.CSSProperties;
  showDivider?: boolean;
}

/** Common Section Header Component */
const SectionHeader = ({
  title,
  titleChip,
  subTitle,
  leftIcon,
  rightElement,
  titleStyles,
  subTitleStyles,
  sx,
  showDivider = false,
}: ISectionHeaderProps) => {
  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        flexWrap={'wrap'}
        gap={'10px'}
        sx={{
          ...sx,
        }}
      >
        <Box
          id="sectionHeaderLeftElement"
          display="flex"
          gap="10px"
          alignItems="center"
          maxWidth={'100%'}
        >
          {leftIcon}
          <Box maxWidth={'100%'}>
            <Box display="flex" gap="8px" alignItems="center" maxWidth={'100%'}>
              <Typography
                color="textPrimary"
                variant="h5"
                fontWeight={600}
                sx={{ ...titleStyles }}
              >
                {title}
              </Typography>
              {titleChip}
            </Box>
            <Typography
              fontSize={'14px'}
              color={'gray'}
              sx={{ ...subTitleStyles }}
            >
              {subTitle}
            </Typography>
          </Box>
        </Box>
        <Box
          id="sectionHeaderRightElement"
          display="flex"
          alignItems="center"
          flexDirection="row-reverse"
          gap={1}
        >
          {rightElement}
        </Box>
      </Box>
      {showDivider && <Divider sx={{ my: 2 }} />}
    </>
  );
};

export default SectionHeader;
