import {
  Box,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Tab,
  Tabs,
  TextField,
} from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Config from '../../../../../config';

import StyledButton from '../../../../widgets/styledButton/StyledButton';
// import AddOrganisationDialog from './AddOrganisationDialog';
import { ClearOutlined } from '@mui/icons-material';
import { HistoryIcon } from '@primer/octicons-react';
import moment from 'moment';
import { MaterialIcons } from '../../../../../assets/MaterialIcons';
import { ApiConfigV2 } from '../../../../../shared/apiConfigV2';
import {
  OrganisationStatusInList,
  PilotMaxDays,
  TrialMaxDays,
} from '../../../../../shared/constants/Organisation';
import { OrganisationDetailItem } from '../../../../../shared/types/organisation/OrganisationDetailItem';
import { getUserCookie } from '../../../../../utilities/Api';
import useDebounce from '../../../../../utilities/hooks/useDebounce';
import {
  encodeQueryStrings,
  useQueryString,
} from '../../../../../utilities/hooks/useQueryString';
import { GetRecentlyVisitedOrganisations } from '../../../../../utilities/Organisation';
import SectionHeader from '../../../../components/SectionHeader/SectionHeader';
import TableViewComponent from '../../../../components/TableViewComponent/TableViewComponent';
import { RootViewContext } from '../../../RootView';
import { ClientsGridColumnsV3 } from '../../utils/grid-columns/ClientsGridColumnsV3';
import AddOrganisationDialogBase from './addOrganisationDialogV2/AddOrganisationDialogBase';
import { useOrganisationList } from './hooks/useOrganisationList';
import {
  AclActions,
  AclModules,
} from '../../../../../shared/constants/Ability';

const DefaultPageSize = 50;

export interface IOrganisationListFilterTypes {
  name: string;
  expiresIn: string;
}

type OrganisationTabsType =
  | keyof typeof OrganisationStatusInList
  | 'recently-visited';

const OrganisationList = () => {
  const queryString = useQueryString();
  const { setAppBreadCrumbs } = useContext(RootViewContext);

  const history = useHistory();

  const recentlyVisitedOrgIds =
    GetRecentlyVisitedOrganisations()?.map((item) => item?.orgId) ?? [];

  const defaultOrganisationTabValue = recentlyVisitedOrgIds?.length
    ? 'recently-visited'
    : '0';

  const initialOrganisationTabValue =
    queryString?.status === 'recently-visited' && !recentlyVisitedOrgIds?.length
      ? '0'
      : (queryString?.status as OrganisationTabsType) ??
        defaultOrganisationTabValue;

  const [status, setStatus] = useState<OrganisationTabsType>(
    initialOrganisationTabValue,
  );
  const [pageSize, setPageSize] = useState<number>(
    queryString?.pageSize ? +queryString?.pageSize : DefaultPageSize,
  );
  const [currentPage, setCurrentPage] = useState<number>(
    queryString?.currentPage ? +queryString?.currentPage : 0,
  );

  const [total, setTotal] = useState<number>(1);
  const [filterInputs, setFilterInputs] =
    useState<IOrganisationListFilterTypes>({
      name: queryString?.name?.toString() ?? '',
      expiresIn: queryString?.expiresIn?.toString() ?? '',
    });
  const debouncedFilter = useDebounce(filterInputs, 500);

  const [showAddOrganisationDialog, setShowAddOrganisationDialog] =
    useState<boolean>(false);

  const onOrgListSuccess = (newTotal: number) => {
    if (newTotal < total) {
      setCurrentPage(0);
    }
    if (
      status === 'recently-visited' &&
      (debouncedFilter?.name || debouncedFilter?.expiresIn)
    ) {
      setStatus('0');
    }
    setTotal(newTotal);
  };

  const {
    data: orgListData,
    isLoading,
    refetch,
  } = useOrganisationList(
    currentPage + 1,
    pageSize,
    debouncedFilter,
    status !== 'recently-visited' ? status : undefined,
    status === 'recently-visited' &&
      !debouncedFilter?.name &&
      !debouncedFilter?.expiresIn
      ? recentlyVisitedOrgIds
      : [],
    onOrgListSuccess,
  );

  useEffect(() => {
    const params: Record<string, any> = {
      ...queryString,
      pageSize,
      currentPage,
      status,
      name: debouncedFilter?.name,
      expiresIn: debouncedFilter?.expiresIn,
    };

    history.replace({ search: encodeQueryStrings(params) });
  }, [pageSize, currentPage, status, debouncedFilter, queryString, history]);

  // Note: Had to disable it as we are persisting status as well
  // useEffect(() => {
  //   debouncedFilter && setStatus('0');
  // }, [debouncedFilter]);

  const onAnalyticsClick = (id: number) => {
    const url = `${
      ApiConfigV2.base.insightsDashboard
    }/admin?organisationId=${id}&token=${getUserCookie()}`;
    window.open(url, '_blank');
  };

  useEffect(() => {
    setAppBreadCrumbs([
      {
        label: 'Commercial',
        path: Config.paths.marketing.home,
        type: 'link',
      },
      {
        label: 'Clients',
        type: 'text',
      },
    ]);
  }, []);

  return (
    <>
      <SectionHeader
        title={'Clients'}
        rightElement={
          <StyledButton
            variant="ghost"
            size="small"
            onClick={() => setShowAddOrganisationDialog(true)}
            startIcon={MaterialIcons.Add}
            margin={0}
            permission={{
              action: AclActions.Create,
              subject: AclModules.ORGANISATION,
            }}
          >
            Add a client
          </StyledButton>
        }
        showDivider
      />

      <Box>
        <Grid container spacing={2} mb={2}>
          <Grid item xs={4}>
            <InputLabel>Name</InputLabel>
            <TextField
              size="small"
              onChange={(event) =>
                setFilterInputs((prev) => ({
                  ...prev,
                  name: event.target.value,
                }))
              }
              value={filterInputs.name}
              placeholder="Search by name"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      sx={{
                        visibility: filterInputs.name ? 'visible' : 'hidden',
                      }}
                      onClick={() =>
                        setFilterInputs((prev) => ({ ...prev, name: '' }))
                      }
                    >
                      <ClearOutlined fontSize="small" />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              fullWidth
            />
          </Grid>
          <Grid item xs={2}>
            <InputLabel>Expires In</InputLabel>
            <Select
              size="small"
              fullWidth
              value={filterInputs.expiresIn}
              onChange={(e) =>
                setFilterInputs((prev) => ({
                  ...prev,
                  expiresIn: e.target.value,
                }))
              }
            >
              <MenuItem value={''}>None</MenuItem>
              <MenuItem value={'30'}>30 Days</MenuItem>
              <MenuItem value={'90'}>90 Days</MenuItem>
            </Select>
          </Grid>
        </Grid>

        <Tabs
          value={status}
          onChange={(event, value) => setStatus(value as OrganisationTabsType)}
          aria-label="organisation status tabs"
          sx={{ marginBottom: 2 }}
        >
          {Object.keys(OrganisationStatusInList)
            .sort(
              (a, b) =>
                OrganisationStatusInList[a as OrganisationDetailItem['status']]
                  .order -
                OrganisationStatusInList[b as OrganisationDetailItem['status']]
                  .order,
            )
            .map((key: string) => (
              <Tab
                label={
                  OrganisationStatusInList[
                    key as OrganisationDetailItem['status']
                  ].title
                }
                key={'orgstatus-' + key}
                value={key}
              />
            ))}
          <Tab
            label={
              <Box display={'flex'} alignItems={'center'} gap={1}>
                <HistoryIcon size={12} /> Recently Visited
              </Box>
            }
            key={'recently-visited'}
            value={'recently-visited'}
            disabled={!recentlyVisitedOrgIds?.length}
          />
        </Tabs>

        <TableViewComponent
          rows={orgListData?.items ?? []}
          columns={ClientsGridColumnsV3({ onAnalyticsClick })}
          loading={isLoading}
          total={total}
          pageSizeCustom={pageSize}
          currentPage={currentPage}
          onPageSizeChange={(pageSize) => {
            setPageSize(pageSize);
          }}
          onPageChange={(page) => {
            setCurrentPage(page);
          }}
          getRowClassName={(params) => {
            const diffDays = Math.abs(
              moment(params?.row?.createdAt).diff(moment(), 'days'),
            );
            const status: keyof typeof OrganisationStatusInList =
              params.row?.status ?? 0;

            const pilotDaysExceeded =
              OrganisationStatusInList[status].title === 'Pilot' &&
              diffDays > PilotMaxDays;
            const trialDaysExceeded =
              OrganisationStatusInList[status].title === 'Trial' &&
              diffDays > TrialMaxDays;

            return pilotDaysExceeded || trialDaysExceeded
              ? 'highlist-tableview-row'
              : '';
          }}
          paginationMode="server"
          // onRowClick={(params) =>
          //   history.push(`${Config.paths.organisationV3}/${params.row.id}`)
          // }
        />
      </Box>

      <AddOrganisationDialogBase
        getOrgsList={refetch}
        open={showAddOrganisationDialog}
        handleClose={() => setShowAddOrganisationDialog(false)}
      />
    </>
  );
};

export default OrganisationList;
