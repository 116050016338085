import { useQuery } from '@tanstack/react-query';
import { OrganisationServiceV2 } from '../../../../../../shared/service/services_v2';

export const useGetOrgMembers = (
  orgId: number,
  pageSize: number,
  currentPage: number,
  id: string,
  userId: string,
  emailAddress: string,
  organisationTeamId: string,
  organisationTeamName: string,
  countryName: string,
  status: string,
  role: string,
  employeeCode: string,
) =>
  useQuery({
    enabled: Boolean(orgId),
    queryKey: [
      'org-members',
      pageSize,
      currentPage,
      orgId,
      id,
      emailAddress,
      userId,
      organisationTeamId,
      organisationTeamName,
      countryName,
      role,
      status,
    ],
    queryFn: () => {
      if (orgId) {
        return OrganisationServiceV2.getMembersByOrg(orgId, {
          take: pageSize,
          page: currentPage,
          ...(id ? { id: +id } : {}),
          ...(userId ? { userId: +userId } : {}),
          emailAddress,
          organisationTeamId,
          organisationTeamName,
          countryName,
          role,
          status,
        });
      }
    },
    staleTime: 2 * 60 * 1000, // Data is fresh for 2 minutes
    cacheTime: 5 * 60 * 1000, // Data is kept in cache for 5 minutes
    select: (data) => data?.data?.data,
    meta: {
      errorMessage:
        'An error occurred while attempting to get org members count',
    },
  });
