import { Box, IconButton, Tooltip } from '@mui/material';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { Dispatch, SetStateAction } from 'react';
import { MaterialIcons } from '../../../../../assets/MaterialIcons';
import { getDisplayDateTime } from '../../../../../utilities/Display';
import { TooltipTypography } from '../../../../components/TooltipTypography/TooltipTypography';
import { IPermission } from '../types/access-control.interface';
import * as _ from 'lodash';

export const PermissionGridColumns: (
  setOpenPermissionDialog: Dispatch<
    SetStateAction<{
      open: boolean;
      edit: boolean;
      permission: IPermission | null;
    }>
  >,
  AclModulesMap: Record<string, string>,
) => GridColDef[] = (setOpenPermissionDialog, AclModulesMap) => [
  {
    flex: 0.5,
    minWidth: 80,
    field: 'id',
    headerName: 'ID',
    renderCell: (params: GridRenderCellParams) => {
      return <TooltipTypography title={params.row?.id} />;
    },
  },
  {
    flex: 1,
    minWidth: 120,
    field: 'name',
    headerName: 'Name',
    renderCell: (params: GridRenderCellParams) => {
      return <TooltipTypography title={params?.row?.name ?? '-'} />;
    },
  },
  {
    flex: 1,
    minWidth: 120,
    field: 'description',
    headerName: 'Description',
    renderCell: (params: GridRenderCellParams) => {
      return <TooltipTypography title={params?.row?.description ?? '-'} />;
    },
  },
  {
    flex: 1,
    minWidth: 120,
    field: 'action',
    headerName: 'Action',
    renderCell: (params: GridRenderCellParams) => {
      return (
        <TooltipTypography title={_.capitalize(params?.row?.action ?? '-')} />
      );
    },
  },
  {
    flex: 1,
    minWidth: 120,
    field: 'subject',
    headerName: 'Module',
    renderCell: (params: GridRenderCellParams) => {
      return (
        <TooltipTypography
          title={AclModulesMap?.[params?.row?.subject] ?? '-'}
        />
      );
    },
  },
  {
    flex: 1,
    minWidth: 120,
    sortable: false,
    field: 'updatedAt',
    headerName: 'Last Updated On',
    renderCell: (params: GridRenderCellParams) => {
      return (
        <TooltipTypography
          title={
            params?.row?.updatedAt
              ? getDisplayDateTime(params?.row?.updatedAt)
              : '-'
          }
        />
      );
    },
  },
  {
    flex: 0.5,
    minWidth: 40,
    field: 'actions',
    sortable: false,
    headerName: 'Actions',
    renderCell: (params: GridRenderCellParams) => {
      const roleName = params?.row?.name ?? 'Role';
      return (
        <Box>
          <Tooltip title={`Edit ${roleName}`}>
            <IconButton
              onClick={() =>
                setOpenPermissionDialog({
                  open: true,
                  edit: true,
                  permission: params?.row,
                })
              }
            >
              {MaterialIcons.Edit}
            </IconButton>
          </Tooltip>
        </Box>
      );
    },
  },
];
