export enum EnvType {
  Localhost = 'localhost',
  Staging = 'staging',
  Production = 'production',
  ProductionJp = 'productionjp',
}
export enum StorageItems {
  USER_INFO = 'userInfo',
  RECENTLY_VISITED_ORGS = 'recentlyVisitedOrgs',
}

export const ImagePrefix = 'https://d3imeoj536zhqj.cloudfront.net/images/';

export const ProductionHostname = 'admin.internal.intellect.co';
export const ProductionJPHostname = 'adminjp.internal.intellect.co';

export const AUDIT_LOG_HEADER_KEY = 'x-intellect-admin-audit-event';

export const EMAIL_REGEX = new RegExp(
  // eslint-disable-next-line no-control-regex
  /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/,
);
