import {
  AbilityBuilder,
  createMongoAbility,
  MongoAbility,
} from '@casl/ability';
import { AclModules, AclActions } from '../shared/constants/Ability';

export type AppAbility = MongoAbility<[AclActions, AclModules]>;

export default function defineAbilityFor(
  permissions: { action: AclActions; subject: AclModules }[],
) {
  const { can, build } = new AbilityBuilder<AppAbility>(createMongoAbility);
  permissions.forEach(({ action, subject }) => {
    can(action, subject);
  });

  return build();
}
