import {
  Autocomplete,
  Grid,
  InputLabel,
  Paper,
  Slide,
  TextField,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { OrganisationTeamItem } from '../../../../../../shared/types/organisation/OrganisationTeamItem';
import { GenericDialog } from '../../../../../components/genericDialog';
import StyledButton from '../../../../../widgets/styledButton/StyledButton';
import { useGetOrganisations } from '../../../../../../utilities/hooks/useGetOrganisations';
import { OrganisationListItem } from '../../../../../../shared/types/organisation/OrganisationListItem';
import { useGetOrgConfigs } from '../hooks/useGetOrgConfigs';
import { OrganisationServiceV2 } from '../../../../../../shared/service/services_v2';
import toast from 'react-hot-toast';
import { ConfigItem } from '../../../../../../shared/types/organisation/OrganisationDetailItem';
import { ConfigKeys } from '../configEditor/constants';

const AddSuprtAdminTeamDialog = ({
  open,
  handleClose,
  teamId,
  orgId,
  refreshTeams,
}: {
  open: boolean;
  teamId: number;
  handleClose: () => void;
  refreshTeams: () => void;
  orgId: number;
}) => {
  const { data: organisations } = useGetOrganisations();
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [selectedOrganisations, setSelectedOrganisations] = useState<
    OrganisationListItem[]
  >([]);
  const [loading, setLoading] = useState(false);
  const { data: orgConfigList, refetch: refetchOrgConfigs } =
    useGetOrgConfigs(orgId);
  const [existingConfig, setExistingConfig] = useState<ConfigItem>();
  const [existingSuperAdminTeams, setExistingSuperAdminTeams] = useState<
    number[]
  >([]);
  const [confirmLoading, setConfirmLoading] = useState(false);

  useEffect(() => {
    if (orgConfigList) {
      const hrViewOrgConfig = orgConfigList.find(
        (config) => config.key === ConfigKeys.HR_VIEW_ORGANISATIONS.title,
      );

      if (hrViewOrgConfig) {
        const orgMapping = JSON.parse(hrViewOrgConfig.value);
        setExistingConfig(hrViewOrgConfig);
        if (orgMapping[teamId.toString()]) {
          setExistingSuperAdminTeams(orgMapping[teamId.toString()]);
        }
      }
    }
  }, [orgConfigList, teamId]);

  useEffect(() => {
    if (existingConfig && organisations && existingSuperAdminTeams.length) {
      const defaultSelectedOrgs = organisations.filter((org) =>
        existingSuperAdminTeams.includes(org.id),
      );
      setSelectedOrganisations(defaultSelectedOrgs);
    }
  }, [existingConfig, organisations, existingSuperAdminTeams, teamId]);

  const handleAddSuperAdminTeams = async () => {
    try {
      if (!teamId) return;
      let response;
      setLoading(true);
      const selectedOrgIds = selectedOrganisations.map((org) => org.id);
      selectedOrgIds.push(orgId); // pushing current Org Id to maintain atleast 2 orgIds present in HR_VIEW_ORGS
      const uniqOrgIds = selectedOrgIds.filter(
        (item, index) => selectedOrgIds.indexOf(item) === index,
      );
      if (existingConfig) {
        const valueObj = JSON.parse(existingConfig.value);
        valueObj[teamId] = uniqOrgIds;
        existingConfig.value = JSON.stringify(valueObj);
        response = await OrganisationServiceV2.updateConfig(existingConfig);
      } else {
        const addConfigObj: Partial<ConfigItem> = {
          key: ConfigKeys.HR_VIEW_ORGANISATIONS.title,
          value: JSON.stringify({
            [teamId]: selectedOrgIds, // Using teamId dynamically as the key
          }),
          isPublic: false,
        };
        response = await OrganisationServiceV2.addOrgConfigs(
          [addConfigObj],
          orgId,
        );
      }
      if (response && response.data.success) {
        toast.success('Super Admin Team added successfully!');
        await refetchOrgConfigs(); // Refetch updated orgConfigList
      }
    } catch (error: any) {
      toast.error(
        error?.response?.data?.message ||
          'An error occurred while attempting to update the super admin team.',
      );
    } finally {
      setLoading(false);
      setShowConfirmation(false);
      refreshTeams();
      handleClose();
    }
  };

  return (
    <GenericDialog
      open={open}
      handleClose={handleClose}
      noFullScreen
      fitToContent
      title={'Make Super Admin Team'}
    >
      {!showConfirmation && (
        <>
          <Grid
            container
            spacing={2}
            justifyContent="center"
            alignItems="center"
            style={{ minHeight: '150px' }}
          >
            <Grid item xs={4}>
              <InputLabel>Organisations</InputLabel>
              <Autocomplete
                multiple
                limitTags={2}
                options={(organisations ?? []) as OrganisationListItem[]}
                getOptionLabel={(option) => option?.name}
                renderInput={(params) => <TextField {...params} />}
                size="small"
                ChipProps={{ size: 'small' }}
                onChange={(e, values) => {
                  setSelectedOrganisations(values);
                }}
                value={selectedOrganisations}
                key={3}
              />
            </Grid>
          </Grid>
          <Grid container justifyContent="center">
            <StyledButton
              sx={{ width: '8rem' }}
              size="small"
              onClick={() => setShowConfirmation(true)}
              disabled={loading}
            >
              Add
            </StyledButton>
          </Grid>
        </>
      )}
      <Slide direction="up" in={showConfirmation} mountOnEnter unmountOnExit>
        <Paper variant="elevation" elevation={0} sx={{ margin: 0, padding: 0 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} width={12}>
              <Typography variant="h6">
                Are you sure you want to make
                <b>
                  <i>
                    {selectedOrganisations.map((org, index) => (
                      <span key={org.id}>
                        {org.name}
                        {index < selectedOrganisations.length - 1 && ', '}
                      </span>
                    ))}
                  </i>
                </b>{' '}
                as super Admin Team?
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              width={12}
              display={'flex'}
              justifyContent={'flex-end'}
            >
              <StyledButton
                variant="ghost"
                size="large"
                onClick={() => setShowConfirmation(false)}
                color="red"
                padding="0.5rem"
                sx={{ width: '7.5rem' }}
                autoFocus
              >
                {'Cancel'}
              </StyledButton>
              <StyledButton
                size="large"
                padding="0.5rem"
                sx={{ width: '7.5rem' }}
                disabled={confirmLoading || !selectedOrganisations}
                onClick={handleAddSuperAdminTeams}
              >
                {'Yes'}
              </StyledButton>
            </Grid>
          </Grid>
        </Paper>
      </Slide>
    </GenericDialog>
  );
};

export default AddSuprtAdminTeamDialog;
