import { styled } from '@mui/system';

// #DDDEE3 dark shade
// #E3E4E8 (slightly lighter)
// #E8E9ED (even lighter)
// #F0F1F4 (much lighter)
const SectionCard = styled('div')({
  padding: '1rem 1.25rem',
  borderRadius: '0.5rem',
  margin: '1rem 0',
  border: '1px solid #E8E9ED',
});

export default SectionCard;
