import { Grid, InputLabel, TextField } from '@mui/material';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';
import { useState } from 'react';

import { CreditPoolTypes } from '../../../../../../../shared/constants/Organisation';
import { GenericDialog } from '../../../../../../components/genericDialog';
import StyledButton from '../../../../../../widgets/styledButton/StyledButton';
import { CreditInputRowRevised } from './CreditInputRowRevised';

const CreditRenewDialog = ({
  handleSubmit,
  open,
  handleClose,
  status,
  isAddPlan,
  orgCreate,
  loading,
}: {
  handleSubmit: any;
  open: boolean;
  handleClose: () => void;
  status: string;
  isAddPlan: boolean;
  orgCreate?: boolean;
  loading?: boolean;
}) => {
  const [accordionStates, setAccordionStates] = useState<any[]>([]);
  const [expiryDate, setExpiryDate] = useState<Date | undefined>(new Date());

  const updateAccordionState = (index: number, newState: any) => {
    setAccordionStates((prevStates) => {
      const newStates = [...prevStates];
      newStates[index] = newState;
      return newStates;
    });
  };

  const addAccordionState = (type: string) => {
    const current = accordionStates.find((cur) => cur.type === type);
    setAccordionStates([
      ...accordionStates.filter((cur) => cur.type !== type),
      {
        type: type,
        included: current ? !current?.included : true,
        planUse: 'emp',
        credits: null,
        creditPerUser: null,
        notes: '',
      },
    ]);
  };
  let maxDate: any;
  if (status === '1') {
    maxDate = moment().add(1, 'year').toDate();
  } else if (status === '5') {
    maxDate = moment().add(2, 'months').toDate();
  } else if (status === '3') {
    maxDate = moment().add(1, 'month').toDate();
  }

  const handleAddClick = () => {
    if (!isAddPlan) {
      const input = prompt(
        'Renew will reset all associated users credits as per new credit plan. Rollback is not possible. If you are sure, please type confirm and press OK',
      );
      if (input !== 'confirm') {
        return;
      }
    }
    const selectedPlansArray = accordionStates
      .filter((state) => state && state.included)
      .map((state) => ({
        credits: state.credits || null,
        creditPerUser: state.creditPerUser || null,
        type: state.type,
        expiresAt: expiryDate || new Date(),
        planUse: state.planUse,
        notes: state?.notes || '',
      }));
    handleSubmit(selectedPlansArray);
    handleClose();
  };

  return (
    <GenericDialog
      open={open}
      handleClose={handleClose}
      noFullScreen
      title="Add Plan"
      extraElements={
        <StyledButton
          disabled={loading}
          sx={{ width: '8rem' }}
          size="small"
          margin={0}
          onClick={handleAddClick}
        >
          Save
        </StyledButton>
      }
    >
      <Grid container spacing={2} mb={2}>
        <Grid item xs={4} width={4}>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <InputLabel id="date-picker-inline">Valid till</InputLabel>
            <DesktopDatePicker
              showToolbar={false}
              inputFormat="YYYY/MM/DD"
              mask="____/__/__"
              value={expiryDate}
              onChange={(date) => {
                let formattedDate;
                if (date && moment(date).isAfter(moment(), 'day')) {
                  formattedDate = new Date(date);
                  setExpiryDate(formattedDate);
                }
              }}
              OpenPickerButtonProps={{
                'aria-label': 'change date',
              }}
              renderInput={(props) => (
                <TextField
                  id="date-picker-inline"
                  {...props}
                  fullWidth
                  size="small"
                  error={!expiryDate}
                  helperText={!expiryDate ? 'Invalid date' : ''}
                />
              )}
              maxDate={maxDate}
              minDate={moment().set('d', moment().get('d') + 1)}
            />
          </LocalizationProvider>
        </Grid>
      </Grid>
      <Grid container={true} spacing={2}>
        {CreditPoolTypes.map((plan: any, index) => {
          return (
            <CreditInputRowRevised
              key={index}
              plan={plan}
              accordionStates={accordionStates}
              updateAccordionState={updateAccordionState}
              addAccordionState={addAccordionState}
            />
          );
        })}
      </Grid>
    </GenericDialog>
  );
};

export default CreditRenewDialog;
