import {
  Autocomplete,
  Grid,
  InputLabel,
  Paper,
  Slide,
  TextField,
  Typography,
} from '@mui/material';
import { useContext, useEffect, useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import { OrganisationServiceV2 } from '../../../../../../shared/service/services_v2';
import { OrganisationTeamItem } from '../../../../../../shared/types/organisation/OrganisationTeamItem';
import { GenericDialog } from '../../../../../components/genericDialog';
import StyledButton from '../../../../../widgets/styledButton/StyledButton';
import { useGetOrganisations } from '../../../../../../utilities/hooks/useGetOrganisations';
import { OrganisationoDetailContext } from '../OrganisationDetailView';
import { useGetOrgTeams } from '../hooks/useGetOrgTeams';

// Recurse function to get teams & their child teams
export const getAllTheTeams = (
  teams: OrganisationTeamItem[],
  result: Partial<OrganisationTeamItem>[],
) => {
  if (!teams.length) return;

  teams.forEach((item) => {
    const { childTeams, ...rest } = item;
    result.push(rest);
    if (childTeams && childTeams.length) {
      getAllTheTeams(childTeams, result);
    }
  });
};

const LinkToTeamDialog = ({
  open,
  handleClose,
  refreshTeams,
  orgId,
  currentTeamId,
  teamName,
}: {
  open: boolean;
  handleClose: () => void;
  refreshTeams: () => void;
  currentTeamId?: number;
  orgId: number;
  teamName: string;
}) => {
  const { data: teams } = useGetOrgTeams(orgId);

  const [showConfirmation, setShowConfirmation] = useState(false);
  const [teamsData, setTeamsData] = useState<OrganisationTeamItem[]>([]);
  const [selectedNewTeam, setSelectedNewTeam] =
    useState<OrganisationTeamItem | null>(null);
  const [confirmLoading, setConfirmLoading] = useState(false);

  useEffect(() => {
    if (teams?.length) {
      const tempTeams: OrganisationTeamItem[] = [];
      getAllTheTeams(teams, tempTeams);
      setTeamsData(tempTeams);
    }
  }, [teams]);

  const currentTeam = useMemo(() => {
    return teams?.find((item) => item?.id === currentTeamId);
  }, [teams, currentTeamId]);

  const onClose = () => {
    setShowConfirmation(false);
    setSelectedNewTeam(null);
    handleClose();
  };

  const handleTeamUpdate = async () => {
    try {
      if (!selectedNewTeam || !currentTeamId) return;

      setConfirmLoading(true);

      const response = await OrganisationServiceV2.linkToTeam({
        sourceTeamId: currentTeamId,
        destinationTeamId: selectedNewTeam.id,
      });

      if (response && response.data.success) {
        toast.success('Members successfully linked to the new team!');
        refreshTeams();
        onClose();
      }
    } catch (error: any) {
      toast.error(
        error?.response?.data?.message ||
          'An error occurred while trying to link the selected members. Please try again.',
      );
    } finally {
      setConfirmLoading(false);
      refreshTeams();
    }
  };

  return (
    <GenericDialog
      noFullScreen
      handleClose={onClose}
      open={open}
      title="Link Members to Another Team"
      extraElements={
        <>
          {!showConfirmation ? (
            <StyledButton
              sx={{ width: '8rem' }}
              size="large"
              onClick={() => setShowConfirmation(true)}
              disabled={!selectedNewTeam}
            >
              Save
            </StyledButton>
          ) : (
            <></>
          )}
        </>
      }
    >
      <>
        {!showConfirmation ? (
          <Grid container spacing={4}>
            <Grid item xs={6} width={6}>
              <InputLabel>Current Team</InputLabel>
              <TextField value={teamName} disabled size="small" fullWidth />
            </Grid>
            <Grid item xs={6} width={6}>
              <InputLabel>New Team</InputLabel>
              <Autocomplete
                fullWidth={true}
                value={selectedNewTeam}
                options={teamsData?.filter(
                  (item) => item?.id !== currentTeamId,
                )}
                getOptionLabel={(option) => option?.name}
                onChange={(event, newValue) => {
                  setSelectedNewTeam(newValue);
                }}
                size="small"
                renderOption={(props, option) => (
                  <li {...props} key={option.id}>
                    {option.name}
                  </li>
                )}
                renderInput={(params) => <TextField {...params} />}
              />
            </Grid>
          </Grid>
        ) : (
          <></>
        )}

        <Slide direction="up" in={showConfirmation} mountOnEnter unmountOnExit>
          <Paper
            variant="elevation"
            elevation={0}
            sx={{ margin: 0, padding: 0 }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} width={12}>
                <Typography variant="h6">
                  Are you sure you want to link
                  <b>
                    <i>{teamName}</i>
                  </b>{' '}
                  to{' '}
                  <b>
                    <i>{selectedNewTeam?.name}</i>
                  </b>
                  ?
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                width={12}
                display={'flex'}
                justifyContent={'flex-end'}
              >
                <StyledButton
                  variant="ghost"
                  size="large"
                  onClick={() => setShowConfirmation(false)}
                  color="red"
                  padding="0.5rem"
                  sx={{ width: '7.5rem' }}
                  autoFocus
                >
                  {'Cancel'}
                </StyledButton>
                <StyledButton
                  size="large"
                  padding="0.5rem"
                  sx={{ width: '7.5rem' }}
                  disabled={confirmLoading || !selectedNewTeam}
                  onClick={handleTeamUpdate}
                >
                  {'Yes'}
                </StyledButton>
              </Grid>
            </Grid>
          </Paper>
        </Slide>
      </>
    </GenericDialog>
  );
};

export default LinkToTeamDialog;
