import { Tab, Tabs } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import SectionHeader from '../../components/SectionHeader/SectionHeader';

import { RootViewContext } from '../RootView';
import RolesList from './RolesList';
import PermissionsList from './PermissionsList';

const AccessControlHome = () => {
  const { setAppBreadCrumbs } = useContext(RootViewContext);

  const [selectedTab, setSelectedTab] = useState(0);

  useEffect(() => {
    setAppBreadCrumbs([
      { label: 'Access Control' },
      { label: selectedTab === 0 ? 'Roles' : 'Permissions' },
    ]);
  }, [selectedTab]);

  return (
    <>
      <SectionHeader
        title={'Access Control'}
        subTitle={'Manage roles & permissions'}
        showDivider
      />

      <Tabs
        value={selectedTab}
        onChange={(event, value) => setSelectedTab(value)}
        sx={{ marginBottom: 2 }}
      >
        <Tab label="Roles" value={0} />
        <Tab label="Permissions" value={1} />
      </Tabs>

      {selectedTab === 0 && <RolesList />}
      {selectedTab === 1 && <PermissionsList />}
    </>
  );
};

export default AccessControlHome;
