import { Divider, Grid, Typography } from '@mui/material';
import { useFormik } from 'formik';
import moment from 'moment';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import { useHistory, useParams } from 'react-router-dom';
import { MaterialIcons } from '../../../../assets/MaterialIcons';
import Config from '../../../../config';
import {
  OnSiteStatus,
  OnSiteStatusLabelColors,
  sanitizeOnsiteDetailsObject,
} from '../../../../shared/constants/OnsiteDeployment';
import { OnSiteDeploymentService } from '../../../../shared/service/services_v2';
import {
  generate15MinTimeIntervals,
  slotTo15MinTime,
} from '../../../../utilities/Date';
import { ISlotTime } from '../../../../utilities/interfaces/Date';
import SectionHeader from '../../../components/SectionHeader/SectionHeader';
import StatusChip from '../../../components/StatusChip/StatusChip';
import ConfirmDialog from '../../../components/confirmDialog/ConfirmDialog';
import { LoadingView } from '../../../components/loadingView';
import StyledButton from '../../../widgets/styledButton/StyledButton';
import { RootViewContext } from '../../RootView';
import { addOnsiteDeploymentFormValidationSchema } from '../utils/schemas/ValidateAddOnsiteForm.schema';
import OnsiteBasicConfiguration from './OnsiteBasicConfiguration';
import OnsiteCardUploadDialog from './OnsiteCardUploadDialog';
import OnsiteEventConfiguration from './OnsiteEventConfiguration';
import OnsiteProviderConfiguration from './OnsiteProviderConfiguration';

export const OnsiteTimeIntervals: ISlotTime[] = [
  ...generate15MinTimeIntervals({ is12HrFormat: true }),
];

export interface ISubSessionDetails {
  id?: number;
  from: string;
  to: string;
  room: string;
  roomGuide: string;
  topics: string;
  overview: string;
  redirectUrl: string;
}

export interface IEventsDetails {
  id?: number;
  eventType: string;
  sessions: ISubSessionDetails[];
}

export interface IAddOnsiteDeploymentInputs {
  id?: number;
  organisationId: number | undefined;
  locationId: number | undefined;
  onSiteType: string | null;
  fromDate: string | null;
  fromTime: string;
  toDate: string | null;
  cardImageUrl: string;
  toTime: string;
  events: IEventsDetails[];
  providerId: string;
  providerOverview: string;
  status?: string;
}

const OnsiteSessionDetails = () => {
  const { setAppBreadCrumbs } = useContext(RootViewContext);
  const { onSiteId }: { onSiteId: string } = useParams();
  const history = useHistory();
  const [showImageUploadDialog, setShowImageUploadDialog] =
    useState<boolean>(false);

  const [submitLoading, setSubmitLoading] = useState(false);
  const [dataLoading, setDataLoading] = useState(false);
  const [confirmStatusChange, setConfirmStatusChange] = useState<{
    open: boolean;
    msg: string;
    status: OnSiteStatus | null;
  }>({ open: false, msg: '', status: null });

  useEffect(() => {
    setAppBreadCrumbs([
      {
        label: 'Onsite Deployments',
        type: 'link',
        path: Config.paths.onsiteSession.home,
      },
    ]);
  }, []);

  const formik = useFormik<IAddOnsiteDeploymentInputs>({
    enableReinitialize: true,
    initialValues: {
      organisationId: undefined,
      locationId: undefined,
      onSiteType: null,
      cardImageUrl: '',
      fromDate: '',
      fromTime: '',
      toDate: '',
      toTime: '',
      events: [],
      providerId: '',
      providerOverview: '',
    },
    validationSchema: addOnsiteDeploymentFormValidationSchema,
    onSubmit: async () => {
      handleAddOnsiteDeployment();
    },
  });

  useEffect(() => {
    if (onSiteId) {
      getOnsiteDeploymentDetails();
    }
    setAppBreadCrumbs([
      { label: 'Deployments', type: 'link', path: '/onsite-sessions' },
      {
        label: onSiteId,
        type: 'link',
        path: '/onsite-sessions/details/' + onSiteId,
      },
    ]);
  }, [onSiteId]);

  const handleAddOnsiteDeployment = async () => {
    try {
      setSubmitLoading(true);

      let response = null;

      const payload = {
        ...formik?.values,
        fromDateTime: moment(
          `${formik?.values?.fromDate} ${slotTo15MinTime(
            +formik?.values?.fromTime,
          )}`,
          'YYYY-MM-DD hh:mm',
        ).toISOString(),
        toDateTime: moment(
          `${formik?.values?.toDate} ${slotTo15MinTime(
            +formik?.values?.toTime,
          )}`,
          'YYYY-MM-DD hh:mm',
        ).toISOString(),
      };

      if (onSiteId) {
        response = await OnSiteDeploymentService.updateOnSiteDeployment(
          onSiteId,
          payload,
        );
      } else {
        response = await OnSiteDeploymentService.createOnSiteDeployment(
          payload,
        );
      }

      if (response && response?.data?.success) {
        toast.success(
          `Onsite Deployment ${onSiteId ? 'updated' : 'added'} successfully!`,
        );
        if (onSiteId) {
          getOnsiteDeploymentDetails();
        } else if (response?.data?.data?.id) {
          history.push(
            `${Config.paths.onsiteSession.details}/${response?.data?.data?.id}`,
          );
        }
      }
    } catch (err) {
      toast.error('Error occurred while creating onsite.');
    } finally {
      setSubmitLoading(false);
    }
  };

  const getOnsiteDeploymentDetails = useCallback(async () => {
    try {
      setDataLoading(true);
      const response = await OnSiteDeploymentService.getOnSiteDeploymentDetails(
        onSiteId,
      );
      if (response && response?.data?.success) {
        const payload = sanitizeOnsiteDetailsObject(
          response?.data?.data ?? {},
          [
            'createdAt',
            'updatedAt',
            'eventId',
            'onSiteId',
            'provider',
            'location',
          ],
        );

        formik?.setValues(payload as IAddOnsiteDeploymentInputs, true);
      }
    } catch (error) {
      toast.error('Error occurred while loading onsite details.');
    } finally {
      setDataLoading(false);
    }
  }, [onSiteId, formik]);

  /**
   * Handler to pick status chip data
   */
  const onSiteStatus = useMemo(() => {
    return OnSiteStatusLabelColors?.[
      (formik?.values?.status ??
        OnSiteStatus.DRAFT) as keyof typeof OnSiteStatusLabelColors
    ];
  }, [formik?.values?.status]);

  /**
   * Condition to show/hide cancel button
   */
  const renderCancelBtn = useMemo(() => {
    return formik?.values?.id && formik?.values?.status === OnSiteStatus.LIVE;
  }, [formik?.values?.id, formik?.values?.status]);

  /**
   * Condition to show/hide go live button
   */
  const renderLiveBtn = useMemo(() => {
    return (
      formik?.values?.id &&
      formik?.values?.status !== OnSiteStatus.DONE &&
      formik?.values?.status !== OnSiteStatus.CANCELLED
    );
  }, [formik?.values?.id, formik?.values?.status]);

  /**
   * Condition to show/hide go save button
   */
  const renderSaveBtn = useMemo(() => {
    return (
      (onSiteId &&
        formik?.values?.id &&
        formik?.values?.status === OnSiteStatus.DRAFT) ||
      !onSiteId
    );
  }, [formik?.values?.id, formik?.values?.status]);

  /**
   * Condition to show/hide go save button
   */
  const renderDoneBtn = useMemo(() => {
    const isCurrentDatePastEndDate = moment()
      .utc()
      .isSameOrAfter(
        moment(
          `${formik?.values?.toDate} ${slotTo15MinTime(
            +formik?.values?.toTime,
          )}`,
          'YYYY-MM-DD hh:mm',
        ),
      );

    return (
      onSiteId &&
      formik?.values?.id &&
      formik?.values?.status === OnSiteStatus.LIVE &&
      isCurrentDatePastEndDate
    );
  }, [formik?.values?.id, formik?.values?.status]);

  const isEditDisabled = useMemo(() => {
    return Boolean(
      formik?.values?.id && formik?.values?.status !== OnSiteStatus.DRAFT,
    );
  }, [formik?.values?.id, formik?.values?.status]);

  /**
   * Handler to prepare warning msg and dialog
   * @param status
   */
  const handleStatusUpdateConfirmation = useCallback(
    (status: OnSiteStatus) => {
      let msg = '';
      switch (status) {
        case OnSiteStatus.LIVE:
          msg =
            'Are you sure you want to move the deployment to a live state? Doing so will make the card go live on microsite for users to view.';
          break;
        case OnSiteStatus.DRAFT:
          msg =
            'Are you sure you want to move the deployment to a draft state? Doing so will remove the card from the microsite, making it unavailable for users to view.';
          break;
        case OnSiteStatus.CANCELLED:
          msg =
            'Are you sure you want to move the deployment to a cancelled state? Doing so will prevent any further changes to this event and remove the card from the microsite.';
          break;
        case OnSiteStatus.DONE:
          msg =
            'Are you sure you want to mark this deployment as done? Doing so will remove the card from the microsite.';
          break;
        default:
          msg = 'Are you sure?';
      }

      setConfirmStatusChange({
        open: true,
        status,
        msg,
      });
    },
    [setConfirmStatusChange],
  );

  /**
   * Handler to update status in db
   * @param status
   */
  const handleOnSiteStatusUpdate = useCallback(
    async (status: OnSiteStatus | null) => {
      try {
        if (!status) return;

        setSubmitLoading(true);
        const response =
          await OnSiteDeploymentService.updateOnSiteDeploymentStatus(
            onSiteId,
            status,
          );
        if (response && response?.data?.success) {
          toast.success(`OnSite Deployment moved to ${status} successfully.`);
          setConfirmStatusChange({ open: false, status: null, msg: '' });
          getOnsiteDeploymentDetails();
        }
      } catch (error) {
        toast.error('Error occurred while updating status.');
      } finally {
        setSubmitLoading(false);
      }
    },
    [
      onSiteId,
      getOnsiteDeploymentDetails,
      setConfirmStatusChange,
      setSubmitLoading,
    ],
  );

  return dataLoading ? (
    <>
      <LoadingView />
    </>
  ) : (
    <>
      <SectionHeader
        title={'Onsite Deployments'}
        titleChip={
          <StatusChip
            bgColor={onSiteStatus.bgColor}
            color={onSiteStatus.color}
            title={onSiteStatus?.title ?? 'N/A'}
          />
        }
        subTitle={'Create a new onsite deployment or edit the existing ones'}
        rightElement={
          <>
            <StyledButton
              type="submit"
              onClick={() => history.push(onSiteId + '/meetings')}
              sx={{ marginRight: 0, marginLeft: 0 }}
            >
              Show users
            </StyledButton>
            {renderCancelBtn && (
              <StyledButton
                size="small"
                sx={{
                  marginRight: 0,
                  marginLeft: 0,
                  color: 'var(--chip-red-color)',
                  backgroundColor: 'var(--chip-red-bg-color)',
                  '&:hover': {
                    backgroundColor: 'var(--chip-red-bg-color)',
                  },
                }}
                startIcon={MaterialIcons.Close}
                onClick={() =>
                  handleStatusUpdateConfirmation(OnSiteStatus.CANCELLED)
                }
                disabled={submitLoading}
              >
                Cancel Deployment
              </StyledButton>
            )}
            {renderLiveBtn && (
              <StyledButton
                size="small"
                sx={{
                  marginRight: 0,
                  marginLeft: 0,
                  color:
                    formik?.values?.status === OnSiteStatus.LIVE
                      ? 'var(--chip-blue-color)'
                      : 'var(--chip-green-color)',
                  backgroundColor:
                    formik?.values?.status === OnSiteStatus.LIVE
                      ? 'var(--chip-blue-bg-color)'
                      : 'var(--chip-green-bg-color)',
                  '&:hover': {
                    backgroundColor:
                      formik?.values?.status === OnSiteStatus.LIVE
                        ? 'var(--chip-blue-bg-color)'
                        : 'var(--chip-green-bg-color)',
                  },
                }}
                onClick={() =>
                  handleStatusUpdateConfirmation(
                    formik?.values?.status === OnSiteStatus.LIVE
                      ? OnSiteStatus.DRAFT
                      : OnSiteStatus.LIVE,
                  )
                }
                disabled={submitLoading}
              >
                {formik?.values?.status === OnSiteStatus.LIVE
                  ? 'Move to Draft'
                  : 'Go Live'}
              </StyledButton>
            )}
            {renderSaveBtn && (
              <StyledButton
                type="submit"
                onClick={() => formik.handleSubmit()}
                disabled={submitLoading}
                sx={{ marginRight: 0, marginLeft: 0 }}
              >
                Save
              </StyledButton>
            )}
            {renderDoneBtn && (
              <StyledButton
                type="submit"
                onClick={() =>
                  handleStatusUpdateConfirmation(OnSiteStatus.DONE)
                }
                disabled={submitLoading}
                sx={{
                  marginRight: 0,
                  marginLeft: 0,
                  color: 'var(--chip-grey-color)',
                  backgroundColor: 'var(--chip-grey-bg-color)',
                  '&:hover': {
                    backgroundColor: 'var(--chip-grey-bg-color)',
                  },
                }}
              >
                Mark as Done
              </StyledButton>
            )}
          </>
        }
        showDivider
      />

      <Grid container spacing={2}>
        <OnsiteBasicConfiguration
          formik={formik}
          setShowImageUploadDialog={setShowImageUploadDialog}
          isEditDisabled={isEditDisabled}
        />
        <OnsiteEventConfiguration
          formik={formik}
          refetchOnSiteDetails={getOnsiteDeploymentDetails}
          isEditDisabled={isEditDisabled}
        />
        <OnsiteProviderConfiguration
          formik={formik}
          isEditDisabled={isEditDisabled}
        />
      </Grid>
      <OnsiteCardUploadDialog
        open={showImageUploadDialog}
        onUploadSuccess={(url) => formik.setFieldValue('cardImageUrl', url)}
        handleClose={() => setShowImageUploadDialog(false)}
      />
      <ConfirmDialog
        open={confirmStatusChange.open}
        handleClose={() =>
          setConfirmStatusChange({ open: false, status: null, msg: '' })
        }
        onConfirm={() => handleOnSiteStatusUpdate(confirmStatusChange.status)}
        title="Important!"
        disabled={submitLoading}
      >
        {confirmStatusChange.msg}
      </ConfirmDialog>
    </>
  );
};

export default OnsiteSessionDetails;
