import { Box, InputAdornment, TextField } from '@mui/material';
import { SearchIcon } from '@primer/octicons-react';
import { useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import { OrganisationServiceV2 } from '../../../../../../shared/service/services_v2';
import { ConfigItem } from '../../../../../../shared/types/organisation/OrganisationDetailItem';
import { LoadingView } from '../../../../../components/loadingView';
import StyledButton from '../../../../../widgets/styledButton/StyledButton';
import { useGetOrgConfigs } from '../hooks/useGetOrgConfigs';
import { useToggleMfaEnabled } from '../hooks/useToggleMfaEnabled';
import AddConfigDialog from './AddConfigDialog';
import ConfigTable from './ConfigTable';
import { MaterialIcons } from '../../../../../../assets/MaterialIcons';
import {
  AclActions,
  AclModules,
} from '../../../../../../shared/constants/Ability';

const ConfigEditor = ({
  orgId,
  showLiteMode,
}: {
  orgId: number;
  showLiteMode?: boolean;
}) => {
  const [configToEdit, setConfigToEdit] = useState<ConfigItem | null>(null);

  const [showAddConfigDialog, setShowAddConfigDialog] =
    useState<boolean>(false);

  const { mutate: toggleMfa } = useToggleMfaEnabled();
  const { data: orgConfigList, refetch: refetchOrgConfigs } =
    useGetOrgConfigs(orgId);

  const existingKeys = useMemo(() => {
    return orgConfigList?.map((item) => item?.key)?.filter(Boolean);
  }, [orgConfigList]);

  const [filter, setFilter] = useState<string>('');
  const configsToShow =
    orgConfigList?.filter((config: ConfigItem) =>
      config.key.toLowerCase().includes(filter.toLowerCase()),
    ) ?? [];

  if (!orgConfigList) {
    return <LoadingView />;
  }

  // Filtering the configs by key
  const orgModeEnabled = true;

  const handleSubmit = async (configs: Partial<ConfigItem>[]) => {
    let response = null;

    try {
      if (orgModeEnabled) {
        response = await OrganisationServiceV2.addOrgConfigs(configs, orgId);
      }

      if (response && response.data.success) {
        toast.success('Configs added successfully!');

        if (orgModeEnabled) {
          refetchOrgConfigs();
        }

        setShowAddConfigDialog(false);
      }
    } catch (error) {
      toast.error('An error occurred while attempting to add the configs.');
    }
  };

  return (
    <>
      <Box>
        {!showLiteMode && (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            {/*<Typography color='textPrimary' variant='h5' fontWeight='bold'>*/}
            {/*  Config Editor*/}
            {/*</Typography>*/}
            {/*Custom Configurations*/}
            <TextField
              size="small"
              onChange={(event) => setFilter(event.target.value)}
              value={filter}
              placeholder="Filter by key"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon size={18} fill="#8c90a6" />
                  </InputAdornment>
                ),
              }}
            />
            <StyledButton
              variant="ghost"
              startIcon={MaterialIcons.Add}
              onClick={() => setShowAddConfigDialog(true)}
              disabled={false}
              permission={{
                action: AclActions.Manage,
                subject: AclModules.ORGANISATION,
              }}
            >
              Add a config
            </StyledButton>
          </Box>
        )}

        <Box>
          <ConfigTable
            showLiteMode={showLiteMode}
            configList={configsToShow}
            getConfigs={refetchOrgConfigs}
            configToEdit={configToEdit}
            setConfigToEdit={setConfigToEdit}
          />
        </Box>
      </Box>

      <AddConfigDialog
        open={showAddConfigDialog}
        handleClose={() => setShowAddConfigDialog(false)}
        handleSubmit={handleSubmit}
        existingKeys={existingKeys}
      />
    </>
  );
};

export default ConfigEditor;
