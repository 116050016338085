import React, { ReactNode } from 'react';
import { Dialog, Slide, SlideProps } from '@mui/material';
import { HeaderView } from '../headerView';

const Transition = React.forwardRef<unknown, SlideProps>((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

Transition.displayName = 'DialogTransition';

export const GenericDialog = ({
  handleClose,
  open,
  children,
  noFullScreen,
  title,
  extraElements,
  footerElements,
  fitToContent = false,
}: {
  handleClose: () => void;
  open: boolean;
  noFullScreen?: boolean;
  children: ReactNode;
  fitToContent?: boolean;
  title?: string;
  extraElements?: ReactNode;
  footerElements?: ReactNode;
}) => {
  return (
    <Dialog
      sx={{
        zIndex: '1202 !important',
        '& .MuiDialog-paper': {
          borderRadius: '0.5rem',
          minWidth: noFullScreen ? '70vw' : 'auto',
          minHeight: noFullScreen && !fitToContent ? '50vh' : 'auto',
          maxHeight: '98vh',
          maxWidth: '98vw',
          overflow: 'auto',
        },
      }}
      fullScreen={!noFullScreen}
      open={open}
      onClose={() => handleClose()}
      TransitionComponent={Transition}
    >
      <HeaderView
        onClose={handleClose}
        title={title}
        extraElements={extraElements}
      />

      <div style={{ margin: 20 }}>{children}</div>

      {footerElements && (
        <div style={{ margin: 20, padding: '0.5rem' }}>{footerElements}</div>
      )}
    </Dialog>
  );
};
