import moment from 'moment';
import 'moment-timezone';
import { StyledEngineProvider } from '@mui/material';

import { BrowserRouter } from 'react-router-dom';
import RootView from './view/screens/RootView';
import { AclGuard } from './shared/guard/AclGuard';

moment.tz.setDefault('utc');

const App = () => {
  return (
    <BrowserRouter basename="admin">
      <StyledEngineProvider injectFirst>
        <RootView />
      </StyledEngineProvider>
    </BrowserRouter>
  );
};

// const mapStateToProps = (state: RootState) => ({
//   isLoggedIn: state.user.isLoggedIn,
// });
// const mapDispatchToProps = (dispatch: Dispatch) => ({
//   setAppConfig: (config: AppConfig) =>
//     dispatch(AppActions.setAppConfig(config)),
//   setUserLoginSuccess: () => dispatch(UserActions.setLoginSucess()),
//   setUserLogoutSuccess: () => dispatch(UserActions.setLogoutSucess()),
// });

// export default connect(mapStateToProps, mapDispatchToProps)(App);

export default App;
