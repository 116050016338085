import { NetworkInstanceV2 } from './NetworkInstance';
import { ApiConfigV2 } from '../../apiConfigV2';
import Axios from 'axios';
import { isProduction } from '../../../utilities/App';

export class AuthV2 {
  client: NetworkInstanceV2;

  constructor(_client: NetworkInstanceV2) {
    this.client = _client;
  }

  login = (email: string, password: string) =>
    this.client.post(ApiConfigV2.api.auth.login, {
      email,
      password,
    });

  getSSOLink = (
    email: string,
    role = 'internal',
  ): Promise<Record<'loginUrl', string>> =>
    this.client
      .post(ApiConfigV2.api.auth.getSsoUrl, {
        email,
        role,
        redirectionUrl: isProduction()
          ? undefined
          : window.location.origin + '/sso/success',
      })
      .then((response) => response.data.data);

  testToken = (token: string) =>
    Axios.get(ApiConfigV2.api.auth.me, {
      headers: {
        Authorization: token,
      },
    });

  getUserDeleteRequests = () =>
    this.client.get(ApiConfigV2.api.auth.deleteRequestList);

  confirmUserDeleteRequest = (requestId: number) =>
    this.client.post(ApiConfigV2.api.auth.deleteRequestConfirm, {
      requestId,
    });

  getUserPermissions = () =>
    this.client.get(ApiConfigV2.api.accessControl.userPermissions);
}
