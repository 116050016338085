import { createContext } from 'react';
import { createContextualCan } from '@casl/react';
import { AppAbility } from '../../../config/Ability';
import { createMongoAbility } from '@casl/ability';
import { AclActions, AclModules } from '../../../shared/constants/Ability';

export const defaultAbility = createMongoAbility<AppAbility>([
  { action: AclActions.Manage, subject: AclModules.ALL },
]);

export const AbilityContext = createContext<AppAbility>(defaultAbility);

export default createContextualCan<AppAbility>(AbilityContext.Consumer);
