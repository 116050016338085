import {
  Autocomplete,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { Fragment, useState } from 'react';
import toast from 'react-hot-toast';

import { MaterialIcons } from '../../../../../../assets/MaterialIcons';
import { OrganisationTeamMemberRole } from '../../../../../../shared/constants/Organisation';
import { OrganisationServiceV2 } from '../../../../../../shared/service/services_v2';
import { Country } from '../../../../../../shared/types/provider/Provider';
import { useGetCountries } from '../../../../../../utilities/hooks/useGetCountries';
import { GenericDialog } from '../../../../../components/genericDialog';
import StyledButton from '../../../../../widgets/styledButton/StyledButton';
import { BulkAddMembersRequestBody } from '../../../../../../shared/types/organisation/OrganisationTeamItem';

const AddMembersDialog = ({
  open,
  handleClose,
  refreshData,
  teamId,
  orgId,
}: {
  open: boolean;
  handleClose: () => void;
  refreshData: () => void;
  teamId: number;
  orgId: number;
}) => {
  const [members, setMembers] = useState<
    {
      emailAddress: string;
      role: string;
      countryCode?: string;
    }[]
  >([{ emailAddress: '', role: '0', countryCode: '' }]);

  const { data: countries } = useGetCountries();
  const [addHRManagerToAllTeams, setAddHRManagerToAllTeams] = useState(false);

  const handleSubmit = async () => {
    let response = null;
    let HrResponse = null;
    let ManagerResponse = null;
    let filteredHrEmails = [];
    let filteredManagerEmails = [];
    let hrCountryCode = undefined;
    let managerCountryCode = undefined;

    try {
      if (addHRManagerToAllTeams === true) {
        // Filter HR emails and countryCode
        filteredHrEmails = members
          ?.filter((item) => item.role === '2')
          ?.map((item) => item.emailAddress);

        hrCountryCode = members.find((item) => item.role === '2')?.countryCode;

        if (filteredHrEmails.length) {
          const hrObj: BulkAddMembersRequestBody = {
            emailList: filteredHrEmails,
            role: '2',
            countryCode: hrCountryCode,
          };
          HrResponse = await OrganisationServiceV2.bulkAddTeamMembers(
            orgId,
            hrObj,
          );
        }

        filteredManagerEmails = members
          ?.filter((item) => item.role === '1')
          ?.map((item) => item.emailAddress);

        managerCountryCode = members.find((item) => item.role === '1')
          ?.countryCode;

        if (filteredManagerEmails.length) {
          const ManagerObj: BulkAddMembersRequestBody = {
            emailList: filteredManagerEmails,
            role: '1',
            countryCode: managerCountryCode,
          };
          ManagerResponse = await OrganisationServiceV2.bulkAddTeamMembers(
            orgId,
            ManagerObj,
          );
        }
      } else {
        response = await OrganisationServiceV2.addMultipleTeamMembers(teamId, {
          newMembers: members, // members includes countryCode
        });
      }

      if (
        (response && response.data.success) ||
        (HrResponse && HrResponse.data.success) ||
        (ManagerResponse && ManagerResponse.data.success)
      ) {
        refreshData();
        toast.success(
          'Member Adding to All Teams Job has started. Changes will be reflected shortly. Please check back later.',
        );
        handleClose();
        setMembers([{ emailAddress: '', role: '0', countryCode: '' }]);
      }
    } catch (error) {
      toast.error(
        'An error occurred while adding the team members. Please try again.',
      );
    }
  };

  return (
    <GenericDialog
      open={open}
      handleClose={handleClose}
      noFullScreen
      fitToContent
      title="Add members"
      extraElements={
        <>
          {members.some(
            (member) => member.role === '1' || member.role === '2',
          ) && (
            <FormControlLabel
              control={
                <Checkbox
                  checked={addHRManagerToAllTeams}
                  onChange={() => setAddHRManagerToAllTeams((prev) => !prev)}
                />
              }
              label="Add HR or Manager to all teams"
            />
          )}

          <StyledButton
            color="light"
            variant="ghost"
            startIcon={MaterialIcons.Add}
            onClick={() =>
              setMembers([...members, { emailAddress: '', role: '0' }])
            }
          >
            Add another member
          </StyledButton>
          <StyledButton sx={{ width: '7rem' }} onClick={handleSubmit}>
            Save
          </StyledButton>
        </>
      }
    >
      <Grid
        paddingLeft={5}
        paddingRight={5}
        maxWidth="75vw"
        width="75vw"
        maxHeight="75vh"
        overflow="auto"
        container
        spacing={2}
      >
        <Grid item xs={12}>
          <Typography variant="subtitle2">
            Note: If the entered email already exists in this Team, then
            submitting will overwrite them
          </Typography>
        </Grid>
        {members.map(
          (
            member: {
              emailAddress: string;
              role: string;
              countryCode?: string;
            },
            index: number,
          ) => {
            return (
              <Fragment key={`add-new-team-member-${orgId}-${teamId}-${index}`}>
                <Grid item xs={4}>
                  <InputLabel>Email address</InputLabel>
                  <TextField
                    value={member.emailAddress}
                    fullWidth
                    type="email"
                    autoFocus
                    onChange={(event) => {
                      const oldData = [...members];
                      oldData[index].emailAddress = event.target.value;
                      setMembers(oldData);
                    }}
                    size="small"
                  />
                </Grid>
                <Grid item xs={3}>
                  <InputLabel>Role</InputLabel>
                  <Select
                    fullWidth
                    value={member.role}
                    onChange={(event) => {
                      const oldData = [...members];
                      oldData[index].role = event.target.value;
                      setMembers(oldData);
                    }}
                    size="small"
                  >
                    {Object.keys(OrganisationTeamMemberRole).map(
                      (key: string, idx) => (
                        <MenuItem key={`${key}-${idx}`} value={key}>
                          {OrganisationTeamMemberRole[key].label}
                        </MenuItem>
                      ),
                    )}
                  </Select>
                </Grid>
                <Grid item xs={3}>
                  <InputLabel>Country</InputLabel>
                  <Autocomplete
                    disabled={false}
                    options={countries ?? []}
                    getOptionLabel={(option: Country) => option.countryName}
                    value={
                      countries?.find(
                        (country) => country.code === member.countryCode,
                      ) || null
                    }
                    onChange={(event: any, newValue: Country | null) => {
                      const oldData = [...members];
                      oldData[index].countryCode = newValue?.code || '';
                      setMembers(oldData);
                    }}
                    renderInput={(params: any) => <TextField {...params} />}
                    key={`countryAutocomplete${member.countryCode}`}
                    size="small"
                  />
                </Grid>
                <Grid item xs={2} display={'flex'} alignItems={'center'}>
                  <IconButton
                    color="error"
                    size="large"
                    onClick={() =>
                      setMembers(members.filter((item, i) => i !== index))
                    }
                  >
                    {MaterialIcons.Delete}
                  </IconButton>
                </Grid>
              </Fragment>
            );
          },
        )}
      </Grid>
    </GenericDialog>
  );
};

export default AddMembersDialog;
