import { ClearOutlined, InfoOutlined } from '@mui/icons-material';
import {
  Autocomplete,
  Box,
  Checkbox,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { useFormik } from 'formik';
import { Fragment, useEffect, useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import { UserManagementService } from '../../../shared/service/services_v2';
import useDebounce from '../../../utilities/hooks/useDebounce';
import LabelWithHelp from '../../components/LabelWithHelp/LabelWithHelp';
import { GenericDialog } from '../../components/genericDialog';
import StyledButton from '../../widgets/styledButton/StyledButton';
import { addUserFormValidationSchema } from './utils/schemas/ValidateAddUserForm.schema';
import { IUser } from './utils/types/user-management.interface';
import { IAssociatedRole } from '../access-control/utils/types/access-control.interface';
import { useUserOverriddenPermissions } from './hooks/useUser';
import { InformationIconWithTooltip } from '../access-control/AssignPermissionsToRoleDialog';
import {
  AclActions,
  AclModuleRows,
  AclModules,
  IAclModuleRow,
} from '../../../shared/constants/Ability';
import { useRolePermissions } from '../access-control/hooks/useRoles';
import ToggleSwitchButton from '../../widgets/ToggleSwitchButton/ToggleSwitchButton';
import SectionCard from '../../components/SectionCard/SectionCard';

interface IAddUserInputs {
  id?: string;
  name: string;
  email?: string | null;
  associatedRoles: number[];
}

const EMAIL_SUFFIX = '@intellect.co';

const AddUserDialog = ({
  open,
  edit,
  roles,
  handleClose,
  handleSuccess,
  existingUser,
}: {
  open: boolean;
  edit: boolean;
  handleClose: () => void;
  handleSuccess: () => void;
  roles: IAssociatedRole[];
  existingUser: Partial<IUser> | null;
}) => {
  const [submitLoading, setSubmitLoading] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);
  const [emailSearch, setEmailSearch] = useState('');
  const [availableUsersMap, setAvailableUsersMap] = useState<{
    [email: string]: { name: string; id: string };
  }>({});
  const [adminAccess, setAdminAccess] = useState<boolean>(false);
  const [selectedPermissions, setSelectedPermissions] = useState<number[]>([]);
  const [overrideDefaultPermissions, setOverrideDefaultPermissions] =
    useState(false);

  /**
   * Searches Non Staff users
   * @param email
   */
  const searchUser = async (email: string) => {
    try {
      const response = await UserManagementService.searchUser(email);
      if (response && response?.data?.success) {
        const users: {
          id: string;
          email: string;
          name: string;
        }[] = response?.data?.data?.items ?? [];

        const map = users.reduce(
          (
            result: {
              [email: string]: { name: string; id: string };
            },
            item,
          ) => {
            result[item.email] = { name: item.name, id: item.id };

            return result;
          },
          {},
        );
        setAvailableUsersMap(map);
      }
    } catch (error: any) {
      toast.error(
        error?.response?.data?.message ??
          'An error occurred while attempting to add a user',
      );
    }
  };
  const {
    values,
    errors,
    touched,
    handleChange,
    handleSubmit,
    resetForm,
    setFieldValue,
  } = useFormik<IAddUserInputs>({
    enableReinitialize: true,
    initialValues: {
      name: existingUser?.name || '',
      email: existingUser?.email?.replace(EMAIL_SUFFIX, '') || '',
      associatedRoles:
        existingUser?.associatedRoles?.map((item) => item?.id) ?? [],
    },
    validationSchema: addUserFormValidationSchema,

    onSubmit: async () => {
      if (!edit) {
        handleAddUser();
      } else {
        handleEditUser();
      }
    },
  });

  const debouncedFilter = useDebounce(emailSearch, 500);

  useEffect(() => {
    // Making an api call when atleast 3 letters are there
    if (debouncedFilter?.length > 2) {
      searchUser(debouncedFilter);
    } else {
      setAvailableUsersMap({});
    }
  }, [debouncedFilter]);

  /**
   * Handler for Adding User
   */
  const handleAddUser = async () => {
    let response = null;
    try {
      setSubmitLoading(true);
      const { email, ...restPayload } = values;
      response = await UserManagementService.addUser({
        email: email + EMAIL_SUFFIX,
        ...(overrideDefaultPermissions
          ? { userPermissions: selectedPermissions }
          : {}),
        ...restPayload,
      });
      if (response && response?.data?.success) {
        toast.success('User added successfully!');
        resetForm();
        handleSuccess();
      }
    } catch (error: any) {
      toast.error(
        error?.response?.data?.message ??
          'An error occurred while attempting to add a user',
      );
    } finally {
      setSubmitLoading(false);
    }
  };

  /**
   * Handler for Editing User
   */
  const handleEditUser = async () => {
    let response = null;
    try {
      setSubmitLoading(true);
      if (!existingUser?.id) throw new Error();
      const { email, ...restPayload } = values;

      response = await UserManagementService.editUser(existingUser?.id, {
        email: email + EMAIL_SUFFIX,
        userPermissions: overrideDefaultPermissions ? selectedPermissions : [],
        ...restPayload,
      });

      if (response && response?.data?.success) {
        toast.success('User updated successfully!');
        resetForm();
        handleSuccess();
      }
    } catch (error: any) {
      toast.error(
        error?.response?.data?.message ??
          'An error occurred while attempting to add a user',
      );
    } finally {
      setSubmitLoading(false);
    }
  };

  const selectedRoles = useMemo(() => {
    return roles?.filter((item) => values?.associatedRoles?.includes(item?.id));
  }, [roles, values?.associatedRoles]);

  const { data: userOverridenPermissions } = useUserOverriddenPermissions(
    existingUser?.id,
  );
  const { data: rolePermissions } = useRolePermissions(
    selectedRoles?.map((item) => item?.id),
  );
  // useRolePermissions
  const isAdminAccessAdded = useMemo(() => {
    return rolePermissions?.find(
      (pr) => pr.action === AclActions.Manage && pr.subject === AclModules.ALL,
    );
  }, [rolePermissions]);

  const roleDefaultPermissionsIds = useMemo(() => {
    return rolePermissions?.map((item) => item?.id);
  }, [rolePermissions]);

  useEffect(() => {
    const permissions =
      overrideDefaultPermissions && userOverridenPermissions?.length
        ? userOverridenPermissions?.filter(
            (item) => roleDefaultPermissionsIds?.includes(item?.id),
          )
        : rolePermissions;

    const existingRolePermissions = permissions?.map((item) => item?.id) ?? [];

    setSelectedPermissions(existingRolePermissions);

    setAdminAccess(
      isAdminAccessAdded?.id &&
        existingRolePermissions?.includes(isAdminAccessAdded?.id)
        ? true
        : false,
    );
  }, [
    userOverridenPermissions,
    rolePermissions,
    isAdminAccessAdded,
    roleDefaultPermissionsIds,
    overrideDefaultPermissions,
  ]);

  useEffect(() => {
    setOverrideDefaultPermissions(
      userOverridenPermissions?.length ? true : false,
    );
  }, [userOverridenPermissions]);

  const addPermission = (permissionId: number) => {
    setSelectedPermissions((prev) => [...prev, permissionId]);
  };

  const removePermission = (permissionId: number) => {
    setSelectedPermissions((prev) => {
      const index = prev.indexOf(permissionId);

      return [...prev.slice(0, index), ...prev.slice(index + 1)];
    });
  };

  const permissionsModuleMap = useMemo(() => {
    return (
      rolePermissions?.reduce((result: Record<string, number>, permission) => {
        if (permission?.id) {
          const key = `${permission.action}:${permission.subject}`;

          result[key] = permission?.id;
        }

        return result;
      }, {}) ?? {}
    );
  }, [rolePermissions]);

  const PermissionRow = ({ row }: { row: IAclModuleRow }) => {
    const canReadId = permissionsModuleMap[`read:${row.subject}`];
    const canCreateId = permissionsModuleMap[`create:${row.subject}`];
    const canManageId = permissionsModuleMap[`manage:${row.subject}`];

    return (
      <Fragment>
        <TableRow sx={{ height: 48 }}>
          <TableCell width={460} component="th" scope="row">
            <Typography>{row.moduleName}</Typography>
          </TableCell>
          <TableCell>
            <Box display={'flex'} justifyContent={'center'}>
              {canReadId ? (
                <Checkbox
                  disabled={!overrideDefaultPermissions}
                  checked={selectedPermissions.includes(canReadId)}
                  onChange={(e) => {
                    e.target.checked
                      ? addPermission(canReadId)
                      : removePermission(canReadId);
                  }}
                />
              ) : (
                '-'
              )}
            </Box>
          </TableCell>
          <TableCell>
            <Box display={'flex'} justifyContent={'center'}>
              {canCreateId ? (
                <Checkbox
                  disabled={!overrideDefaultPermissions}
                  checked={selectedPermissions.includes(canCreateId)}
                  onChange={(e) => {
                    e.target.checked
                      ? addPermission(canCreateId)
                      : removePermission(canCreateId);
                  }}
                />
              ) : (
                '-'
              )}
            </Box>
          </TableCell>
          <TableCell>
            <Box display={'flex'} justifyContent={'center'}>
              {canManageId ? (
                <Checkbox
                  disabled={!overrideDefaultPermissions}
                  checked={selectedPermissions.includes(canManageId)}
                  onChange={(e) => {
                    e.target.checked
                      ? addPermission(canManageId)
                      : removePermission(canManageId);
                  }}
                />
              ) : (
                '-'
              )}
            </Box>
          </TableCell>
        </TableRow>
      </Fragment>
    );
  };

  return (
    <GenericDialog
      noFullScreen
      open={open}
      handleClose={handleClose}
      title={`${edit ? 'Edit' : 'Add'} User`}
      extraElements={
        <StyledButton
          size="large"
          sx={{ width: '10rem' }}
          loading={true}
          type="submit"
          onClick={() => handleSubmit()}
          disabled={submitLoading}
        >
          Submit
        </StyledButton>
      }
    >
      <Box height={'64vh'}>
        <Grid container spacing={2} padding={2}>
          <Grid item sm={6}>
            <InputLabel htmlFor="name">Name</InputLabel>
            <TextField
              fullWidth
              id="name"
              placeholder="Name"
              size="small"
              value={values.name}
              onChange={handleChange}
              error={touched.name && Boolean(errors.name)}
              helperText={touched.name && errors.name}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      sx={{
                        visibility: values.name ? 'visible' : 'hidden',
                      }}
                      onClick={() => setFieldValue('name', '')}
                    >
                      <ClearOutlined fontSize="small" />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>

          <Grid item sm={4}>
            <InputLabel htmlFor="email">
              <LabelWithHelp
                label="Email"
                helpText="Searches through all Non-Staff users & for new value Press Enter"
              />
            </InputLabel>
            {edit ? (
              <TextField
                fullWidth
                id="email"
                size="small"
                placeholder="email"
                value={values.email}
                onChange={handleChange}
                error={touched.email && Boolean(errors.email)}
                helperText={touched.email && errors.email}
                InputProps={{
                  endAdornment: (
                    <>
                      <InputAdornment position="start">
                        {EMAIL_SUFFIX}
                      </InputAdornment>
                      <InputAdornment position="end">
                        <IconButton
                          sx={{
                            visibility: values.email ? 'visible' : 'hidden',
                          }}
                          onClick={() => setFieldValue('email', '')}
                        >
                          <ClearOutlined fontSize="small" />
                        </IconButton>
                      </InputAdornment>
                    </>
                  ),
                }}
              />
            ) : (
              <Autocomplete
                freeSolo
                disablePortal
                options={Object.keys(availableUsersMap)}
                value={values?.email}
                onChange={(event, newValue: any) => {
                  const existingUser = availableUsersMap?.[newValue];
                  if (existingUser) {
                    setFieldValue('name', existingUser?.name);
                    setFieldValue('id', existingUser?.id);
                  }
                  setFieldValue(
                    'email',
                    newValue?.replace(EMAIL_SUFFIX, '') ?? '',
                  );
                }}
                onInputChange={(event, newValue) => {
                  setEmailSearch(newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={touched.email && Boolean(errors.email)}
                    helperText={touched.email && errors.email}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {params.InputProps.endAdornment}
                          <InputAdornment position="start">
                            {EMAIL_SUFFIX}
                          </InputAdornment>
                        </>
                      ),
                    }}
                  />
                )}
                size="small"
                ChipProps={{ size: 'small' }}
                key={2}
                loading={searchLoading}
              />
            )}
          </Grid>
          <Grid item sm={6}>
            <InputLabel htmlFor="type">Role</InputLabel>
            <Autocomplete
              multiple
              disablePortal
              limitTags={1}
              options={roles}
              getOptionLabel={(option) => option?.name}
              value={selectedRoles}
              onChange={(event, newValue) => {
                setFieldValue(
                  'associatedRoles',
                  newValue?.map((item) => item?.id),
                );
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={
                    touched.associatedRoles && Boolean(errors.associatedRoles)
                  }
                  helperText={touched.associatedRoles && errors.associatedRoles}
                />
              )}
              size="small"
              ChipProps={{ size: 'small' }}
              key={1}
            />
          </Grid>
          <Grid item sm={4}>
            <InputLabel>
              <LabelWithHelp
                label="Override Role's Default Permissions?"
                helpText="Select this option to override the default permissions assigned to this user by their roles. Enabling this allows you to customize specific permissions for the user, replacing the standard set provided by default."
              />
            </InputLabel>
            <ToggleSwitchButton
              checked={!!overrideDefaultPermissions}
              onChange={(v) => {
                setOverrideDefaultPermissions(v);
              }}
              uniqueKey={'override-default-permissions'}
            />
          </Grid>
          <Grid item sm={12}>
            <InputLabel>Permissions</InputLabel>
            <SectionCard sx={{ maxHeight: '44vh', overflow: 'auto' }}>
              <TableContainer>
                <Table
                  size="small"
                  sx={{
                    opacity: overrideDefaultPermissions ? 1 : 0.7,
                  }}
                >
                  <TableHead>
                    <TableRow sx={{ height: 48 }}>
                      <TableCell
                        width={460}
                        component="th"
                        scope="row"
                      ></TableCell>
                      <TableCell width={150}>
                        <Box
                          sx={{
                            display: 'flex',
                            fontSize: '1rem',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          Read
                          <InformationIconWithTooltip title="User with this permission, will be able to read/view selected module." />
                        </Box>
                      </TableCell>
                      <TableCell width={150}>
                        <Box
                          sx={{
                            display: 'flex',
                            fontSize: '1rem',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          Create
                          <InformationIconWithTooltip title="User with this permission, will be able to create/update selected module." />
                        </Box>
                      </TableCell>
                      <TableCell width={150}>
                        <Box
                          sx={{
                            display: 'flex',
                            fontSize: '1rem',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          Manage
                          <InformationIconWithTooltip title="User with this permission, will be able to do all the read/view, create/update, delete selected module." />
                        </Box>
                      </TableCell>
                    </TableRow>
                    <TableRow sx={{ height: 48 }}>
                      <TableCell>
                        <Box
                          sx={{
                            display: 'flex',
                            fontSize: '1rem',
                            alignItems: 'center',
                            textTransform: 'capitalize',
                          }}
                        >
                          Administrator Access
                          <Tooltip
                            placement="top"
                            title="Allows a full access to the system"
                          >
                            <InfoOutlined sx={{ ml: 0.5, fontSize: '15px' }} />
                          </Tooltip>
                        </Box>
                      </TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell>
                        <Box display={'flex'} justifyContent={'center'}>
                          {isAdminAccessAdded ? (
                            <Checkbox
                              disabled={!overrideDefaultPermissions}
                              checked={adminAccess}
                              onChange={(
                                event: React.ChangeEvent<HTMLInputElement>,
                              ) => {
                                const checked = event.target.checked;
                                setAdminAccess(checked);

                                if (isAdminAccessAdded?.id) {
                                  if (!checked) {
                                    removePermission(isAdminAccessAdded.id);
                                  } else {
                                    addPermission(isAdminAccessAdded.id);
                                  }
                                }
                              }}
                            />
                          ) : (
                            '-'
                          )}
                        </Box>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody
                    sx={{
                      display: adminAccess ? 'none' : 'visible',
                    }}
                  >
                    {AclModuleRows?.map((row: IAclModuleRow) => (
                      <PermissionRow key={row.moduleName} row={row} />
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </SectionCard>
          </Grid>
        </Grid>
      </Box>
    </GenericDialog>
  );
};

export default AddUserDialog;
