import { Box, Typography } from '@mui/material';

function Home() {
  return (
    <Box
      display={'flex'}
      alignItems={'center'}
      justifyContent={'center'}
      flexDirection={'column'}
      gap={4}
    >
      <Typography variant="h5">
        Welcome Home! Your command center for progress, growth, and success. 🚀
      </Typography>
    </Box>
  );
}

export default Home;
