import { Box, Divider, IconButton, List, Tooltip } from '@mui/material';
import { useContext, useEffect, useState } from 'react';

import { useLocation, withRouter } from 'react-router-dom';
import { MaterialIcons } from '../../../assets/MaterialIcons';
import logo from '../../../assets/logo.svg';
import wordmark from '../../../assets/wordmark.svg';
import Config from '../../../config';
import { ISideBarItem, SideBarItem } from './items';
import { NavbarDrawer } from '../NavbarDrawer/NavbarDrawer';
import { RootViewContext } from '../../screens/RootView';

const Sidebar = () => {
  const [activeSidebarItem, setActiveSidebarItem] = useState<string[]>([]);

  const { minimizeNavbar, setMinimizeNavbar } = useContext(RootViewContext);

  const location = useLocation();
  useEffect(() => {
    const flattenedSidebarItems = Config.sidebar?.reduce(
      (newSidebar: { title: string; path: string }[], currentSidebar) => {
        newSidebar?.push({
          title: currentSidebar?.title,
          path: currentSidebar?.path,
        });

        currentSidebar?.children?.forEach((item) => {
          newSidebar?.push({
            title: item?.title,
            path: item?.path,
          });
        });

        return newSidebar;
      },
      [],
    );
    const navbarItem = flattenedSidebarItems?.filter(
      (item) => location?.pathname?.includes(item.path),
    );

    if (navbarItem) {
      setActiveSidebarItem(navbarItem?.map((item) => item?.title));
    }
  }, [location]);

  return (
    <NavbarDrawer
      variant="permanent"
      sx={{ width: Config.drawerWidth }}
      open={minimizeNavbar}
    >
      <Box
        display="flex"
        flexDirection={minimizeNavbar ? 'row' : 'column'}
        padding="0.75rem 1.5rem"
        alignItems="center"
        justifyContent="space-between"
        sx={{
          backgroundColor: '#f6f5f4',
        }}
        gap={2}
      >
        <Box display="flex" justifyContent={'center'} alignItems={'center'}>
          <Box overflow="hidden" width={35} height={35}>
            <img alt="Intellect Logo" src={logo} width="100%" height="100%" />
          </Box>
          {minimizeNavbar && (
            <Box width={100}>
              <img
                alt="Intellect Wordmark"
                src={wordmark}
                width="100%"
                height="100%"
              />
            </Box>
          )}
        </Box>

        <IconButton
          sx={{ color: '#000' }}
          onClick={() => {
            setMinimizeNavbar(!minimizeNavbar);
          }}
        >
          {minimizeNavbar
            ? MaterialIcons.ChevronLeft
            : MaterialIcons.ChevronRight}
        </IconButton>
      </Box>
      <Divider />
      <List
        sx={{
          backgroundColor: '#fbfaf9',
        }}
      >
        {Config?.sidebar?.map((item: ISideBarItem) => (
          <SideBarItem
            setActiveItem={setActiveSidebarItem}
            activeItem={activeSidebarItem}
            key={item.title}
            item={item}
            open={minimizeNavbar}
          />
        ))}
      </List>
      {/* <Divider /> */}
      {/* <List>{secondaryListItems}</List> */}
    </NavbarDrawer>
  );
};

export default withRouter(Sidebar);
