import {
  Autocomplete,
  Box,
  Divider,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';
import { Fragment, useContext, useEffect, useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import { useHistory } from 'react-router-dom';
import { MaterialIcons } from '../../../assets/MaterialIcons';
import { ChiefProviderServiceV2 } from '../../../shared/service/services_v2';
import { OrganisationListItem } from '../../../shared/types/organisation/OrganisationListItem';
import { useGetOrganisations } from '../../../utilities/hooks/useGetOrganisations';
import {
  encodeQueryStrings,
  useQueryString,
} from '../../../utilities/hooks/useQueryString';
import NoDataComponent from '../../components/NoDataComponent/NoDataComponent';
import { LoadingView } from '../../components/loadingView';
import StyledButton from '../../widgets/styledButton/StyledButton';
import { RootViewContext } from '../RootView';
import { CaseNotesGridColumns } from './utils/grid-columns/CaseNotesGridColumns';
import { CaseNotesFilterFields, ICaseNotes } from './utils/types/CaseNotes';
import { ChevronDownIcon, FilterIcon } from '@primer/octicons-react';

import CaseNotesFilters, { filterData, FilterRow } from './CaseNotesFilters';
import {
  convertUnixEpochToDateTime,
  getDisplayDate,
} from '../../../utilities/Display';
import FilterChip from '../../components/FilterChip/FilterChip';

const availableFontSizes = [8, 10, 12, 14, 16, 18, 20, 24, 26, 28, 30];

const formatDateForCaseNotes = (date: string) => {
  return moment(date).startOf('day').format('YYYY/MM/DD').toString();
};
const parsedNotes = (caseNotes: ICaseNotes[]) => {
  return caseNotes.map((note: any) => ({
    ...note,
    scheduledStartTime: convertUnixEpochToDateTime(
      Number(note?.scheduledStartTime),
    ),
    scheduledEndTime: convertUnixEpochToDateTime(
      Number(note?.scheduledEndTime),
    ),
    scheduledDate: getDisplayDate(note?.scheduledDate),
  }));
};
const CaseNotesList = () => {
  const queryString = useQueryString({
    arrayFormat: 'separator',
    arrayFormatSeparator: ',',
  });
  const { setAppBreadCrumbs } = useContext(RootViewContext);
  const { data: organisations } = useGetOrganisations();
  const [fontSize, setFontSize] = useState(16);
  const [lineHeight, setLineHeight] = useState(30);
  const [caseNotes, setCaseNotes] = useState<ICaseNotes[]>([]);
  const [total, setTotal] = useState(0);
  const [filterInputs, setFilterInputs] = useState<{
    orgId: number | null;
    orgName: string | null;
    from: string | null;
    to: string | null;
  }>({
    orgId: queryString?.orgId ? +queryString?.orgId : null,
    orgName: queryString?.orgName ? queryString?.orgName?.toString() : null,
    from: queryString?.from ? queryString?.from?.toString() : null,
    to: queryString?.to ? queryString?.to?.toString() : null,
  });
  const [loading, setLoading] = useState(false);
  const [showFilters, setShowFilters] = useState<null | HTMLElement>(null);
  const history = useHistory();

  const handleFilterClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setShowFilters(event.currentTarget);
  };
  const [filters, setFilters] = useState<FilterRow[]>([]);

  const handleDeleteFilter = (filterKey: string) => {
    setFilters((prevFilters) =>
      prevFilters.filter((filter) => filter.type !== filterKey),
    );
  };

  const getCaseNotes = async () => {
    try {
      if (
        !filterInputs?.orgId ||
        !filterInputs?.orgName ||
        !filterInputs.from ||
        !filterInputs.to
      ) {
        return;
      }

      setLoading(true);
      const response = await ChiefProviderServiceV2.getCaseNotes({
        companyId: filterInputs.orgId,
        companyName: filterInputs.orgName,
        startDate: formatDateForCaseNotes(filterInputs.from),
        endDate: formatDateForCaseNotes(filterInputs.to),
      });
      if (response?.data?.success) {
        const fetchedNotes = response?.data?.data?.meetings;
        // Parse the notes immediately after setting original notes
        setCaseNotes(parsedNotes(fetchedNotes)); // Set the parsed notes directly
        setTotal(response?.data?.data?.total ?? 0);
      }
    } catch (err: any) {
      // showing toast when error is not of VPN, bcz it is already handled in NetworkInstance interceptor.
      if (err?.response?.data?.errorCode !== 1003) {
        toast.error(
          'Something went wrong while fetching case notes, try after sometime.',
        );
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setAppBreadCrumbs([
      {
        label: 'Case notes reports',
        type: 'text',
      },
    ]);
  }, []);

  useEffect(() => {
    if (fontSize >= 20) {
      setLineHeight(40);
    } else if (fontSize >= 14) {
      setLineHeight(30);
    } else {
      setLineHeight(25);
    }
  }, [fontSize]);

  const selectedOrganisations = useMemo(() => {
    return organisations?.find((item) => item?.id === filterInputs?.orgId);
  }, [organisations, filterInputs?.orgId]);

  useEffect(() => {
    const params: Record<string, any> = {
      ...queryString,
      orgId: filterInputs?.orgId ?? '',
      orgName: filterInputs?.orgName ?? '',
      from: filterInputs?.from ?? '',
      to: filterInputs?.to ?? '',
    };

    history.replace({ search: encodeQueryStrings(params) });
  }, [filterInputs]);

  const handleFilterSubmit = (filters: FilterRow[]) => {
    setFilters(filters);
  };

  const handleResetFilters = () => {
    setFilters([]);
  };

  const FilteredData = filterData(caseNotes, filters);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <InputLabel>Organisation</InputLabel>
          <Autocomplete
            key={`${selectedOrganisations?.id}`}
            options={(organisations ?? []) as OrganisationListItem[]}
            getOptionLabel={(option) => option?.name}
            renderInput={(params) => (
              <TextField {...params} placeholder="Select Organisation" />
            )}
            size="small"
            ChipProps={{ size: 'small' }}
            onChange={(e, value) => {
              if (value) {
                setFilterInputs((prev) => ({
                  ...prev,
                  orgId: value?.id,
                  orgName: value?.name,
                }));
              }
            }}
            value={selectedOrganisations}
          />
        </Grid>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <Grid item xs={4}>
            <InputLabel>Date Range (From-To)</InputLabel>
            <Box display={'flex'} alignItems={'center'} gap={'5px'}>
              <DatePicker
                openTo="day"
                value={
                  filterInputs.from ? moment(filterInputs.from).toDate() : null
                }
                onChange={(value: Date | null) => {
                  if (value) {
                    setFilterInputs((prev) => ({
                      ...prev,
                      from: moment(value).startOf('day').toString(),
                      to: null,
                    }));
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    size="small"
                    placeholder="From"
                  />
                )}
              />
              -
              <DatePicker
                openTo="day"
                value={
                  filterInputs.to ? moment(filterInputs.to).toDate() : null
                }
                onChange={(value: Date | null) => {
                  if (value) {
                    setFilterInputs((prev) => ({
                      ...prev,
                      to: moment(value).startOf('day').toString(),
                    }));
                  }
                }}
                minDate={moment(filterInputs.from).toDate()}
                maxDate={moment(filterInputs.from).add(1, 'month').toDate()}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    size="small"
                    placeholder="To"
                  />
                )}
              />
            </Box>
          </Grid>
        </LocalizationProvider>
        <Grid item xs={4}>
          <InputLabel>{'\u200B'}</InputLabel>
          <Box display={'flex'} justifyContent={'flex-end'}>
            <StyledButton
              margin={0}
              startIcon={MaterialIcons.Search}
              onClick={getCaseNotes}
            >
              Search
            </StyledButton>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid
          item
          xs={12}
          width={12}
          display={'flex'}
          justifyContent={'space-between'}
          alignItems={'center'}
        >
          <Box display={'flex'} alignItems={'center'} padding={1} gap={2}>
            <Typography>Font Size</Typography>
            <Select
              size="small"
              value={fontSize}
              onChange={(e) => setFontSize(+e.target.value)}
            >
              {availableFontSizes?.map((item, idx) => (
                <MenuItem value={item} key={idx}>
                  {item}
                </MenuItem>
              ))}
            </Select>
          </Box>
          <Typography variant="subtitle1">
            Total Records: {FilteredData.length}
          </Typography>
          <Box display={'flex'} justifyContent={'flex-end'}>
            <StyledButton
              size="small"
              margin={0}
              variant="ghost"
              onClick={handleFilterClick}
              startIcon={<FilterIcon size={16} />}
              endIcon={<ChevronDownIcon size={18} />}
              sx={{
                border: filters.length > 0 ? '1px solid' : 'none',
              }}
            >
              Filter
              {filters.length > 0 ? (
                <Typography
                  marginLeft={1}
                  variant="subtitle2"
                  sx={{
                    backgroundColor: '#3E445B',
                    color: '#fff',
                    width: '20px',
                    height: '20px',
                    borderRadius: '50%',
                    fontWeight: 700,
                  }}
                >
                  {filters.length}
                </Typography>
              ) : null}
            </StyledButton>
          </Box>
        </Grid>
        <Grid item xs={12} display={'flex'} alignItems={'center'} gap={'4px'}>
          {filters.length > 0 ? (
            <Typography fontSize={'14px'}>Filtered By:</Typography>
          ) : (
            <></>
          )}
          {filters.map((filter) => (
            <Fragment key={`${filter.type}-${filter.value}`}>
              {filter.value && filter.type && (
                <FilterChip
                  title={`${CaseNotesFilterFields.find(
                    (item: any) => item.field === filter.type,
                  )?.label}: ${filter.value}`}
                  onDelete={() => handleDeleteFilter(filter.type)}
                />
              )}
            </Fragment>
          ))}
        </Grid>
        <Grid item xs={12} width={12}>
          <DataGrid
            columns={CaseNotesGridColumns}
            rows={FilteredData}
            rowCount={total}
            rowHeight={lineHeight}
            columnHeaderHeight={lineHeight}
            rowSelection={false}
            hideFooter={true}
            slots={{
              noRowsOverlay: NoDataComponent,
              noResultsOverlay: NoDataComponent,
              loadingOverlay: () => (
                <>
                  <LoadingView />
                </>
              ),
            }}
            hideFooterPagination={true}
            disableColumnSelector={true}
            loading={loading}
            sx={{
              '& ::-webkit-scrollbar': {
                width: '6px',
              },
              '& ::-webkit-scrollbar-track': {
                backgroundColor: '#f5f5f5',
              },
              '& ::-webkit-scrollbar-thumb': {
                borderRadius: '10px',
                boxShadow: 'inset 0 0 6px rgba(0,0,0,.3)',
                backgroundColor: '#f5f5f5',
              },
              '& .MuiDataGrid-columnHeaders': {
                background: '#f5f6f7',
              },
              '&.MuiDataGrid-root': {
                fontSize: `${fontSize}px`,
                maxHeight: '800px',
              },
              '.MuiTypography-root': {
                fontSize: `${fontSize}px`,
              },
              '& .MuiDataGrid-columnHeaderTitle': {
                fontWeight: '700',
              },
              '.MuiDataGrid-virtualScroller,.MuiDataGrid-overlayWrapperInner': {
                minHeight: total === 0 ? '250px' : 'none',
              },
              '&.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
                outline: 'none !important',
              },
              '& .MuiDataGrid-row': {
                transition: 'all 0.1s linear',
                textDecoration: 'none',
                border: 'none',
                '&:hover': {
                  backgroundColor: 'transparent',
                },
              },
              '& .MuiDataGrid-withBorderColor': { border: 'none' },

              '& .MuiDataGrid-cell': {
                border: '1px solid rgba(224, 224, 224, 1)',
              },
              '& .MuiDataGrid-cell[tabindex="0"]': {
                border: '2px solid #4285f4',
                // backgroundColor: 'var(--chip-grey-bg-color)',
              },
              '& .MuiSwitch-track': {
                backgroundColor: 'red',
              },
            }}
            disableColumnMenu={true}
            disableColumnFilter={true}
          />
        </Grid>
      </Grid>
      <CaseNotesFilters
        anchorEl={showFilters}
        handleClose={() => setShowFilters(null)}
        onFilterSubmit={handleFilterSubmit}
        handleResetFilters={handleResetFilters}
        defaultFilters={filters}
      />
    </>
  );
};

export default CaseNotesList;
