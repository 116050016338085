import { Theme } from '@emotion/react';
import { SxProps } from '@mui/material';
import { Variants } from 'framer-motion';
import { ReactNode } from 'react';
import MotionBox from '../MotionBox/MotionBox';

const fadeVariants = {
  enter: {
    opacity: 0,
  },
  center: {
    opacity: 1,
    transition: { ease: 'easeOut', duration: 0.25 },
  },
  exit: {
    opacity: 0,
    transition: {
      duration: 0.25,
      type: 'tween',
    },
  },
};

export const slideVariants: Variants = {
  enter: (direction: number) => {
    return {
      x: direction > 0 ? '100%' : '-100%',
      opacity: 0,
    };
  },
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1,
  },
  exit: (direction: number) => {
    return {
      zIndex: 0,
      x: direction < 0 ? '100%' : '-100%',
      opacity: 0,
    };
  },
};

const SlidingSection = ({
  variant = 'slide',
  children,
  sx,
  minimal = false,
}: {
  variant?: 'slide' | 'fade';
  children: ReactNode;
  sx?: SxProps<Theme>;
  minimal?: boolean;
}) => {
  const baseStyles: SxProps<Theme> = !minimal
    ? {
        width: '100%',
        height: '100%',
        position: 'absolute',
      }
    : {};

  return (
    <MotionBox
      variants={variant === 'slide' ? slideVariants : fadeVariants}
      initial="enter"
      animate="center"
      exit="exit"
      transition={{
        x: { type: 'tween', duration: 0.5 },
      }}
      sx={{
        ...baseStyles,
        ...(Array.isArray(sx) ? sx : [sx]),
      }}
    >
      {children}
    </MotionBox>
  );
};

export default SlidingSection;
