import { ApiConfigV2 } from '../../apiConfigV2';
import { NetworkInstanceV2 } from './NetworkInstance';

class Triaging {
  client: NetworkInstanceV2;

  constructor(_client: NetworkInstanceV2) {
    this.client = _client;
  }

  getRequestsUrl = (
    page: any,
    pageSize: any,
    requestType: string,
    employeeId: string,
    organisation: string,
    workEmail: string,
    personalEmail: string,
  ) => {
    const url = `${ApiConfigV2.api.triaging.getAccesscodeRequests}?page=${page}&pageSize=${pageSize}&requestType=${requestType}&employeeId=${employeeId}&organisation=${organisation}&workEmail=${workEmail}&personalEmail=${personalEmail}`;
    return this.client.get(url);
  };

  // Wrapper function for specific access code request (with 'domain_verified' as requestType)
  getDomainVerifiedRequests = (
    page: any,
    pageSize: any,
    employeeId: string,
    organisation: string,
    workEmail: string,
    personalEmail: string,
  ) => {
    return this.getRequestsUrl(
      page,
      pageSize,
      'domain_verified',
      employeeId,
      organisation,
      workEmail,
      personalEmail,
    );
  };

  getGeneralRequests = (
    page: any,
    pageSize: any,
    reqType: string,
    employeeId: string,
    organisation: string,
    workEmail: string,
    personalEmail: string,
  ) => {
    return this.getRequestsUrl(
      page,
      pageSize,
      reqType,
      employeeId,
      organisation,
      workEmail,
      personalEmail,
    );
  };

  getAccesscodesOfRequest = (reqId: number) => {
    return this.client.get(
      `${ApiConfigV2.api.triaging.getAccesscodesOfRequest.replace(
        '{{reqId}}',
        reqId.toString(),
      )}`,
    );
  };

  updateRequest = (reqId: any, status: string) => {
    return this.client.post(
      `${ApiConfigV2.api.triaging.getAccesscodeRequests}/${reqId}`,
      {
        status,
      },
    );
  };

  approveDomainRequest = (
    reqId: any,
    status: string,
    accessCode: string | null,
    type: string,
  ) => {
    return this.client.post(
      `${ApiConfigV2.api.triaging.getAccesscodeRequests}/${reqId}`,
      {
        status,
        accessCode,
        type,
      },
    );
  };
}

export { Triaging };
