import { Typography } from '@mui/material';

export const ConfigKeys: any = {
  ALLOW_OTP: {
    title: 'Allow otp login on care.intellect.co?',
    values: [
      { title: 'Yes', value: 'true' },
      { title: 'No', value: 'false' },
    ],
  },
  APP_ACCESS: {
    title: 'Send user onboarding and email verification email?',
    values: [
      { title: 'Yes', value: 'true' },
      { title: 'No', value: 'false' },
    ],
  },
  CLINICAL_SELF_DISABLE: {
    title: 'Restrict clinician triaging on app?',
    values: [
      { title: 'Yes', value: 'false' },
      { title: 'No', value: 'true' },
    ],
  },
  EAP_ACCESS: {
    title: 'Show organisation to helpline rangers?',
    values: [
      { title: 'Yes', value: 'true' },
      { title: 'No', value: 'false' },
    ],
  },
  careNavigator: {
    title: 'Enable care navigator?',
    values: [
      { title: 'Yes', value: 'true' },
      { title: 'No', value: 'false' },
    ],
  },
  MAVERICK_CONFIG: {
    title: 'Type of "care" platform config',
    valueParser: (value: string) => {
      return value === 'care' ? 'ByteDance' : 'Custom';
    },
  },
  PRIVATE_TAGS: {
    title: 'User tags on provider dashboard(Comma Seperated)',
  },
  eapHelpLine: {
    title: 'Helpline numbers(with country)',
    valueParser: (value: string) => {
      return value
        .split(',')
        .map((num) => <Typography key={num}>{num}</Typography>);
    },
  },
  eapHotLine: {
    title: '(OLD) Helpline numbers(without country)',
  },
  helplineCTA: {
    title: 'Helpline Button Text',
  },
  HR_VIEW_ORGANISATIONS: {
    title: 'HR_VIEW_ORGANISATIONS',
  },
  eapNote: {
    title: 'Helpline dashboard note',
  },
  is60MinuteCoachingDisabled: {
    title: 'Enable 60mins coaching',
    values: [
      { title: 'Yes', value: 'true' },
      { title: 'No', value: 'false' },
    ],
  },
};
