// ** React Imports
import { ReactNode, useContext } from 'react';
import { AbilityContext } from './Can';
import { NavLink } from '../../../shared/types/Navbar/Navbar';

interface Props {
  navLink?: NavLink;
  children: ReactNode;
}

const CanViewNavLink = (props: Props) => {
  // ** Props
  const { children, navLink } = props;

  // ** Hook
  const ability = useContext(AbilityContext);

  if (navLink?.alwaysShow) return <>{children}</>;

  return ability &&
    navLink?.action &&
    navLink?.subject &&
    ability.can(navLink?.action, navLink?.subject) ? (
    <>{children}</>
  ) : null;
};

export default CanViewNavLink;
