import moment from 'moment';
import { slotToTime } from '../../../utilities/Date';
import { ISlots } from '../../../view/components/ScheduleTimeSlots/types';
import { ApiConfigV2 } from '../../apiConfigV2';
import { AUDIT_LOG_HEADER_KEY } from '../../constants/App';
import { Provider, ProviderProfile } from '../../types/provider/Provider';
import { NetworkInstanceV2 } from './NetworkInstance';
import { ProviderAuditLogEvents } from '../../constants/Provider';

class ChiefProvider {
  client: NetworkInstanceV2;

  constructor(_client: NetworkInstanceV2) {
    this.client = _client;
  }

  getAllProvidersNoPagination = (): Promise<Provider[]> =>
    this.client
      .get(ApiConfigV2.api.provider.listV1)
      .then((response) => response?.data?.data);

  getAllProviders = (
    queryParams: Record<string, any> = {},
  ): Promise<{
    page: number;
    pageSize: number;
    providers: Provider[];
    total: number;
  }> =>
    this.client
      .get(ApiConfigV2.api.provider.listV2, { params: queryParams })
      .then((response) => response?.data?.data);

  addProviderProfile = (body: {
    name: string;
    providerId: string;
    type?: any;
    services?: string;
  }) => {
    const payload = {
      name: body.name,
      providerId: body.providerId.toString(),
      profileImage: 'image.jpg',
      bio: ' ',
    };
    return this.client.post(ApiConfigV2.api.provider.listV1, payload);
  };
  addProvider = (body: {
    name: string;
    email: string;
    type?: any;
    services?: string[];
  }) => {
    const payload = {
      name: body.name,
      email: body.email,
      type: body.type,
      services: body.services,
    };
    return this.client.post(ApiConfigV2.api.provider.listV1, payload);
  };

  getProviderProfile = (providerId: number) =>
    this.client.get(
      ApiConfigV2.api.provider.profileV2.replace(
        '{{providerId}}',
        `${providerId}`,
      ),
    );

  getProviderLocation = (providerId: number) =>
    this.client.get(
      ApiConfigV2.api.provider.location.replace(
        '{{providerId}}',
        `${providerId}`,
      ),
    );

  getProviderLanguages = (providerId: number) =>
    this.client.get(
      ApiConfigV2.api.provider.getLanguageV2.replace(
        '{{providerId}}',
        `${providerId}`,
      ),
    );
  getCountries = () => this.client.get(ApiConfigV2.api.provider.country);
  getProviderHolidays = (providerId: number) =>
    this.client.get(
      ApiConfigV2.api.provider.getHolidayV2.replace(
        '{{providerId}}',
        `${providerId}`,
      ),
    );
  getCountryCities = (countryId: number) =>
    this.client.get(
      ApiConfigV2.api.provider.cities.replace('{{countryId}}', `${countryId}`),
    );

  getCountryStates = (countryId: number) =>
    this.client.get(
      ApiConfigV2.api.provider.states.replace('{{countryId}}', `${countryId}`),
    );
  addCountryCity = (cityDetails: any) =>
    this.client.post(ApiConfigV2.api.provider.city, {
      ...cityDetails,
    });

  getProviderClients = (providerId: number) =>
    this.client.get(
      ApiConfigV2.api.provider.getClientsV2.replace(
        '{{providerId}}',
        `${providerId}`,
      ),
    );
  getAllSlots = (providerId: number) =>
    this.client.get(
      ApiConfigV2.api.provider.getLanguageV2.replace(
        '{{providerId}}',
        `${providerId}`,
      ),
    );

  getProviderSlots = (providerId: number) =>
    this.client.get(
      ApiConfigV2.api.provider.getSlotsV2.replace(
        '{{providerId}}',
        `${providerId}`,
      ),
    );

  getProviderAttributes = (providerId: number) =>
    this.client.get(
      ApiConfigV2.api.provider.getAttributesV2.replace(
        '{{providerId}}',
        `${providerId}`,
      ),
    );
  getProviderExpertise = (providerId: number) =>
    this.client.get(
      ApiConfigV2.api.provider.getExpertiseV2.replace(
        '{{providerId}}',
        `${providerId}`,
      ),
    );
  updateProvider = (
    providerId: number,
    body: { canMatch?: boolean; timezone?: string },
    changeLog: Record<string, any>,
    auditEvent: string,
  ) =>
    this.client.put(
      ApiConfigV2.api.provider.update.replace(
        '{{providerId}}',
        String(providerId),
      ),
      { updatedPayload: body, changeLog },
      { headers: { [AUDIT_LOG_HEADER_KEY]: auditEvent } },
    );

  updateProviderConfigAllyFlag = (
    providerId: number,
    body: { id?: string; value: string },
    changeLog: Record<string, any>,
    auditEvent: string,
  ) =>
    this.client.put(
      ApiConfigV2.api.provider.updateConfig.replace(
        '{{providerId}}',
        String(providerId),
      ),
      { updatedPayload: body, changeLog },
      { headers: { [AUDIT_LOG_HEADER_KEY]: auditEvent } },
    );

  updateServiceStatus = (
    providerId: number,
    body: { canMatch: boolean; id: number; service: string },
    changeLog: Record<string, any>,
    auditEvent: string,
  ) => {
    return this.client.post(
      ApiConfigV2.api.provider.service.replace(
        '{{providerId}}',
        String(providerId),
      ),
      { updatedPayload: body, changeLog },
      { headers: { [AUDIT_LOG_HEADER_KEY]: auditEvent } },
    );
  };

  addProviderLocation = (
    providerId: number,
    body: { countryId: number; cityId?: number; stateId?: number },
    changeLog: Record<string, any>,
    auditEvent: string,
  ) =>
    this.client.put(
      ApiConfigV2.api.provider.addLocation,
      {
        updatedPayload: {
          providerId,
          countryId: body.countryId,
          cityId: body.cityId,
          stateId: body.stateId,
        },
        changeLog,
      },
      { headers: { [AUDIT_LOG_HEADER_KEY]: auditEvent } },
    );

  addProviderResidentLocation = (
    providerId: number,
    body: { countryId: number; cityId?: number; stateId?: number },
    changeLog: Record<string, any>,
    auditEvent: string,
  ) =>
    this.client.put(
      ApiConfigV2.api.provider.addResidentLocation,
      {
        updatedPayload: {
          providerId,
          countryId: body.countryId,
          cityId: body.cityId,
          stateId: body.stateId,
        },
        changeLog,
      },
      { headers: { [AUDIT_LOG_HEADER_KEY]: auditEvent } },
    );

  addProviderLicenseLocation = (
    providerId: number,
    body: {
      countryId: number | null;
      cityId?: number | null;
      stateId?: number | null;
    }[],
    changeLog: Record<string, any>,
    auditEvent: string,
  ) =>
    this.client.put(
      ApiConfigV2.api.provider.addLicenseLocation,
      {
        updatedPayload: {
          providerId,
          locations: body,
        },
        changeLog,
      },
      { headers: { [AUDIT_LOG_HEADER_KEY]: auditEvent } },
    );

  saveProviderProfile = (
    profile: Partial<ProviderProfile>,
    changeLog: Record<string, any> = {},
    auditLogEvent: string,
  ) =>
    this.client.post(
      `${ApiConfigV2.api.provider.updateV2}`,
      {
        updatedPayload: {
          ...profile,
          bioTranslation: profile.bioTranslation || {},
          providerNameTranslation: profile.providerNameTranslation || {},
        },
        changeLog,
      },
      { headers: { [AUDIT_LOG_HEADER_KEY]: auditLogEvent } },
    );

  manualAssign = (
    userId: string,
    providerId: string,
    body: {
      friendlyName: string;
      goals: string[];
      role: string;
      userTimeZone: string;
    },
    changeLog: Record<string, any>,
  ) =>
    this.client.post(
      `${ApiConfigV2.api.provider.coach.manualAssign}`
        .replace('{{providerId}}', providerId)
        .replace('{{userId}}', userId),
      { updatedPayload: body, changeLog },
      {
        headers: {
          [AUDIT_LOG_HEADER_KEY]: ProviderAuditLogEvents.USER_MATCHED,
        },
      },
    );

  deleteHoliday = (providerId: number, holidayId: string) =>
    this.client.delete(
      ApiConfigV2.api.provider.holiday.delete
        .replace('{{providerId}}', String(providerId))
        .replace('{{id}}', holidayId),
    );

  saveRanking = (providerId: number, rankings: any[]) =>
    this.client.post(
      ApiConfigV2.api.provider.saveRanking.replace(
        '{{providerId}}',
        String(providerId),
      ),
      { rankings },
    );

  deleteClient = (
    providerId: number,
    clientId: string,
    changeLog: Record<string, any>,
  ) =>
    this.client.delete(
      ApiConfigV2.api.provider.client.delete
        .replace('{{providerId}}', String(providerId))
        .replace('{{userId}}', clientId),
      {
        data: { changeLog },
        headers: {
          [AUDIT_LOG_HEADER_KEY]: ProviderAuditLogEvents.USER_UNMATCHED,
        },
      },
    );

  rescheduleMeeting = (
    id: string,
    body: Record<'userId' | 'scheduledStartTime' | 'duration' | 'role', string>,
  ) =>
    this.client.put(
      ApiConfigV2.api.provider.meeting.reschedule.replace('{{meetingId}}', id),
      body,
    );

  meetingCancel = (meetingId: string, providerId: any) =>
    this.client.delete(
      ApiConfigV2.api.provider.meeting.reschedule
        .replace('{{providerId}}', String(providerId))
        .replace('{{meetingId}}', meetingId),
    );

  saveSlots = (providerId: number, timezone: string, slots: any[]) => {
    const locations = slots.map((s) => s.locationId);
    const momentSlots: any[] = [];
    const offset = moment.tz(timezone).utcOffset();
    slots.map((slot) => {
      momentSlots.push({
        slot: moment(
          `${slot.dayOfWeek} ${slotToTime(+slot.slot)}`,
          'd HH:mm',
        ).subtract(offset, 'minutes'),
        locationId: slot.locationId,
      });
    });
    const locationMap: any = locations.reduce((res, locationId) => {
      res[locationId] = {
        0: new Set(),
        1: new Set(),
        2: new Set(),
        3: new Set(),
        4: new Set(),
        5: new Set(),
        6: new Set(),
        7: new Set(),
      };
      return res;
    }, {});

    momentSlots.forEach((locationSlot: any) => {
      locationMap[locationSlot.locationId][+locationSlot.slot.format('d')].add(
        +locationSlot.slot.format('HH') * 2 +
          +Math.floor(+locationSlot.slot.format('mm') / 30).toFixed(0),
      );
    });

    const body = Object.keys(locationMap)
      .map((locationId: any) => {
        return Object.keys(locationMap[locationId]).map((key: any) => {
          const slots: number[] = [];
          locationMap[locationId][key].forEach((slot: number) =>
            slots.push(slot),
          );
          return {
            locationId,
            dayOfWeek: key,
            slots: slots.sort((a: number, b: number) => a - b).join(','),
          };
        });
      })
      .flat();

    return this.client.post(
      ApiConfigV2.api.provider.saveSlots.replace(
        '{{providerId}}',
        String(providerId),
      ),
      body.map((s: any) => ({
        ...s,
        locationId: s.locationId === '0' ? null : s.locationId,
      })),
    );
  };

  /** This method directly calls save slots api
   * It recieved already preapred slots in UTC,
   * whereas above method 1st converts slots then calls the API
   */
  saveProviderSlots = (providerId: number, slots: ISlots[]) => {
    return this.client.post(
      ApiConfigV2.api.provider.saveSlots.replace(
        '{{providerId}}',
        String(providerId),
      ),
      slots,
    );
  };

  saveProviderOverrides = (
    providerId: number,
    payload: {
      items: {
        localDate: string;
        slots: string;
        locationId: string | null;
      }[];
    },
  ) => {
    return this.client.post(
      ApiConfigV2.api.provider.saveOverrides.replace(
        '{{providerId}}',
        String(providerId),
      ),
      payload,
    );
  };

  saveProviderBlocks = (
    providerId: number,
    payload: {
      items: {
        localDate: string;
        slots: string;
        locationId: string | null;
      }[];
    },
  ) => {
    return this.client.post(
      ApiConfigV2.api.provider.saveBlocks.replace(
        '{{providerId}}',
        String(providerId),
      ),
      payload,
    );
  };

  addProviderLeave = (payload: {
    providerId: number;
    from: number;
    to: number;
  }) => {
    return this.client.post(ApiConfigV2.api.provider.saveLeaves, payload);
  };

  removeProviderLeave = (payload: {
    providerId: number;
    localDates: string[];
  }) => {
    return this.client.put(ApiConfigV2.api.provider.removeLeaves, payload);
  };

  removeProviderLeaveV2 = (payload: {
    providerId: number;
    leaveIds: number[];
  }) => {
    return this.client.put(ApiConfigV2.api.provider.removeLeavesV2, payload);
  };

  getSessions = (providerId: number, fromDate: string, toDate: string) =>
    this.client.get(
      `${ApiConfigV2.api.provider.sessionsV2.replace(
        '{{providerId}}',
        `${providerId}`,
      )}/${encodeURIComponent(fromDate)}/${encodeURIComponent(toDate)}`,
    );

  userProviders = (userId: any) =>
    this.client.get(
      `${ApiConfigV2.api.provider.user.list.replace(
        '{{userId}}',
        `${userId}`,
      )}`,
    );

  //In-Person
  getLocations = (queryStringParams: {
    page: number;
    pageSize: number;
    cityId?: number;
    countryId?: number;
  }) => {
    console.log('queryStringParams:', queryStringParams);
    return this.client.get(ApiConfigV2.api.provider.inPerson.locationList, {
      params: queryStringParams,
    });
  };

  getLocationDetails = (clinicId: string) => {
    return this.client.get(
      ApiConfigV2.api.provider.inPerson.locationDetails.replace(
        '{{clinicId}}',
        String(clinicId),
      ),
    );
  };

  addLocation = (body: {
    name: string;
    address: string;
    timezone: string;
    cityId: number;
    latitude: number | null;
    longitude: number | null;
  }) => this.client.post(ApiConfigV2.api.provider.inPerson.addLocation, body);

  updateLocation = (
    clinicId: string,
    body: {
      name: string;
      address: string;
      timezone: string;
      cityId: number;
      latitude: number | null;
      longitude: number | null;
    },
  ) =>
    this.client.put(
      ApiConfigV2.api.provider.inPerson.updateLocation.replace(
        '{{clinicId}}',
        clinicId,
      ),
      body,
    );

  getLocationRooms = (clinicId: string) => {
    return this.client.get(
      ApiConfigV2.api.provider.inPerson.listRooms.replace(
        '{{clinicId}}',
        String(clinicId),
      ),
    );
  };

  addRoom = (
    body: {
      name: string;
      description: string;
      availability: any;
    },
    locationId: string,
  ) =>
    this.client.post(
      ApiConfigV2.api.provider.inPerson.addRoom.replace(
        '{{clinicId}}',
        String(locationId),
      ),
      body,
    );

  updateRoom = (
    body: {
      name: string;
      description: string;
    },
    roomId: string,
  ) =>
    this.client.put(
      ApiConfigV2.api.provider.inPerson.updateRoom.replace(
        '{{roomId}}',
        String(roomId),
      ),
      body,
    );

  getRoomDetails = (roomId: string) => {
    return this.client.get(
      ApiConfigV2.api.provider.inPerson.roomDetails.replace(
        '{{roomId}}',
        String(roomId),
      ),
    );
  };

  getRoomEvents = (
    roomId: string,
    queryParams: { fromDate: string; toDate: string },
    header: { timeZone: string },
  ) => {
    return this.client.get(
      ApiConfigV2.api.provider.inPerson.roomEvents.replace(
        '{{roomId}}',
        String(roomId),
      ),
      {
        params: queryParams,
        headers: header,
      },
    );
  };

  saveProviderPreferredLocations = (
    providerId: number,
    payload: Record<string, any>,
    auditEvent: string,
  ) => {
    return this.client.post(
      ApiConfigV2.api.provider.inPerson.saveProviderPreferredLocations.replace(
        '{{providerId}}',
        String(providerId),
      ),
      payload,
      {
        headers: {
          [AUDIT_LOG_HEADER_KEY]: auditEvent,
        },
      },
    );
  };

  getProviderPreferredLocations = (providerId: number) => {
    return this.client.get(
      ApiConfigV2.api.provider.inPerson.getProviderPreferredLocations.replace(
        '{{providerId}}',
        String(providerId),
      ),
    );
  };

  clearAllProviderPreferredLocations = (providerId: number) => {
    return this.client.delete(
      ApiConfigV2.api.provider.inPerson.deleteProviderPreferredLocations.replace(
        '{{providerId}}',
        String(providerId),
      ),
    );
  };

  fetchUserMeetings = (userId: number, providerId?: number, showPast?: any) => {
    if (showPast == true) {
      return this.client.post(
        `${ApiConfigV2.api.provider.userMeetings}?showPast=true`,
        {
          userId,
        },
      );
    }
    return this.client.post(ApiConfigV2.api.provider.userMeetings, {
      providerId,
      userId,
    });
  };

  getAvailability = async ({
    service,
    organisationId,
  }: {
    service: string;
    organisationId?: number;
  }) => {
    const response = await this.client.get(
      `${ApiConfigV2.api.provider.availability}?service=${service}&organisationId=${organisationId}`,
    );
    return response.data;
  };

  getMeetings = async (queryParams: Record<string, any> = {}) => {
    const response = await this.client.get(
      `${ApiConfigV2.api.provider.meetings}`,
      {
        params: queryParams,
      },
    );
    return response.data;
  };

  getMeetingDetails = (meetingId: string): Promise<any> =>
    this.client.get(
      ApiConfigV2.api.provider.meeting.details.replace(
        '{{meetingId}}',
        meetingId,
      ),
    );

  getCaseNotes = (payload: {
    companyId: number;
    companyName: string;
    startDate: string;
    endDate: string;
  }) => this.client.post(ApiConfigV2.api.provider.caseNotes, payload);

  getMetabaseQuestion = (questionId: number) =>
    this.client.get(
      ApiConfigV2.api.analytics.metabaseQuestion.replace(
        '{{questionId}}',
        String(questionId),
      ),
    );

  getMeetingHistoryInfo = (meetingId: string): Promise<any> =>
    this.client.get(
      ApiConfigV2.api.provider.meeting.history.replace(
        '{{meetingId}}',
        meetingId,
      ),
    );
  updateMeetingStatus = (meetingId: string, status: string): Promise<any> => {
    return this.client.put(
      ApiConfigV2.api.provider.meeting.statusUpdate.replace(
        '{{meetingId}}',
        meetingId,
      ),
      {
        status,
      },
    );
  };
}

export { ChiefProvider };
